import React, { createContext, useState } from "react"
import { useAuth } from "../../../auth"
import CompteInfoFournisseurEditionMode from "./CompteInfoFournisseurEditionMode"
import MyCard from "../../../../utils/MyCard"
import CompteInfoFournisseurPreviewMode from "./CompteInfoFournisseurPreviewMode"

const CompteInfoFournisseurContext = createContext<ICompteInfoFournisseurContextPropsModel>({} as ICompteInfoFournisseurContextPropsModel)

const CompteInfoFournisseur = () => {
     const [mode, setMode] = useState<"PREVIEW" | "EDITION">("PREVIEW")
     const { currentUser } = useAuth()

     return (
          <>
               <CompteInfoFournisseurContext.Provider value={{ toggleToPreviewMode: () => setMode("PREVIEW") }}>
                    <MyCard>
                         <MyCard.Header
                              rightSideContent={
                                   <div onClick={() => setMode(prev => (prev === "PREVIEW" ? "EDITION" : "PREVIEW"))} className={"cursor-pointer"}>
                                        {mode === "EDITION" ? (
                                             <span className={"fas fa-eye fs-1 text-hover-primary"} />
                                        ) : (
                                             <span className={"fas fa-edit fs-1 text-hover-warning"} />
                                        )}
                                   </div>
                              }
                         >
                              {currentUser?.fournisseur?.fournisseur?.raisonSociale}
                         </MyCard.Header>
                         <MyCard.Body classNames={"w-100 w-lg-75 w-xxl-65"}>
                              <>{mode === "PREVIEW" ? <CompteInfoFournisseurPreviewMode /> : <CompteInfoFournisseurEditionMode />}</>
                         </MyCard.Body>
                    </MyCard>
               </CompteInfoFournisseurContext.Provider>
          </>
     )
}

interface ICompteInfoFournisseurContextPropsModel {
     toggleToPreviewMode: () => void
}

export { CompteInfoFournisseur, CompteInfoFournisseurContext }
