import { Link, useNavigate } from "react-router-dom"
import React, { Dispatch, SetStateAction, useState } from "react"
import { toast } from "react-toastify"
import { craDetailsToggleAllTicketsResuataurantMonth, craDetailsToggleTicketResuataurant } from "../../../core/_request"
import { ConsultantCraDetailsModel, ConsultantCraModel } from "../../../../../../models"
import moment from "moment"
import MyTooltip from "../../../../../../utils/MyTooltip"
import Swal from "sweetalert2"
import withReactContent from "sweetalert2-react-content"
import "./styles.scss"

type CraUpdateGrilleTempsProps = {
     data: ConsultantCraModel
     editionModeEnabled?: boolean
     setData: (v: ConsultantCraModel) => void
}

export const CraUpdateTicketsRestaurant: React.FC<CraUpdateGrilleTempsProps> = ({ data, setData, editionModeEnabled = true }) => {
     const MySwal = withReactContent(Swal)

     const [cellsToggling, setCellsToggling] = useState<ConsultantCraDetailsModel[]>([])

     // Une row avec les jours du mois
     const TableHeadRow = () => {
          return (
               <tr className={"px-2"}>
                    <th className="col fw-bold sticky-column"></th>
                    {data.consultantCraDetails.map((item, key) => {
                         return (
                              <th key={item.id} className={`text-center text-primary fw-bold ${key == data.consultantCraDetails.length - 1 && "pe-4"}`}>
                                   {item.date.parseZone().format("DD")}
                              </th>
                         )
                    })}
               </tr>
          )
     }

     // Une row avec les tickets restaurant
     const TableBodyRowTicketsRestaurant = () => {
          function toggleCellTicketRestaurant(cra_details_id: number) {
               craDetailsToggleTicketResuataurant(cra_details_id)
                    .then(r => {
                         const index = data.consultantCraDetails.findIndex(item => item.id == cra_details_id)
                         data.consultantCraDetails[index] = { ...data.consultantCraDetails[index], ticketResto: r.data.ticketNewValue }
                         setData(data)
                    })
                    .catch(e => {
                         toast.error(e.response.data?.detail, { autoClose: false })
                    })
                    .finally(() => setCellsToggling(prev => prev.filter(item => item.id != cra_details_id)))
          }

          function checkAllCellsPossible(date: moment.Moment) {
               MySwal.fire({
                    icon: "info",
                    text: `Vous êtes sur le point de cocher toutes les cases du mois. Voulez-vous procéder?`,
                    showCancelButton: true,
                    confirmButtonText: "Oui",
                    cancelButtonText: "Annuler",
                    showLoaderOnConfirm: true,
                    preConfirm: () => {
                         return craDetailsToggleAllTicketsResuataurantMonth(date.format("MM"), date.format("YYYY"))
                              .then(r => {
                                   data.consultantCraDetails.map(item => {
                                        if (r.data.consultantCraDetailsAffectedIds.includes(item.id)) item.ticketResto = true
                                   })
                                   setData(data)
                                   toast.success("Votre action a bien été prise en compte.")
                              })
                              .catch(e => {
                                   toast.error(e.response?.data?.detail, { autoClose: false })
                              })
                    },
                    allowOutsideClick: () => !Swal.isLoading(),
               }).then()
          }

          return (
               <>
                    <tr>
                         <th scope="row" className={"d-flex justify-content-between sticky-column border-end"}>
                              <span className={"text-primary fw-bold ps-2"}>TICKETS</span>
                              {editionModeEnabled && (
                                   <MyTooltip title={"Saisie automatique"}>
                                        <i
                                             className={"fas fa-wand-magic-sparkles btn btn-color-dark p-0 ms-sm-0 ms-2 fs-3"}
                                             onClick={() => checkAllCellsPossible(data.declaration.mois!.parseZone())}
                                             style={{ verticalAlign: "middle" }}
                                        ></i>
                                   </MyTooltip>
                              )}
                         </th>
                         {data.consultantCraDetails.map((item, key) => (
                              <td
                                   key={key}
                                   className={`text-center heuresCell ${[5, 6].includes(item.date.weekday()) && "isWeekendCell"} ${
                                        moment().format("YYYYMMDD") == item.date.format("YYYYMMDD") && "isTodayCell"
                                   } ${editionModeEnabled && "cursor-pointer"}`}
                                   onClick={() => {
                                        if (editionModeEnabled && !cellsToggling.find(item_ => item_.id == item.id)) {
                                             toggleCellTicketRestaurant(item.id)
                                             setCellsToggling(prev => [...prev, item])
                                        }
                                   }}
                              >
                                   <span>
                                        {cellsToggling.find(item_ => item_.id == item.id) ? (
                                             <span className="spinner-border spinner-border-sm align-middle"></span>
                                        ) : (
                                             item.ticketResto && <i className={"fas fa-check-circle text-dark"} />
                                        )}
                                   </span>
                              </td>
                         ))}
                    </tr>
               </>
          )
     }

     return (
          <>
               <div className="table-responsive text-nowrap" style={{ overflowY: "hidden" }}>
                    <table id={"cra-table"} className={`table m-0 ${editionModeEnabled && "cra-table-edition-mode"}`}>
                         {/* Begin::thead */}
                         <thead className="thead-dark border-bottom border-top">
                              <TableHeadRow />
                         </thead>
                         {/* End::thead */}

                         {/* Begin::tbody */}
                         <tbody className={"border-top border-bottom"}>
                              {/* Begin::Heures de travail */}
                              <TableBodyRowTicketsRestaurant />
                              {/* End::Heures de travail */}
                         </tbody>
                         {/* End::tbody */}
                    </table>
               </div>

               <div className="text-primary d-flex flex-row-reverse fs-6 mb-4">
                    <span className={"fw-bold p-3 border-primary"}>
                         {data.consultantCraDetails.filter(item => item.ticketResto).length == 0 && "Aucun ticket restaurant"}
                         {data.consultantCraDetails.filter(item => item.ticketResto).length == 1 && "1 ticket restaurant"}
                         {data.consultantCraDetails.filter(item => item.ticketResto).length > 1 &&
                              `${data.consultantCraDetails.filter(item => item.ticketResto).length} tickets restaurant`}
                    </span>
               </div>
          </>
     )
}

type CraUpdateTicketsRestaurantWrapperProps = {
     data: ConsultantCraModel
     setData: (v: ConsultantCraModel) => void
     setStepperStep: Dispatch<SetStateAction<"one" | "two" | "three">>
     editionModeEnabled?: boolean
}

export const CraUpdateTicketsRestaurantWrapper: React.FC<CraUpdateTicketsRestaurantWrapperProps> = ({ data, setData, setStepperStep, editionModeEnabled = true }) => {
     const navigate = useNavigate()

     return (
          <div className={"mx-auto"} style={{ maxWidth: "1500px" }}>
               <div className={"mb-5"}>
                    <CraUpdateTicketsRestaurant data={data} setData={setData} editionModeEnabled={editionModeEnabled} />
               </div>

               {/* FOOTER */}
               <div className="d-none d-sm-flex justify-content-between">
                    <div>
                         <Link to="/activites/cra " className={`btn btn-sm btn-light-primary me-2`}>
                              <span className={"fas fa-list-dots fs-1 me-3 align-middle"} /> Retour à la liste des CRA
                         </Link>
                         <button type="button" className="btn btn-sm btn-light-primary me-3" onClick={() => setStepperStep("two")}>
                              <span className={"fas fa-arrow-left fs-3 me-1 align-middle"} /> CRA
                         </button>
                    </div>

                    <button
                         type="button"
                         className="btn btn-sm btn-primary"
                         onClick={() => {
                              toast.info("Votre saisie a bien été enregistrée.")
                              navigate("/activites/cra")
                         }}
                    >
                         Finaliser <span className={"fas fa-check-circle fs-3 ms-1 align-middle"} />
                    </button>
               </div>

               <div className="d-flex d-sm-none flex-column">
                    <div className={"mb-3"}>
                         <button type="button" className="btn btn-sm btn-light-primary me-3" onClick={() => setStepperStep("two")}>
                              <span className={"fas fa-arrow-left fs-3 me-1 align-middle"} /> CRA
                         </button>
                         <button
                              type="button"
                              className="btn btn-sm btn-primary float-end"
                              onClick={() => {
                                   toast.info("Votre saisie a bien été enregistrée.")
                                   navigate("/activites/cra")
                              }}
                         >
                              Finaliser <span className={"fas fa-check-circle fs-3 ms-1 align-middle"} />
                         </button>
                    </div>

                    <Link to="/activites/cra " className={`btn btn-sm btn-light-primary`}>
                         <span className={"fas fa-list-dots fs-1 me-3 align-middle"} /> Retour à la liste des CRA
                    </Link>
               </div>
          </div>
     )
}
