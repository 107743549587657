import React from "react"
import "./styles.scss"
import { useDeclarationUpdate } from "./DeclarationUpdate"
import { CraUpdateTicketsRestaurant } from "../../cra/craUpdate/CraUpdateTicketsRestaurant"
import { ConsultantCraModel } from "../../../../../../models"
import { useQuery } from "react-query"
import { AxiosError } from "axios"
import { declarationActiveCra } from "../../../core/_request"
import MyAlert from "../../../../../../utils/MyAlert"

export const DeclarationUpdateTicketsResaurant = () => {
     const context = useDeclarationUpdate()

     const activeCraQuery = useQuery<ConsultantCraModel, AxiosError>("activeCra", () => declarationActiveCra(context.data.declaration!.id!).then(r => r.data))

     return (
          <div className={"mx-auto"} style={{ maxWidth: "1500px" }}>
               {activeCraQuery.isFetching && (
                    <div className={"text-center p-4"}>
                         <span className="spinner-border mb-2"></span>
                         <p style={{ fontWeight: 500 }}>Chargement en cours ...</p>
                    </div>
               )}

               {!activeCraQuery.isFetching && activeCraQuery.isError && (
                    <MyAlert type={"danger"} classNames={"mb-4"}>
                         <>
                              {activeCraQuery.error.response?.data?.detail}
                              <button className={"btn btn-sm btn-danger ms-2"} onClick={() => activeCraQuery.refetch()}>
                                   Recharger
                              </button>
                         </>
                    </MyAlert>
               )}

               {!activeCraQuery.isFetching && !activeCraQuery.isError && activeCraQuery.data && (
                    <CraUpdateTicketsRestaurant
                         data={activeCraQuery.data}
                         setData={cra => context.setData(prev => ({ ...prev, cra }))}
                         editionModeEnabled={context.editionModeEnabled}
                    />
               )}

               {/* FOOTER */}
               <div className="mt-4">
                    <div className="d-none d-sm-flex justify-content-between">
                         <div>
                              <button type={"button"} onClick={context.onClickOnRetourAuxDeclarationCallback} className={`btn btn-sm btn-light-primary me-2`}>
                                   <span className={"fas fa-list-dots fs-1 me-3 align-middle"} /> Retour aux déclarations
                              </button>
                              <button type="button" className="btn btn-sm btn-light-primary me-3" onClick={() => context.setDeclarationStepHeader("ndf")}>
                                   <span className={"fas fa-arrow-left fs-3 me-1 align-middle"} /> Notes de frais
                              </button>
                         </div>

                         <button
                              type="button"
                              className="btn btn-sm btn-primary"
                              onClick={() => {
                                   context.setDeclarationStepHeader("recap")
                                   context.setEnCoursDeRectificationEnabledDepuisRecapitulatif(false)
                              }}
                         >
                              Récapitulatif
                              <span className={"fas fa-arrow-right fs-3 ms-1 align-middle"} />
                         </button>
                    </div>

                    <div className="d-flex d-sm-none flex-column">
                         <div className={"mb-3"}>
                              <button type="button" className="btn btn-sm btn-light-primary me-3" onClick={() => context.setDeclarationStepHeader("ndf")}>
                                   <span className={"fas fa-arrow-left fs-3 me-1 align-middle"} /> Notes de frais
                              </button>
                              <button
                                   type="button"
                                   className="btn btn-sm btn-primary float-end"
                                   onClick={() => {
                                        context.setDeclarationStepHeader("recap")
                                        context.setEnCoursDeRectificationEnabledDepuisRecapitulatif(false)
                                   }}
                              >
                                   Récapitulatif
                                   <span className={"fas fa-arrow-right fs-3 ms-1 align-middle"} />
                              </button>
                         </div>

                         <button type={"button"} onClick={context.onClickOnRetourAuxDeclarationCallback} className={`btn btn-sm btn-light-primary`}>
                              <span className={"fas fa-list-dots fs-1 me-3 align-middle"} /> Retour aux déclarations
                         </button>
                    </div>
               </div>
          </div>
     )
}
