import React from "react"
import { Link } from "react-router-dom"
import MyTooltip from "../../../../../utils/MyTooltip"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { ConsultantNdfModel } from "../../../../../models"
import { AxiosError } from "axios"
import { ndfDelete, ndfGetListe } from "../../core/_request"
import MyAlert from "../../../../../utils/MyAlert"
import { FirstLetterUppercase } from "../../../../../helpers/helper-global"
import moment from "moment/moment"
import Swal from "sweetalert2"
import withReactContent from "sweetalert2-react-content"
import { toast } from "react-toastify"

export function Liste() {
     const queryClient = useQueryClient()
     const MySwal = withReactContent(Swal)

     const getNdfListingQuery = useQuery<ConsultantNdfModel[], AxiosError>("ndfListes", () => ndfGetListe().then(r => r.data))
     const deleteNdfMutation = useMutation((id: number) => ndfDelete(id))

     function handleDeleteNdf(ndf: ConsultantNdfModel) {
          MySwal.fire({
               icon: "warning",
               title: `Êtes-vous sûr de vouloir supprimer la note de frais du mois de ${ndf.declaration.mois?.format("MMMM YYYY")}?`,
               text: "Cette action est irréversible",
               showCancelButton: true,
               confirmButtonText: "Oui",
               cancelButtonText: "Annuler",
               showLoaderOnConfirm: true,
               preConfirm: async () => {
                    return deleteNdfMutation
                         .mutateAsync(ndf.id)
                         .then(() => {
                              queryClient.setQueryData("ndfListes", (items: ConsultantNdfModel[] | undefined) => {
                                   if (items) return items.filter(item => item.id != ndf.id)

                                   return []
                              })
                         })
                         .catch(e => {
                              toast.error(e.response?.data?.detail, { autoClose: false })
                         })
               },
               allowOutsideClick: () => !Swal.isLoading(),
          }).then()
     }

     return (
          <div className="card">
               <div className="card-body p-5 w-xxl-1200px">
                    <div className={"d-flex justify-content-sm-end justify-content-center mb-6"}>
                         <button className={`btn btn-light-primary btn-sm me-2`} onClick={() => getNdfListingQuery.refetch()}>
                              <i className={"fas fa-sync me-md-2 pe-0 fs-3"} />
                              <span className={`d-md-inline d-none`}>Recharger la liste</span>
                         </button>
                         <Link className={`btn btn-primary btn-sm align-self-center`} to={"new"}>
                              <i className={"fas fa-plus-circle fs-2"} />
                              Ajouter une note de frais
                         </Link>
                    </div>

                    {/* loader */}
                    {getNdfListingQuery.isFetching && (
                         <div className={"text-center p-4"}>
                              <span className="spinner-border mb-2"></span>
                              <p style={{ fontWeight: 500 }}>Chargement en cours ...</p>
                         </div>
                    )}

                    {/* error */}
                    {!getNdfListingQuery.isFetching && getNdfListingQuery.isError && (
                         <MyAlert type={"danger"} classNames={"mb-4"}>
                              <>
                                   <span className={"me-2"}>{getNdfListingQuery.error?.response?.data?.detail}</span>
                                   <button className={"btn btn-sm btn-danger"} onClick={() => getNdfListingQuery.refetch()}>
                                        Recharger
                                   </button>
                              </>
                         </MyAlert>
                    )}

                    {/* no data found */}
                    {!getNdfListingQuery.isFetching && !getNdfListingQuery.isError && !getNdfListingQuery.data && (
                         <div className={"text-center p-4"}>
                              <p style={{ fontWeight: 500 }}>Aucune NDF de disponible ...</p>
                         </div>
                    )}

                    {!getNdfListingQuery.isFetching && !getNdfListingQuery.isError && getNdfListingQuery.data && (
                         <>
                              <div className={"row"} style={{ maxHeight: "55vh", overflow: "scroll" }}>
                                   {getNdfListingQuery.data.map((item, key) => (
                                        <div className="col-md-6 col-xl-4 mb-9 card">
                                             <div className="card-header border-secondary border justify-content-center bg-danger" style={{ minHeight: 0 }}>
                                                  <div className="card-title">
                                                       <Link
                                                            to="update"
                                                            className={"text-white"}
                                                            state={{
                                                                 ndf_id: item.id,
                                                                 default_step: "two",
                                                                 breadCrumbPageTitle: FirstLetterUppercase(moment(item.declaration.mois).format("MMMM YYYY")),
                                                            }}
                                                       >
                                                            NDF de {item.declaration.mois?.parseZone().format("MMMM YYYY")}
                                                       </Link>
                                                  </div>
                                             </div>
                                             <div className="card-body text-center border-start border-end border-bottom border-secondary p-4">
                                                  <Link
                                                       to="update"
                                                       state={{
                                                            ndf_id: item.id,
                                                            default_step: "two",
                                                            breadCrumbPageTitle: moment(item.declaration.mois).format("MMMM YYYY"),
                                                       }}
                                                       className={`btn btn-light-dark btn-sm me-2`}
                                                  >
                                                       <i className={"fas fa-eye"} /> Consulter
                                                  </Link>

                                                  <MyTooltip title={"Supprimer"}>
                                                       <button className={"btn btn-danger btn-sm"} onClick={() => handleDeleteNdf(item)}>
                                                            <i className={"fas fa-trash"} /> Supprimer
                                                       </button>
                                                  </MyTooltip>
                                             </div>
                                        </div>
                                   ))}
                              </div>
                         </>
                    )}
               </div>
          </div>
     )
}
