import { FC } from "react"
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom"
import { PrivateRoutes } from "./PrivateRoutes"
import { PrivateRoutes as PrivateRoutesCandidat } from "../app-candidat/routing/PrivateRoutes"
import { PrivateRoutes as PrivateRoutesPreembauche } from "../app-preembauche/routing/PrivateRoutes"
import { PrivateRoutes as PrivateRoutesConsultant } from "../app-consultant/routing/PrivateRoutes"
import { PrivateRoutes as PrivateRoutesRespFournisseur } from "../app-fournisseur-resp/PrivateRoutes"
import { ErrorsPage } from "../modules/errors/ErrorsPage"
import { useAuth } from "../modules/auth"
import { App } from "../App"
import { MasterLayout } from "../../_metronic/layout/MasterLayout"
import useWindowDimensions from "../hooks/useWindowDimensions"
import {
     CONST_USER_ROLE_CANDIDAT,
     CONST_USER_ROLE_CANDIDAT_EN_COURS_EMBAUCHE,
     CONST_USER_ROLE_CONSULTANT,
     CONST_USER_ROLE_RESPONSABLE_FOURNISSEUR,
     IS_DEV_ENV_ENABLED,
} from "../constants"
import CandidatureWithoutAuthWrapper from "../app-public/candidatureWithoutAuth/CandidatureWithoutAuth"
import PublicRoutes from "./PublicRoutes"

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const { PUBLIC_URL } = process.env

const AppRoutes: FC = () => {
     const { height, width } = useWindowDimensions()

     const { currentUser } = useAuth()

     return (
          <BrowserRouter basename={PUBLIC_URL}>
               {IS_DEV_ENV_ENABLED && (
                    <div className={"float-start"} style={{ zIndex: 9999999999, position: "fixed" }}>
                         {width} x {height} {width < 576 && "( XS )"}
                         {width >= 576 && width < 768 && "( SM )"}
                         {width >= 768 && width < 992 && "( MD )"}
                         {width >= 992 && width < 1200 && "( LG )"}
                         {width >= 1200 && width < 1400 && "( XL )"}
                         {width >= 1400 && "( XXL )"}
                    </div>
               )}

               <Routes>
                    <Route element={<App />}>
                         <Route path="candidature-no-auth/*" element={<CandidatureWithoutAuthWrapper />} />
                         <Route path="error/*" element={<ErrorsPage />} />
                         {currentUser ? (
                              <Route element={<MasterLayout />}>
                                   <Route
                                        path="/*"
                                        element={
                                             <>
                                                  <PrivateRoutes />
                                                  {currentUser.consultant?.consultant && (
                                                       <>
                                                            {currentUser.user?.roles?.includes(CONST_USER_ROLE_CONSULTANT) && <PrivateRoutesConsultant />}
                                                            {currentUser.user?.roles?.includes(CONST_USER_ROLE_CANDIDAT_EN_COURS_EMBAUCHE) && <PrivateRoutesPreembauche />}
                                                            {currentUser.user?.roles?.includes(CONST_USER_ROLE_CANDIDAT) && <PrivateRoutesCandidat />}
                                                       </>
                                                  )}

                                                  {currentUser.user?.roles?.includes(CONST_USER_ROLE_RESPONSABLE_FOURNISSEUR) && (
                                                       <>
                                                            <PrivateRoutesRespFournisseur />
                                                       </>
                                                  )}
                                             </>
                                        }
                                   />
                                   {/*Page not found*/}
                                   <Route path="*" element={<Navigate to="/error/404" />} />
                              </Route>
                         ) : (
                              <Route path="/*" element={<PublicRoutes />} />
                         )}
                    </Route>
               </Routes>
          </BrowserRouter>
     )
}

export { AppRoutes }
