import { useAuth } from "../../../../auth"
import React from "react"
import { CONST_CIVILITE_NON_RENSEIGNEE } from "../../../../../constants"

export function CompteInfoPersoPreviewPreembaucheFreelance() {
     const { currentUser } = useAuth()

     return (
          <>
               {/*Civilité*/}
               <div className="row mb-7">
                    <label className="col-lg-4 fw-bold text-muted">Civilité</label>

                    <div className="col-lg-8">
                         <span className="fw-bolder fs-6 text-dark">
                              {currentUser?.user?.civiliteLabel && currentUser.user.civilite !== CONST_CIVILITE_NON_RENSEIGNEE ? (
                                   currentUser?.user.civiliteLabel
                              ) : (
                                   <span className={"text-warning d-flex align-items-center"}>
                                        À renseigner <i className={"fas fa-exclamation-circle text-warning ms-1"} />
                                   </span>
                              )}
                         </span>
                    </div>
               </div>

               {/*Nom & prénom*/}
               <div className="row mb-7">
                    <label className="col-lg-4 fw-bold text-muted">Prénom NOM</label>

                    <div className="col-lg-8">
                         <span className="fw-bolder fs-6 text-dark">
                              {currentUser?.user?.prenom} {currentUser?.user?.nom}
                         </span>
                    </div>
               </div>

               {/*Email*/}
               <div className="row mb-7">
                    <label className="col-lg-4 fw-bold text-muted">Email</label>

                    <div className="col-lg-8">
                         <span className="fw-bolder fs-6 text-dark me-2">{currentUser?.user?.username}</span>
                         <i className={"fas fa-check-circle text-success"} />
                    </div>
               </div>

               {/*Tél*/}
               <div className="row">
                    <label className="col-lg-4 fw-bold text-muted">Téléphone</label>

                    <div className="col-lg-8">
                         <span className="fw-bolder fs-6 text-dark">
                              {currentUser?.user?.tel && currentUser.user.telIndicatif ? (
                                   <>
                                        {currentUser?.user.telIndicatif?.phoneCodeWithPlusPrefix} {currentUser?.user.tel}
                                   </>
                              ) : (
                                   <span className={"text-warning d-flex align-items-center"}>
                                        À renseigner <i className={"fas fa-exclamation-circle text-warning ms-1"} />
                                   </span>
                              )}
                         </span>
                    </div>
               </div>
          </>
     )
}
