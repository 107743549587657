import React from "react"
import { useDeclarationUpdate } from "../DeclarationUpdate"

const Footer = () => {
     const context = useDeclarationUpdate()

     return (
          <>
               <div className="d-none d-sm-flex justify-content-between">
                    <div>
                         <button type={"button"} onClick={context.onClickOnRetourAuxDeclarationCallback} className={`btn btn-sm btn-light-primary me-2`}>
                              <i className={"fas fa-list-dots me-2 align-middle"} /> Retour aux déclarations
                         </button>
                         <button type="button" className="btn btn-sm btn-light-primary me-3" onClick={() => context.setDeclarationStepHeader("ndf")}>
                              <span className={"fas fa-arrow-left fs-3 me-1 align-middle"} /> Factures de frais
                         </button>
                    </div>

                    <button
                         type="button"
                         className="btn btn-sm btn-primary"
                         onClick={() => {
                              context.setDeclarationStepHeader("recap")
                              context.setEnCoursDeRectificationEnabledDepuisRecapitulatif(false)
                         }}
                    >
                         Récapitulatif
                         <span className={"fas fa-arrow-right fs-3 ms-1 align-middle"} />
                    </button>
               </div>

               <div className="d-flex d-sm-none flex-column">
                    <div className={"mb-3"}>
                         <button type="button" className="btn btn-sm btn-light-primary me-3" onClick={() => context.setDeclarationStepHeader("ndf")}>
                              <span className={"fas fa-arrow-left fs-3 me-1 align-middle"} /> Factures de frais
                         </button>
                         <button
                              type="button"
                              className="btn btn-sm btn-primary float-end"
                              onClick={() => {
                                   context.setDeclarationStepHeader("recap")
                                   context.setEnCoursDeRectificationEnabledDepuisRecapitulatif(false)
                              }}
                         >
                              Récapitulatif
                              <span className={"fas fa-arrow-right fs-3 ms-1 align-middle"} />
                         </button>
                    </div>

                    <button type={"button"} onClick={context.onClickOnRetourAuxDeclarationCallback} className={`btn btn-sm btn-light-primary`}>
                         <span className={"fas fa-list-dots fs-1 me-2 align-middle"} /> Retour aux déclarations
                    </button>
               </div>
          </>
     )
}

export default Footer
