import React from "react"
import clsx from "clsx"
import { useLocation } from "react-router"
import { checkIsActive, KTSVG, WithChildren } from "../../../../helpers"

type Props = {
     to: string
     title: string
     icon?: string
     fontIcon?: string
     hasBullet?: boolean
}

const SidebarMenuItemWithSub: React.FC<Props & WithChildren> = ({ children, to, title, icon, fontIcon, hasBullet }) => {
     const { pathname } = useLocation()
     const isActive = checkIsActive(pathname, to)

     return (
          <div className={clsx("menu-item", { "here show": isActive }, "menu-accordion")} data-kt-menu-trigger="click">
               <span className="menu-link">
                    {hasBullet && (
                         <span className="menu-bullet">
                              <span className="bullet bullet-dot"></span>
                         </span>
                    )}
                    {icon && (
                         <span className="menu-icon">
                              {" "}
                              <KTSVG path={icon} className={`svg-icon-2`} />
                         </span>
                    )}
                    {fontIcon && <i className={clsx("fs-3 text-white text-center", fontIcon)} style={{ width: "23px" }}></i>}
                    <span className="menu-title  ms-2">{title}</span>
                    <span className="menu-arrow"></span>
               </span>
               <div
                    className={clsx("menu-sub menu-sub-accordion", {
                         "menu-active-bg": isActive,
                    })}
               >
                    {children}
               </div>
          </div>
     )
}

export { SidebarMenuItemWithSub }
