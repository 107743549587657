import { useQuery } from "react-query"
import moment from "moment"
import axios, { AxiosError } from "axios"
import { API_ESPACE_FOURNISSEUR_RESP_GET_DECLARATIONS_VOYANTS_ENDPOINT } from "../../../constants"
import MyCard from "../../../utils/MyCard"
import MySimpleSpinner from "../../../utils/MySimpleSpinner"
import MyAlert from "../../../utils/MyAlert"
import { Link, useNavigate } from "react-router-dom"
import MyDateMonthYearField from "../../../utils/fields/MyDateMonthYearField"
import { useState } from "react"
import { FirstLetterUppercase } from "../../../helpers/helper-global"
import { toast } from "react-toastify"
import Voyants from "./Voyants"

const DeclarationsVoyants = () => {
     const navigate = useNavigate()

     const [date, setDate] = useState<string>()

     const declarationsSousTraitantVoyantsQuery = useQuery<IGetConsultantsDeclarationsVoyantsResponseModel[], AxiosError>("QUERY_VOYANTS", () =>
          axios.get<IGetConsultantsDeclarationsVoyantsResponseModel[]>(API_ESPACE_FOURNISSEUR_RESP_GET_DECLARATIONS_VOYANTS_ENDPOINT).then(r => r.data)
     )

     return (
          <div className={"d-flex justify-content-center"}>
               <div className={"w-100 w-lg-75 w-xxl-70"}>
                    <MyCard>
                         <MyCard.Header>Déclarations</MyCard.Header>
                         <MyCard.Body>
                              {declarationsSousTraitantVoyantsQuery.isFetching && (
                                   <div className={"text-center p-5"}>
                                        <MySimpleSpinner size={"xl"} />
                                   </div>
                              )}

                              {!declarationsSousTraitantVoyantsQuery.isFetching && declarationsSousTraitantVoyantsQuery.isError && (
                                   <MyAlert type={"danger"} classNames={"mb-4"}>
                                        <>
                                             <span className={"me-2"}>{declarationsSousTraitantVoyantsQuery.error?.response?.data?.detail}</span>
                                             <button className={"btn btn-sm btn-danger"} onClick={() => declarationsSousTraitantVoyantsQuery.refetch()}>
                                                  Recharger
                                             </button>
                                        </>
                                   </MyAlert>
                              )}

                              {/* Month cards */}
                              {!declarationsSousTraitantVoyantsQuery.isFetching && !declarationsSousTraitantVoyantsQuery.isError && declarationsSousTraitantVoyantsQuery.data && (
                                   <>
                                        <div className={"row"}>
                                             {declarationsSousTraitantVoyantsQuery.data?.map((item, key) => (
                                                  <div className={"col-6 col-sm-3 col-md-3 mb-xl-0 mb-4"} key={key}>
                                                       <MyCard>
                                                            <MyCard.Header isCollapseIconShown={false}>
                                                                 <span className={"fas fa-calendar-day me-2 fs-3"}></span>
                                                                 <Link
                                                                      to={"/espace-resp-fournisseur/declarations/listing"}
                                                                      state={{ mois: item.mois.format("YYYY-MM-DD") }}
                                                                      className={"text-dark text-hover-primary"}
                                                                 >
                                                                      {FirstLetterUppercase(item.mois.format("MMM YYYY"))}
                                                                 </Link>
                                                            </MyCard.Header>
                                                            <MyCard.Body>
                                                                 <Voyants item={item.voyants} mois={item.mois} />
                                                            </MyCard.Body>
                                                       </MyCard>
                                                  </div>
                                             ))}
                                        </div>

                                        <div className="d-flex align-items-center my-5">
                                             <div className="border-bottom border-secondary mw-50 w-100"></div>
                                             <span className="fw-bold border-secondary fs-7 mx-2">OU</span>
                                             <div className="border-bottom border-secondary mw-50 w-100"></div>
                                        </div>

                                        {/* Choose month */}
                                        <div className="d-flex justify-content-center">
                                             <div className={"w-100 w-sm-50 w-lg-30 d-flex"}>
                                                  <MyDateMonthYearField name={"date"} value={date} onChange={setDate} placeholder={"Choisissez un mois"} />
                                                  <button
                                                       type="submit"
                                                       className="btn btn-sm btn-outline btn-outline-dark ms-2"
                                                       onClick={() => {
                                                            if (!date || date === "") {
                                                                 toast.error("Veuillez saisir un mois")
                                                            } else {
                                                                 navigate("/espace-resp-fournisseur/declarations/listing", {
                                                                      state: {
                                                                           mois: moment("01/" + date, "DD/MM/YYYY").format("YYYY-MM-DD"),
                                                                      },
                                                                 })
                                                            }
                                                       }}
                                                  >
                                                       <i className="fas fa-search text-dark"></i>
                                                  </button>
                                             </div>
                                        </div>
                                   </>
                              )}
                         </MyCard.Body>
                    </MyCard>
               </div>
          </div>
     )
}

export interface IGetConsultantsDeclarationsVoyantsResponseModel {
     mois: moment.Moment
     voyants: IGetConsultantsDeclarationsVoyantsItemResponseModel
}

export interface IGetConsultantsDeclarationsVoyantsItemResponseModel {
     totalUncreated: number
     totalEnCoursDeCreation: number
     totalEnCoursDeModification: number
     totalEnCoursDeRectification: number
     totalEnCoursSignature: number
     totalSigneParCollab: number
     totalSigneParResponsable: number
     totalDemandeRectifParSiege: number
     totalValidePourPaie: number
     totalSigneParPersonnelApresDemandeRegul: number
     totalEnAttenteDeValidationDeLaRegularisation: number
}

export default DeclarationsVoyants
