import { Link, useNavigate } from "react-router-dom"
import React, { Dispatch, SetStateAction } from "react"
import * as Yup from "yup"
import { useFormik } from "formik"
import { FormikStepOneValuesModel } from "../../../core/_models"
import moment from "moment"
import MySelectField from "../../../../../../utils/fields/MySelectField"
import { CONST_MOIS_LIST } from "../../../../../../constants"
import MyAlert from "../../../../../../utils/MyAlert"
import { toast } from "react-toastify"
import { useIntl } from "react-intl"
import { ndfCreate, ndfGetNdfByDate } from "../../../core/_request"
import { FirstLetterUppercase } from "../../../../../../helpers/helper-global"

const urlParams = new URLSearchParams(window.location.search)

export function NdfCreate({ consultant_ID }: { consultant_ID: number }) {
     const intl = useIntl()
     const urlParamsMois = urlParams.get("mois") as string | null
     const urlParamsAnnee = urlParams.get("annee") as string | null
     const navigate = useNavigate()

     const formStepPeriodeValidationSchema = Yup.object().shape({
          mois: Yup.string().required("Le mois est requis."),
          annee: Yup.string().required("L'année est requise."),
     })

     const formikStepOne = useFormik<FormikStepOneValuesModel>({
          initialValues: {
               mois: urlParamsMois || moment().format("MM"),
               annee: urlParamsAnnee || moment().format("YYYY"),
          },
          validationSchema: formStepPeriodeValidationSchema,
          onSubmit: (values, { setStatus, setSubmitting }) => {
               setSubmitting(true)

               // Check values
               // <MySelectField options={[{label: moment().subtract(1, 'year').format("YYYY"), value: moment().subtract(1, 'year').format("YYYY")}, {

               if (!CONST_MOIS_LIST.find(item => values.mois == item.value) || ![moment().subtract(1, "year").format("YYYY"), moment().format("YYYY")].includes(values.annee)) {
                    setStatus(`Vous ne pouvez pas soumettre pas soumettre un NDF pour le mois de ${values.mois}/${values.annee}`)
                    setSubmitting(false)
               } else {
                    // Check si la NDF existe
                    ndfGetNdfByDate(values.mois, values.annee)
                         .then(r => {
                              if (!r.data) {
                                   ndfCreate(consultant_ID, values.mois, values.annee)
                                        .then(r => {
                                             setSubmitting(false)
                                             navigate(`/activites/ndf/update`, {
                                                  state: {
                                                       ndf_id: r.data.id,
                                                       breadCrumbPageTitle: `${FirstLetterUppercase(moment(`${values.annee}-${values.mois}-01`).format("MMMM YYYY"))}`,
                                                  },
                                             })
                                        })
                                        .catch(e => {
                                             const error = e.response?.data?.detail ? e.response.data.detail : intl.formatMessage({ id: "FORM.STATUS.UNEXPECTED_ERROR_MESSAGE" })
                                             setStatus(error)
                                             toast.error(error, { autoClose: false })
                                             setSubmitting(false)
                                        })
                              } else {
                                   navigate(`/activites/ndf/update`, {
                                        state: {
                                             ndf_id: r.data.id,
                                             breadCrumbPageTitle: `${FirstLetterUppercase(moment(`${values.annee}-${values.mois}-01`).format("MMMM YYYY"))}`,
                                        },
                                   })
                              }
                         })
                         .catch(e => {
                              const error = e.response?.data?.detail ? e.response.data.detail : intl.formatMessage({ id: "FORM.STATUS.UNEXPECTED_ERROR_MESSAGE" })
                              setStatus(error)
                              toast.error(error, { autoClose: false })
                              setSubmitting(false)
                         })
               }
          },
     })

     return (
          <div className="card mb-5 mb-xl-10">
               <div className="card-body p-9">
                    <NdfStepperHeader stepperStep={"one"} />

                    <form onSubmit={formikStepOne.handleSubmit} className={"mx-auto mw-1000px"}>
                         {formikStepOne.status && (
                              <MyAlert type={"danger"} classNames={"mb-5"}>
                                   {formikStepOne.status}
                              </MyAlert>
                         )}

                         <h5 className={"mb-3"}>Choisissez une période</h5>
                         <div className="row mb-5">
                              <div className="col-sm-6 mb-sm-0 mb-3">
                                   <MySelectField
                                        options={CONST_MOIS_LIST}
                                        name={"mois"}
                                        value={formikStepOne.values.mois}
                                        isSearchable={false}
                                        onChange={value => formikStepOne.setFieldValue("mois", value)}
                                        placeholder={"Mois"}
                                   />
                                   {formikStepOne.errors.mois && (
                                        <div className="fv-plugins-message-container">
                                             <div className="fv-help-block">{formikStepOne.errors.mois}</div>
                                        </div>
                                   )}
                              </div>
                              <div className="col-sm-6">
                                   <MySelectField
                                        options={[
                                             { label: moment().subtract(1, "year").format("YYYY"), value: moment().subtract(1, "year").format("YYYY") },
                                             {
                                                  label: moment().format("YYYY"),
                                                  value: moment().format("YYYY"),
                                             },
                                        ]}
                                        name={"annee"}
                                        isSearchable={false}
                                        value={formikStepOne.values.annee}
                                        onChange={value => formikStepOne.setFieldValue("annee", value)}
                                        placeholder={"Année"}
                                   />
                                   {formikStepOne.errors.annee && (
                                        <div className="fv-plugins-message-container">
                                             <div className="fv-help-block">{formikStepOne.errors.annee}</div>
                                        </div>
                                   )}
                              </div>
                         </div>

                         {/* FOOTER */}
                         <div className="d-flex flex-stack">
                              <Link to="/activites/ndf " className={`btn btn-sm btn-light-primary me-2`}>
                                   <span className={"fas fa-list-dots fs-1 me-3 align-middle"} /> Retour à la liste des NDF
                              </Link>

                              <button type="submit" className="btn btn-md-lg btn-sm btn-primary" disabled={formikStepOne.isSubmitting}>
                                   {!formikStepOne.isSubmitting && (
                                        <>
                                             Suivant <span className={"fas fa-arrow-right fs-3 ms-1 align-middle"} />
                                        </>
                                   )}

                                   {formikStepOne.isSubmitting && <span>Un instant ...</span>}
                              </button>
                         </div>
                    </form>
               </div>
          </div>
     )
}

type StepperHeaderProps = { stepperStep: "one" | "two"; setStepperStep?: Dispatch<SetStateAction<"one" | "two">> }
export const NdfStepperHeader: React.FC<StepperHeaderProps> = ({ stepperStep, setStepperStep }) => {
     return (
          <div className="stepper stepper-links mb-5">
               <div className="stepper-nav">
                    {/* begin::Step 1 */}
                    <div className={`stepper-item ${stepperStep == "one" && "current"} ${["two", "two", "four"].includes(stepperStep) && "completed"}`}>
                         <h3 className="stepper-title">
                              <span className={"d-none d-sm-block"}>1. Période</span>
                              <span className={"d-block d-sm-none fas fa-calendar-plus"} style={{ fontSize: "2rem" }}></span>
                         </h3>
                    </div>
                    {/* end::Step 1 */}
                    {/* begin::Step 2 */}
                    <div
                         className={`stepper-item ${stepperStep == "two" && "current"} ${["one", "two"].includes(stepperStep) && "pending"} ${
                              !["one", "two"].includes(stepperStep) && "cursor-pointer"
                         }`}
                         onClick={() => !["one", "two"].includes(stepperStep) && setStepperStep!("two")}
                    >
                         <h3 className="stepper-title">
                              <span className={"d-none d-sm-block"}>2. Dépenses</span>
                              <span className={"d-block d-sm-none fas fa-file-invoice"} style={{ fontSize: "2rem" }}></span>
                         </h3>
                    </div>
                    {/* end::Step 2 */}
               </div>
          </div>
     )
}
