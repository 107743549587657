import React, { useContext, useState } from "react"
import { useThemeMode } from "../../../../../../_metronic/partials"
import FileAndDriveHelper from "../../../../../helpers/FileAndDriveHelper"
import { toAbsoluteUrl } from "../../../../../../_metronic/helpers"
import * as Yup from "yup"
import {
     API_ESPACE_FOURNISSEUR_RESP_SET_DETAILS_COLLAB_BY_RESP_FOURNISSEUR_ENDPOINT,
     API_PUBLIC_GET_PAYS_INDICATIFS_TEL_URL,
     CONST_CIVILITE_NON_RENSEIGNEE,
     CONST_CIVILITES_OPTIONS_FOR_SELECT2,
     CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR,
     CONST_MIME_IMAGE_JPEG,
     CONST_MIME_IMAGE_JPG,
     CONST_MIME_IMAGE_PNG,
} from "../../../../../constants"
import { Form, Formik, FormikHelpers } from "formik"
import axios, { AxiosError } from "axios"
import { ConsultantModel, IHttpErrorResponseModel } from "../../../../../models"
import { toast } from "react-toastify"
import MyAlert from "../../../../../utils/MyAlert"
import MyLoadingOverlay from "../../../../../utils/MyLoadingOverlay"
import MyInputField from "../../../../../utils/fields/MyInputField"
import MySelectGetAllDataFromServer from "../../../../../utils/fields/MySelectGetAllDataFromServer"
import MySimpleSpinner from "../../../../../utils/MySimpleSpinner"
import { FournisseurRespGetCollaborateurDetailsContext } from "../FournisseurRespGetCollaborateurDetails"
import { useQueryClient } from "react-query"
import MySelectField from "../../../../../utils/fields/MySelectField"
import MyInptuMaskField from "../../../../../utils/fields/MyInputMaskField"

const Edition = () => {
     // Hooks
     const { detailsQuery, setIsEditingDetails } = useContext(FournisseurRespGetCollaborateurDetailsContext)
     const { mode } = useThemeMode()
     const queryClient = useQueryClient()

     // Vars
     const details = detailsQuery.data!
     const user = details.candidat?.user!

     // States
     const [isAvatarLoading, setIsAvatarLoading] = useState<boolean>(true)
     const [avatarPreview, setAvatarPreview] = useState<any>(
          user.avatarGoogleDriveId
               ? FileAndDriveHelper.getThumbnailDriveUrl(user.avatarGoogleDriveId)
               : toAbsoluteUrl(mode == "dark" ? "/media/svg/files/blank-image-dark.svg" : "/media/svg/files/blank-image.svg")
     )

     // Schema
     const schema = Yup.object().shape({
          civilite: Yup.string().required().nullable().label(`"Civilité"`),
          nom: Yup.string().required().nullable().label(`"Nom"`),
          prenom: Yup.string().required().nullable().label(`"Prenom"`),
          email: Yup.string().required().nullable().label(`"Email"`),
          telIndicatif: Yup.number().required().nullable().label(`"Indicatif"`),
          tel: Yup.string().required().nullable().label(`"Tél."`),
          avatar: Yup.mixed()
               .nullable()
               .test("fileFormat", "Seuls les formats JPEG ou PNG sont acceptés", (value: any) => {
                    if (!value) return true // If no file is provided, skip the test
                    return [CONST_MIME_IMAGE_PNG, CONST_MIME_IMAGE_JPEG, CONST_MIME_IMAGE_JPG].includes(value.type)
               })
               .test("fileSize", "Le document ne doit pas dépasser le 10MB", (value: any) => {
                    if (!value) return true // If no file is provided, skip the test
                    return value.size <= 10 * 1024 * 1024
               }),
     })

     // Init values
     const initialValues: ICollabEditDetailsByFournisseurRespModel = {
          civilite: user.civilite,
          nom: user.nom,
          prenom: user.prenom,
          email: user.username?.includes(".merci") ? null : user.username,
          telIndicatif: user.telIndicatif?.id,
          tel: user?.tel,
     }

     // Handle submit
     function handleSubmit(values: ICollabEditDetailsByFournisseurRespModel, helpers: FormikHelpers<ICollabEditDetailsByFournisseurRespModel>) {
          helpers.setStatus(null)

          // Prepare payload
          const formData = new FormData()
          for (const [key, value] of Object.entries(values)) {
               if (key === "avatar" && value) {
                    formData.append("avatar", value)
               } else {
                    formData.append(`data[${key}]`, value)
               }
          }

          // Request
          axios.post<ConsultantModel>(API_ESPACE_FOURNISSEUR_RESP_SET_DETAILS_COLLAB_BY_RESP_FOURNISSEUR_ENDPOINT(details.id!), formData)
               .then(r => {
                    toast.success("Vos modifications ont bien été prises en compte.")
                    queryClient.setQueryData("GET_CONSULTANT_DETAILS", r.data)
                    setIsEditingDetails(false)
               })
               .catch((e: AxiosError) => {
                    const error: IHttpErrorResponseModel = e.response?.data

                    // Set form errors
                    if (error?.code === CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR && error?.errors) {
                         for (const key in error.errors) helpers.setFieldError(key, error.errors[key])
                    }

                    // Set form global status and notify user using a toast
                    helpers.setStatus(error?.detail)
                    toast.error(error?.detail, { autoClose: false })

                    // Stop submit loader
                    helpers.setSubmitting(false)
               })
               .finally(() => helpers.setSubmitting(false))
     }

     return (
          <>
               <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={schema}>
                    {helpers => {
                         return (
                              <Form noValidate autoComplete="off">
                                   {helpers.status && (
                                        <MyAlert type={"danger"} classNames={"mb-4"}>
                                             {helpers.status}
                                        </MyAlert>
                                   )}

                                   {/* Field avatar */}
                                   <div className="row mb-4">
                                        <label className="col-lg-4 col-form-label fw-bold fs-6">Avatar</label>
                                        <div className="col-lg-8">
                                             <div className="image-input image-input-outline" data-kt-image-input="true" style={{ backgroundImage: `url(${avatarPreview})` }}>
                                                  <MyLoadingOverlay isActive={isAvatarLoading}>
                                                       <img
                                                            className="image-input-wrapper w-125px h-125px"
                                                            src={avatarPreview}
                                                            onLoad={() => setIsAvatarLoading(false)}
                                                            alt={"avatar"}
                                                       ></img>
                                                  </MyLoadingOverlay>

                                                  <label
                                                       className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                                                       data-kt-image-input-action="change"
                                                       data-bs-original-title="Change avatar"
                                                  >
                                                       <i className="bi bi-pencil-fill fs-7"></i>
                                                       <input
                                                            type="file"
                                                            name="avatar"
                                                            accept=".png, .jpg, .jpeg"
                                                            onChange={e => {
                                                                 const file = e.target.files![0]
                                                                 helpers.setFieldValue("avatar", file)
                                                                 const reader = new FileReader()
                                                                 reader.onloadend = () => {
                                                                      // Set the image preview URL
                                                                      setAvatarPreview(reader.result as string)
                                                                 }
                                                                 reader.readAsDataURL(file)
                                                            }}
                                                       />
                                                  </label>
                                             </div>
                                        </div>
                                   </div>
                                   {helpers.errors.avatar && <div className={"text-danger"}>{helpers.errors.avatar}</div>}

                                   {/* Civilité */}
                                   <div className="row mb-5">
                                        <label className="col-lg-4 col-form-label fw-bold fs-6 required">Nom</label>

                                        <div className="col-lg-8">
                                             <MySelectField
                                                  options={CONST_CIVILITES_OPTIONS_FOR_SELECT2.filter(item => item.value !== CONST_CIVILITE_NON_RENSEIGNEE)}
                                                  name={"civilite"}
                                                  value={helpers.values.civilite}
                                                  isSearchable={false}
                                                  onChange={value => helpers.setFieldValue("civilite", value)}
                                                  placeholder={"Choisissez une civilité"}
                                                  isClearable={true}
                                             />
                                             <div className={"mb-2"}>{helpers.errors.civilite && <div className={"text-danger"}>{helpers.errors.civilite}</div>}</div>
                                        </div>
                                   </div>

                                   {/* Nom */}
                                   <div className="row mb-5">
                                        <label className="col-lg-4 col-form-label fw-bold fs-6 required">Nom</label>

                                        <div className="col-lg-8">
                                             <MyInputField
                                                  name={"nom"}
                                                  value={helpers.values.nom}
                                                  placeholder={"Nom"}
                                                  onChange={val => helpers.setFieldValue("nom", val)}
                                                  isInvalid={!!helpers.errors.nom}
                                             />

                                             <div className={"mb-2"}>{helpers.errors.nom && <div className={"text-danger"}>{helpers.errors.nom}</div>}</div>
                                        </div>
                                   </div>

                                   {/* Prénom */}
                                   <div className="row mb-5">
                                        <label className="col-lg-4 col-form-label fw-bold fs-6 required">Preném</label>

                                        <div className="col-lg-8">
                                             <MyInputField
                                                  name={"prenom"}
                                                  value={helpers.values.prenom}
                                                  onChange={val => helpers.setFieldValue("prenom", val)}
                                                  placeholder={"Prénom"}
                                                  isInvalid={!!helpers.errors.prenom}
                                             />
                                             <div className={"mb-2"}>{helpers.errors.prenom && <div className={"text-danger"}>{helpers.errors.prenom}</div>}</div>
                                        </div>
                                   </div>

                                   {/* email */}
                                   <div className="row mb-5">
                                        <label className="col-lg-4 col-form-label fw-bold fs-6 required">Email</label>

                                        <div className="col-lg-8">
                                             <MyInputField
                                                  name={"email"}
                                                  value={helpers.values.email}
                                                  onChange={val => helpers.setFieldValue("email", val)}
                                                  placeholder={"Email"}
                                                  isInvalid={!!helpers.errors.email}
                                             />
                                             <div className={"mb-2"}>{helpers.errors.email && <div className={"text-danger"}>{helpers.errors.email}</div>}</div>
                                        </div>
                                   </div>

                                   {/* Tél. */}
                                   <div className="row mb-5">
                                        <label className="col-lg-4 col-form-label fw-bold fs-6 required">Téléphone</label>

                                        <div className="col-lg-8">
                                             <div className="row">
                                                  <div className="col-lg-6 mb-2 mb-lg-0">
                                                       <MySelectGetAllDataFromServer
                                                            name={"telIndicatif"}
                                                            value={helpers.values.telIndicatif}
                                                            url={API_PUBLIC_GET_PAYS_INDICATIFS_TEL_URL}
                                                            defaultOptions={
                                                                 user.telIndicatif
                                                                      ? [
                                                                             {
                                                                                  value: user.telIndicatif.id as number,
                                                                                  label: user.telIndicatif.phoneCodeWithPlusPrefixAndCountry as string,
                                                                             },
                                                                        ]
                                                                      : []
                                                            }
                                                            placeholder={"Choisissez un indicatif"}
                                                            onChange={val => helpers.setFieldValue("telProIndicatif", val)}
                                                       />
                                                       {helpers.errors.telIndicatif && <div className={"text-danger"}>{helpers.errors.telIndicatif}</div>}
                                                  </div>
                                                  <div className="col-lg-6">
                                                       <MyInptuMaskField
                                                            name={"telPro"}
                                                            mask={"99 9999999999999999999999999"}
                                                            value={helpers.values.tel}
                                                            isInvalid={!!helpers.errors.tel}
                                                            placeholder={"Téléphone"}
                                                            onChange={e => helpers.setFieldValue("tel", e.target.value)}
                                                       />
                                                       {helpers.errors.tel && <div className={"text-danger"}>{helpers.errors.tel}</div>}
                                                  </div>
                                             </div>
                                        </div>
                                   </div>

                                   <div className="separator bg-primary my-5" />

                                   {/* Submit button */}
                                   <div className={"d-flex justify-content-end gap-5"}>
                                        <button
                                             className={"btn btn-sm btn-outline btn-active-secondary d-inline-flex align-items-center"}
                                             type={"button"}
                                             onClick={() => setIsEditingDetails(false)}
                                             disabled={helpers.isSubmitting}
                                        >
                                             Annuler
                                        </button>
                                        <button className={"btn btn-sm btn-primary d-inline-flex align-items-center"} type={"submit"} disabled={helpers.isSubmitting}>
                                             {!helpers.isSubmitting ? <>Appliquer les modifications</> : <MySimpleSpinner size={"sm"} />}
                                        </button>
                                   </div>
                              </Form>
                         )
                    }}
               </Formik>
          </>
     )
}

interface ICollabEditDetailsByFournisseurRespModel {
     civilite?: string | null
     nom?: string | null
     prenom?: string | null
     email?: string | null
     codePostal?: string | null
     telIndicatif?: number | null
     tel?: string | null
     avatar?: File | null
}

export default Edition
