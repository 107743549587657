import { useQuery } from "react-query"
import { AxiosError } from "axios"
import { toast } from "react-toastify"
import { Link } from "react-router-dom"
import React from "react"
import { ConsultantDeclarationMensuelleModel, IHttpErrorResponseModel } from "../../../../../models"
import { declarationListing } from "../../core/_request"
import { useConsultantDeclarationActions } from "./core/_hooks"
import { FirstLetterUppercase } from "../../../../../helpers/helper-global"
import {
     CONST_CONSULTANT_CONTRAT_ST,
     CONST_CONSULTANT_STATUT_ST_FREELANCE,
     CONST_CONSULTANT_STATUT_ST_PORTAGE,
     CONST_CONSULTANT_STATUT_ST_SOCIETE_SERVICE,
     CONST_DECLARATION_SUIVI_TYPE_INTERESSE_DEMANDE_REGUL,
     CONST_DECLARATION_SUIVI_TYPE_INTERESSE_FINALISATION,
     CONST_DECLARATION_SUIVI_TYPE_INTERESSE_RECTIFICATION_SANS_DEMANDE,
     CONST_DECLARATION_SUIVI_TYPE_INTERESSE_SIGNATURE,
     CONST_DECLARATION_SUIVI_TYPE_RESP_DEMANDE_RECTIFICATION,
     CONST_DECLARATION_SUIVI_TYPE_RESP_REJET_REGUL,
     CONST_DECLARATION_SUIVI_TYPE_RESP_VALIDATION,
     CONST_DECLARATION_SUIVI_TYPE_RESP_VALIDATION_REGUL,
     CONST_DECLARATION_SUIVI_TYPE_SIEGE_DEMANDE_RECTIFICATION,
     CONST_DECLARATION_SUIVI_TYPE_SIEGE_VALIDATION,
} from "../../../../../constants"
import MyCard from "../../../../../utils/MyCard"
import MySimpleSpinner from "../../../../../utils/MySimpleSpinner"
import MyAlert from "../../../../../utils/MyAlert"
import MyTooltip from "../../../../../utils/MyTooltip"
import { DeclarationsListingResponseModel } from "../../core/_models"

const REACT_QUERY_KEY_GET_LISTING = "REACT_QUERY_KEY_GET_LISTING"
export const DeclarationList = ({ consultant_ID, onClickOnConsultationButtonCallback }: IPropsModel) => {
     const listingQuery = useQuery<DeclarationsListingResponseModel, AxiosError>(REACT_QUERY_KEY_GET_LISTING, async () => {
          return declarationListing(consultant_ID)
               .then(r => {
                    return r.data
               })
               .catch((e: AxiosError) => {
                    const error: IHttpErrorResponseModel = e.response?.data

                    toast.error(error?.detail, { autoClose: false })

                    throw e
               })
     })

     const { actions } = useConsultantDeclarationActions()

     function entamerRectificationSansValidationRespOuSiege(declaration: ConsultantDeclarationMensuelleModel) {
          actions.entamerRectificationSansDemandeValidation(declaration.id as number, () => onClickOnConsultationButtonCallback(declaration, "recap"))
     }

     function handleDemandeRegulCallback() {
          listingQuery.refetch().then()
     }

     const BadgeAccordingToEtat = ({ declaration }: { declaration: ConsultantDeclarationMensuelleModel }) => {
          return (
               <div>
                    {[null, undefined, CONST_DECLARATION_SUIVI_TYPE_INTERESSE_RECTIFICATION_SANS_DEMANDE].includes(declaration.etat?.type) && (
                         <span className="badge badge-warning text-black">À COMPLÉTER</span>
                    )}
                    {[CONST_DECLARATION_SUIVI_TYPE_INTERESSE_FINALISATION].includes(declaration.etat?.type as string) && (
                         <span className="badge badge-warning text-black">À SIGNER</span>
                    )}
                    {[
                         CONST_DECLARATION_SUIVI_TYPE_INTERESSE_SIGNATURE,
                         CONST_DECLARATION_SUIVI_TYPE_RESP_VALIDATION,
                         CONST_DECLARATION_SUIVI_TYPE_SIEGE_DEMANDE_RECTIFICATION,
                    ].includes(declaration.etat?.type as string) && <span className="badge badge-light-success">ENVOYÉE</span>}
                    {[CONST_DECLARATION_SUIVI_TYPE_RESP_DEMANDE_RECTIFICATION, CONST_DECLARATION_SUIVI_TYPE_RESP_VALIDATION_REGUL].includes(declaration.etat?.type as string) && (
                         <span className="badge badge-light-warning">À RECTIFIER</span>
                    )}
                    {[CONST_DECLARATION_SUIVI_TYPE_SIEGE_VALIDATION, CONST_DECLARATION_SUIVI_TYPE_INTERESSE_DEMANDE_REGUL, CONST_DECLARATION_SUIVI_TYPE_RESP_REJET_REGUL].includes(
                         declaration.etat?.type as string
                    ) && <span className="badge badge-success">Validée</span>}
               </div>
          )
     }

     return (
          <>
               {listingQuery.isFetching && (
                    <div className={"text-center"}>
                         <MySimpleSpinner size={"xl"} />
                    </div>
               )}

               {!listingQuery.isFetching && !listingQuery.isError && listingQuery.data && listingQuery.data.declarations.length === 0 && (
                    <MyAlert type={"primary"}>
                         <div>Aucune déclaration de disponible</div>
                    </MyAlert>
               )}

               {listingQuery.isError && (
                    <MyAlert type={"danger"}>
                         <div className={"d-flex align-items-center"}>
                              <div className={"me-2"}>{listingQuery.error.response?.data.detail}</div>
                              <button className={"btn btn-sm btn-danger d-flex align-items-center p-0 px-3 py-2"} onClick={() => listingQuery.refetch()}>
                                   Recharger
                              </button>
                         </div>
                    </MyAlert>
               )}

               {!listingQuery.isFetching && !listingQuery.isError && listingQuery.data && listingQuery.data.declarations.length > 0 && (
                    <>
                         <div className={"row"}>
                              {listingQuery.data.declarations.map((item, key) => (
                                   <div className="col-md-6 col-xl-4 mb-9 card" key={key}>
                                        <div className="card-header border border-primary bg-primary" style={{ minHeight: 0 }}>
                                             <div className="card-title">
                                                  <div
                                                       style={{ color: "inherit" }}
                                                       onClick={() => {
                                                            onClickOnConsultationButtonCallback(item, "recap")
                                                       }}
                                                  >
                                                       {FirstLetterUppercase(item.mois?.parseZone().format("MMMM YYYY"))}
                                                  </div>
                                             </div>
                                             <div className="card-toolbar">
                                                  <BadgeAccordingToEtat declaration={item} />
                                             </div>
                                        </div>
                                        <div className="card-body text-center border-start border-end border-bottom border-primary p-4">
                                             <div className={"d-flex justify-content-center"}>
                                                  {listingQuery.data.consultant.contrat !== CONST_CONSULTANT_CONTRAT_ST && (
                                                       <MyTooltip title={"Arrêts maladie"}>
                                                            <div
                                                                 style={{ verticalAlign: "middle", cursor: "pointer" }}
                                                                 onClick={() => {
                                                                      onClickOnConsultationButtonCallback(item, "arrets")
                                                                 }}
                                                                 className={`p-3`}
                                                            >
                                                                 <span className={"fas fa-file-medical text-dark"} style={{ fontSize: "2rem" }} />
                                                            </div>
                                                       </MyTooltip>
                                                  )}

                                                  {listingQuery.data.consultant.contrat !== CONST_CONSULTANT_CONTRAT_ST && (
                                                       <MyTooltip title={"Demandes de congés"}>
                                                            <div
                                                                 style={{ verticalAlign: "middle", cursor: "pointer" }}
                                                                 onClick={() => {
                                                                      onClickOnConsultationButtonCallback(item, "ddc")
                                                                 }}
                                                                 className={`p-3`}
                                                            >
                                                                 <span className={"fas fa-umbrella-beach text-dark"} style={{ fontSize: "2rem" }} />
                                                            </div>
                                                       </MyTooltip>
                                                  )}

                                                  {listingQuery.data.consultant.contrat === CONST_CONSULTANT_STATUT_ST_FREELANCE && (
                                                       <MyTooltip title={"CRA"}>
                                                            <div
                                                                 style={{ verticalAlign: "middle", cursor: "pointer" }}
                                                                 onClick={() => {
                                                                      onClickOnConsultationButtonCallback(item, "cra")
                                                                 }}
                                                                 className={`p-3`}
                                                            >
                                                                 <span className={"fas fa-calendar-days text-dark"} style={{ fontSize: "2rem" }} />
                                                            </div>
                                                       </MyTooltip>
                                                  )}

                                                  {listingQuery.data.consultant.contrat === CONST_CONSULTANT_CONTRAT_ST && (
                                                       <MyTooltip title={"Factures"}>
                                                            <div
                                                                 style={{ verticalAlign: "middle", cursor: "pointer" }}
                                                                 onClick={() => {
                                                                      onClickOnConsultationButtonCallback(item, "factures")
                                                                 }}
                                                                 className={`p-3`}
                                                            >
                                                                 <span className={"fas fa-file-alt text-dark"} style={{ fontSize: "2rem" }} />
                                                            </div>
                                                       </MyTooltip>
                                                  )}

                                                  <MyTooltip title={"NDF"}>
                                                       <div
                                                            style={{ verticalAlign: "middle", cursor: "pointer" }}
                                                            onClick={() => {
                                                                 onClickOnConsultationButtonCallback(item, "ndf")
                                                            }}
                                                            className={`p-3`}
                                                       >
                                                            <span className={"fas fa-file-invoice-dollar text-dark"} style={{ fontSize: "2rem" }} />
                                                       </div>
                                                  </MyTooltip>

                                                  {listingQuery.data.consultant.contrat !== CONST_CONSULTANT_CONTRAT_ST && (
                                                       <MyTooltip title={"TR"}>
                                                            <div
                                                                 style={{ verticalAlign: "middle", cursor: "pointer" }}
                                                                 onClick={() => {
                                                                      onClickOnConsultationButtonCallback(item, "ticket_resto")
                                                                 }}
                                                                 className={`p-3`}
                                                            >
                                                                 <span className={"fas fa-utensils text-dark"} style={{ fontSize: "2rem" }} />
                                                            </div>
                                                       </MyTooltip>
                                                  )}
                                                  <MyTooltip title={"Récapitulatif"}>
                                                       <div
                                                            style={{ verticalAlign: "middle", cursor: "pointer" }}
                                                            onClick={() => {
                                                                 onClickOnConsultationButtonCallback(item, "recap")
                                                            }}
                                                            className={`p-3`}
                                                       >
                                                            <span className={"fas fa-list text-dark"} style={{ fontSize: "2rem" }} />
                                                       </div>
                                                  </MyTooltip>
                                             </div>

                                             <div className="separator separator-dashed mb-2"></div>

                                             <div className="d-flex justify-content-center">
                                                  {[
                                                       undefined,
                                                       CONST_DECLARATION_SUIVI_TYPE_INTERESSE_FINALISATION,
                                                       CONST_DECLARATION_SUIVI_TYPE_INTERESSE_RECTIFICATION_SANS_DEMANDE,
                                                  ].includes(item.etat?.type as string) && (
                                                       <>
                                                            <MyTooltip title={"Consulter"}>
                                                                 <button
                                                                      type={"button"}
                                                                      className={"btn btn-sm btn-primary me-2"}
                                                                      onClick={() => onClickOnConsultationButtonCallback(item, "recap")}
                                                                 >
                                                                      <span className={"fas fa-eye align-middle fs-4"} />
                                                                 </button>
                                                            </MyTooltip>
                                                            <button
                                                                 type={"button"}
                                                                 className={"btn btn-sm btn-success"}
                                                                 onClick={() => onClickOnConsultationButtonCallback(item, "recap")}
                                                            >
                                                                 {[CONST_CONSULTANT_STATUT_ST_PORTAGE, CONST_CONSULTANT_STATUT_ST_SOCIETE_SERVICE].includes(
                                                                      listingQuery.data.consultant.statut!
                                                                 ) ? (
                                                                      <>
                                                                           Envoyer <span className={"fas fa-paper-plane align-middle fs-4"} />
                                                                      </>
                                                                 ) : (
                                                                      <>
                                                                           Signer <span className={"fas fa-signature align-middle fs-4"} />
                                                                      </>
                                                                 )}
                                                            </button>
                                                       </>
                                                  )}

                                                  {[CONST_DECLARATION_SUIVI_TYPE_INTERESSE_SIGNATURE].includes(item.etat?.type as string) && (
                                                       <>
                                                            <MyTooltip title={"Apporter une modification"}>
                                                                 <button
                                                                      type={"button"}
                                                                      className={"btn btn-sm btn-warning me-2"}
                                                                      onClick={() => entamerRectificationSansValidationRespOuSiege(item)}
                                                                 >
                                                                      <span className={"fas fa-edit align-middle fs-4"} />
                                                                 </button>
                                                            </MyTooltip>

                                                            <MyTooltip title={"Consulter"}>
                                                                 <button
                                                                      type={"button"}
                                                                      className={"btn btn-sm btn-primary"}
                                                                      onClick={() => onClickOnConsultationButtonCallback(item, "recap")}
                                                                 >
                                                                      <span className={"fas fa-eye align-middle fs-4"} />
                                                                 </button>
                                                            </MyTooltip>
                                                       </>
                                                  )}

                                                  {[CONST_DECLARATION_SUIVI_TYPE_RESP_DEMANDE_RECTIFICATION, CONST_DECLARATION_SUIVI_TYPE_RESP_VALIDATION_REGUL].includes(
                                                       item.etat?.type as string
                                                  ) && (
                                                       <button
                                                            type={"button"}
                                                            className={"btn btn-sm btn-danger"}
                                                            onClick={() => onClickOnConsultationButtonCallback(item, "recap")}
                                                       >
                                                            Apporter une correction <i className={"fas fa-edit align-middle fs-4"} />
                                                       </button>
                                                  )}

                                                  {[
                                                       CONST_DECLARATION_SUIVI_TYPE_INTERESSE_DEMANDE_REGUL,
                                                       CONST_DECLARATION_SUIVI_TYPE_RESP_VALIDATION,
                                                       CONST_DECLARATION_SUIVI_TYPE_SIEGE_DEMANDE_RECTIFICATION,
                                                  ].includes(item.etat?.type as string) && (
                                                       <button
                                                            type={"button"}
                                                            className={"btn btn-sm btn-primary"}
                                                            onClick={() => onClickOnConsultationButtonCallback(item, "recap")}
                                                       >
                                                            Consulter <i className={"fas fa-eye align-middle fs-4"} />
                                                       </button>
                                                  )}

                                                  {[CONST_DECLARATION_SUIVI_TYPE_SIEGE_VALIDATION, CONST_DECLARATION_SUIVI_TYPE_RESP_REJET_REGUL].includes(
                                                       item.etat?.type as string
                                                  ) && (
                                                       <>
                                                            <MyTooltip title={"Consulter"}>
                                                                 <button
                                                                      type={"button"}
                                                                      className={"btn btn-sm btn-primary me-2"}
                                                                      onClick={() => onClickOnConsultationButtonCallback(item, "recap")}
                                                                 >
                                                                      <span className={"fas fa-eye align-middle fs-4"} />
                                                                 </button>
                                                            </MyTooltip>
                                                            <MyTooltip title={"Demander une régularisation"}>
                                                                 <button
                                                                      type={"button"}
                                                                      className={"btn btn-sm btn-danger me-2"}
                                                                      onClick={() => actions.demandeRegul(item?.id as number, handleDemandeRegulCallback)}
                                                                 >
                                                                      <span className={"fas fa-hand align-middle fs-4"} />
                                                                 </button>
                                                            </MyTooltip>
                                                       </>
                                                  )}
                                             </div>
                                        </div>
                                   </div>
                              ))}
                         </div>
                    </>
               )}
          </>
     )
}

export const DeclarationListWrapper = ({ consultant_ID, onClickOnConsultationButtonCallback }: IPropsModel) => {
     return (
          <div className={"d-flex justify-content-center"}>
               <div className={"w-100 w-lg-85 w-xxl-85"}>
                    <MyCard>
                         <MyCard.Header
                              rightSideContent={
                                   <>
                                        <Link className={`btn btn-sm btn-primary d-flex align-items-center`} to={"new"}>
                                             Nouvelle déclaration <span className={"fas fa-plus-circle fs-3 ms-1"} />
                                        </Link>
                                   </>
                              }
                         >
                              Mes déclarations
                         </MyCard.Header>
                         <MyCard.Body>
                              <DeclarationList consultant_ID={consultant_ID} onClickOnConsultationButtonCallback={onClickOnConsultationButtonCallback} />
                         </MyCard.Body>
                    </MyCard>
               </div>
          </div>
     )
}

interface IPropsModel {
     consultant_ID: number
     onClickOnConsultationButtonCallback: (declaration: ConsultantDeclarationMensuelleModel, defaultStep: any) => void
}
