import { useAuth } from "../../../../../auth"
import React from "react"
import { CONST_CONSULTANT_CONTRAT_ST } from "../../../../../../constants"
import { InfoPersoViewOnlyEspaceConsultantFreelance } from "./InfoPersoViewOnlyEspaceConsultantFreelance"
import { InfoPersoViewOnlyEspaceConsultantSalarie } from "./InfoPersoViewOnlyEspaceConsultantSalarie"

export function InfoPersoViewOnlyEspaceConsultant() {
     const { currentUser } = useAuth()

     return (
          <>
               {currentUser?.consultant?.consultant?.contrat === CONST_CONSULTANT_CONTRAT_ST ? (
                    <InfoPersoViewOnlyEspaceConsultantFreelance />
               ) : (
                    <InfoPersoViewOnlyEspaceConsultantSalarie />
               )}
          </>
     )
}
