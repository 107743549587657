import { FC } from "react"
import clsx from "clsx"
import { Link } from "react-router-dom"
import { useLocation } from "react-router"
import { checkIsActive, KTSVG, WithChildren } from "../../../../helpers"

type Props = {
     to: string
     target?: "_self" | "_blank"
     title: string
     icon?: string
     fontIcon?: string
     hasBullet?: boolean
     iconColor?: "text-warning" | "text-danger" | "text-primary" | "text-success"
     onClickCallback?: () => void
}

const SidebarMenuItem: FC<Props & WithChildren> = ({ children, to, target = "_self", title, icon, fontIcon, hasBullet = false, iconColor, onClickCallback = () => {} }) => {
     const { pathname } = useLocation()
     const isActive = checkIsActive(pathname, to)

     return (
          <div className="menu-item" onClick={onClickCallback}>
               <Link className={clsx("menu-link without-sub", { active: isActive })} to={to} target={target}>
                    {hasBullet && (
                         <span className="menu-bullet">
                              <span className="bullet bullet-dot"></span>
                         </span>
                    )}
                    {icon && (
                         <span className="menu-icon">
                              {" "}
                              <KTSVG path={icon} className={`svg-icon-2 ${iconColor}`} />
                         </span>
                    )}
                    {fontIcon && <i className={clsx("fs-3 text-white text-center", fontIcon)} style={{ width: "23px" }}></i>}
                    <span className="menu-title ms-2">{title}</span>
               </Link>
               {children}
          </div>
     )
}

export { SidebarMenuItem }
