import { ConsultantModel, IFournisseurResponsableModel, IHttpErrorResponseModel } from "../../../models"
import * as Yup from "yup"
import { Form, Formik, FormikHelpers } from "formik"
import axios, { AxiosError } from "axios"
import {
     API_ESPACE_FOURNISSEUR_RESP_GET_RESP_FOURNISSEUR_ENDPOINT,
     API_ESPACE_FOURNISSEUR_RESP_SET_RESP_FOURNISSEUR_COLLAB_ENDPOINT,
     CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR,
} from "../../../constants"
import { toast } from "react-toastify"
import MyAlert from "../../../utils/MyAlert"
import MySimpleSpinner from "../../../utils/MySimpleSpinner"
import MySelectGetAllDataFromServer from "../../../utils/fields/MySelectGetAllDataFromServer"
import React from "react"

const ChangementDeResponsable = ({ consultants, onSuccessCallback, onCancelCallback }: IPropsModel) => {
     const schema = Yup.object().shape({
          responsable: Yup.number().label(`"Responsable"`).required(),
     })

     const initialValues: ISetResponsableCollabRequestModel = {}

     function handleSubmit(values: ISetResponsableCollabRequestModel, helpers: FormikHelpers<ISetResponsableCollabRequestModel>) {
          helpers.setStatus(null)

          axios.post<IFournisseurResponsableModel>(API_ESPACE_FOURNISSEUR_RESP_SET_RESP_FOURNISSEUR_COLLAB_ENDPOINT, {
               ...values,
               consultants: consultants.map(item => item.id),
          })
               .then(r => {
                    if (onSuccessCallback) onSuccessCallback(r.data)
               })
               .catch((e: AxiosError) => {
                    const error: IHttpErrorResponseModel = e.response?.data

                    // Set form errors
                    if (error?.code === CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR && error?.errors) {
                         for (const key in error.errors) helpers.setFieldError(key, error.errors[key])
                    }

                    // Set form global status and notify user using a toast
                    helpers.setStatus(error?.detail)
                    toast.error(error?.detail, { autoClose: false })

                    // Stop submit loader
                    helpers.setSubmitting(false)
               })
     }

     return (
          <>
               <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={schema}>
                    {helpers => {
                         return (
                              <Form noValidate autoComplete="off">
                                   {helpers.status && (
                                        <MyAlert type={"danger"} classNames={"mb-4"}>
                                             {helpers.status}
                                        </MyAlert>
                                   )}

                                   <MySelectGetAllDataFromServer
                                        url={API_ESPACE_FOURNISSEUR_RESP_GET_RESP_FOURNISSEUR_ENDPOINT}
                                        method={"GET"}
                                        name={"responsable"}
                                        value={helpers.values.responsable}
                                        isClearable={false}
                                        placeholder={"Choisissez le nouveau responsable"}
                                        onChange={val => helpers.setFieldValue("responsable", val)}
                                        className={"mb-5"}
                                   />

                                   <>
                                        {/* Submit button */}
                                        <div className={"d-flex justify-content-end"}>
                                             <button
                                                  className={"btn btn-sm btn-outline btn-outline-primary d-inline-flex align-items-center me-2"}
                                                  type={"button"}
                                                  onClick={onCancelCallback}
                                                  disabled={helpers.isSubmitting}
                                             >
                                                  Annuler
                                             </button>
                                             <button className={"btn btn-sm btn-primary d-inline-flex align-items-center"} type={"submit"} disabled={helpers.isSubmitting}>
                                                  {!helpers.isSubmitting ? <>Appliquer le changement</> : <MySimpleSpinner size={"sm"} />}
                                             </button>
                                        </div>
                                   </>
                              </Form>
                         )
                    }}
               </Formik>
          </>
     )
}

interface IPropsModel {
     consultants: ConsultantModel[]
     onSuccessCallback?: (nouveauResp: IFournisseurResponsableModel) => void
     onCancelCallback?: () => void
}

interface ISetResponsableCollabRequestModel {
     consultantS?: number[]
     responsable?: number
}

export default ChangementDeResponsable
