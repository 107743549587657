import React from "react"
import "../styles.scss"
import { ConsultantCraModel, ConsultantNdfModel } from "../../../../../../../models"
import MyCard from "../../../../../../../utils/MyCard"
import { declarationActiveCra, declarationActiveNdf } from "../../../../core/_request"
import { useQuery } from "react-query"
import { AxiosError } from "axios"
import MyAlert from "../../../../../../../utils/MyAlert"
import { DdcListe } from "../../../ddc/DdcListe"
import { useDeclarationUpdate } from "../DeclarationUpdate"
import { CraUpdateTicketsRestaurant } from "../../../cra/craUpdate/CraUpdateTicketsRestaurant"
import { DdcListingFilters } from "../../../../core/_models"
import { MaladieListe } from "../../../maladie/MaladieListe"
import { NdfDetailsSalarie } from "../../../ndf/NdfDetails/salarie/NdfDetailsSalarie"
import Footer from "./_Footer"
import { CONST_CONSULTANT_CONTRAT_ST, CONST_CONSULTANT_STATUT_ST_PORTAGE, CONST_CONSULTANT_STATUT_ST_SOCIETE_SERVICE } from "../../../../../../../constants"
import CraUpdateGrilleTempsFreelance from "../../../cra/craUpdate/CraUpdateGrilleTempsFreelance"
import { Factures } from "../factures/DeclarationUpdateFactures"
import NdfDetailsSousTraitant from "../../../ndf/NdfDetails/sousTraitant/NdfDetailsSousTraitant"

export const DeclarationUpdateRecapitulatif = () => {
     const context = useDeclarationUpdate()

     const activeCraQuery = useQuery<ConsultantCraModel, AxiosError>("activeCra", () => declarationActiveCra(context.data.declaration?.id!).then(r => r.data))

     const ndfExistsQuery = useQuery<ConsultantNdfModel, AxiosError>("ndfExists", () => declarationActiveNdf(context.data.declaration?.id!).then(r => r.data))

     return (
          <div className={"mx-auto"} style={{ maxWidth: "1500px" }}>
               {context.data.declaration?.consultant?.contrat !== CONST_CONSULTANT_CONTRAT_ST && (
                    <>
                         <MyCard classNames={"border border-secondary border-opacity-50 mb-4"} defaultIsCollapsed={true}>
                              <MyCard.Header classNames={"bg-secondary"} isCollapseIconShown>
                                   <i className={"fas fa-file-medical fs-4 text-dark me-2"} /> Arrêts
                              </MyCard.Header>
                              <MyCard.Body>
                                   {context.editionModeEnabled && (
                                        <div className="text-center mb-8">
                                             <button
                                                  className={"btn btn-light-warning"}
                                                  onClick={() => {
                                                       context.setEnCoursDeRectificationEnabledDepuisRecapitulatif(true)
                                                       context.setDeclarationStepHeader("arrets")
                                                  }}
                                             >
                                                  Apporter une modification
                                             </button>
                                        </div>
                                   )}
                                   <MaladieListe
                                        filtres={{ duOrAuWithThisMonthAndYear: context.data.declaration!.mois?.startOf("month").clone() } as DdcListingFilters}
                                        editionMode={false}
                                   />
                              </MyCard.Body>
                         </MyCard>

                         <MyCard classNames={"border border-secondary border-opacity-50 mb-4"} defaultIsCollapsed={true}>
                              <MyCard.Header classNames={"bg-secondary"} isCollapseIconShown>
                                   <i className={"fas fa-umbrella-beach fs-4 text-dark me-2"} /> Demandes de congés
                              </MyCard.Header>
                              <MyCard.Body>
                                   {context.editionModeEnabled && (
                                        <div className="text-center mb-8">
                                             <button
                                                  className={"btn btn-light-warning"}
                                                  onClick={() => {
                                                       context.setEnCoursDeRectificationEnabledDepuisRecapitulatif(true)
                                                       context.setDeclarationStepHeader("ddc")
                                                  }}
                                             >
                                                  Apporter une modification
                                             </button>
                                        </div>
                                   )}
                                   <DdcListe
                                        filtres={{ duOrAuWithThisMonthAndYear: context.data.declaration!.mois?.startOf("month").clone() } as DdcListingFilters}
                                        editionMode={false}
                                   />
                              </MyCard.Body>
                         </MyCard>
                    </>
               )}

               {/* CRA */}
               {![CONST_CONSULTANT_STATUT_ST_SOCIETE_SERVICE, CONST_CONSULTANT_STATUT_ST_PORTAGE].includes(context.data.declaration?.consultant?.statut!) && (
                    <MyCard classNames={"border border-secondary border-opacity-50 mb-4"} defaultIsCollapsed={true}>
                         <MyCard.Header classNames={"bg-secondary"} isCollapseIconShown>
                              <i className={"fas fa-calendar-days fs-4 text-dark me-2"} /> CRA
                         </MyCard.Header>
                         <MyCard.Body>
                              {context.editionModeEnabled && (
                                   <div className="text-center mb-8">
                                        <button
                                             className={"btn btn-light-warning"}
                                             onClick={() => {
                                                  context.setEnCoursDeRectificationEnabledDepuisRecapitulatif(true)
                                                  context.setDeclarationStepHeader("cra")
                                             }}
                                        >
                                             Apporter une modification
                                        </button>
                                   </div>
                              )}

                              {activeCraQuery.isFetching && (
                                   <div className={"text-center p-4"}>
                                        <span className="spinner-border mb-2"></span>
                                        <p style={{ fontWeight: 500 }}>Chargement en cours ...</p>
                                   </div>
                              )}

                              {!activeCraQuery.isFetching && activeCraQuery.isError && <MyAlert type={"danger"}>{activeCraQuery.error.response?.data?.detail}</MyAlert>}

                              {!activeCraQuery.isFetching && !activeCraQuery.isError && activeCraQuery.data && (
                                   <CraUpdateGrilleTempsFreelance
                                        data={activeCraQuery.data}
                                        setData={cra => context.setData(prev => ({ ...prev, cra }))}
                                        editionModeEnabled={false}
                                   />
                              )}
                         </MyCard.Body>
                    </MyCard>
               )}

               {/* NDF */}
               <MyCard classNames={"border border-secondary border-opacity-50 mb-4"} defaultIsCollapsed={true}>
                    <MyCard.Header classNames={"bg-secondary"} isCollapseIconShown>
                         <i className={"fas fa-file-invoice-dollar fs-4 text-dark me-2"} />{" "}
                         {context.data.declaration?.consultant?.contrat === CONST_CONSULTANT_CONTRAT_ST ? "Factures de frais" : "Notes de frais"}
                    </MyCard.Header>
                    <MyCard.Body>
                         {context.editionModeEnabled && (
                              <div className="text-center">
                                   <button
                                        className={"btn btn-sm btn-light-warning mb-8"}
                                        onClick={() => {
                                             context.setEnCoursDeRectificationEnabledDepuisRecapitulatif(true)
                                             context.setDeclarationStepHeader("ndf")
                                        }}
                                   >
                                        Apporter une modification
                                   </button>
                              </div>
                         )}

                         {!ndfExistsQuery.isFetching && ndfExistsQuery.isError && (
                              <MyAlert type={"danger"} classNames={"mb-4"}>
                                   <>
                                        <span className={"me-2"}>{ndfExistsQuery.error?.response?.data?.detail}</span>
                                        <button className={"btn btn-sm btn-danger"} onClick={() => ndfExistsQuery.refetch()}>
                                             Recharger
                                        </button>
                                   </>
                              </MyAlert>
                         )}
                         {ndfExistsQuery.isFetching && (
                              <div className={"text-center"}>
                                   <span className="spinner-border mb-2"></span>
                                   <p style={{ fontWeight: 500 }}>Chargement en cours ...</p>
                              </div>
                         )}

                         {!ndfExistsQuery.isError && !ndfExistsQuery.isFetching && ndfExistsQuery.data && (
                              <>
                                   {ndfExistsQuery.data ? (
                                        <>
                                             {ndfExistsQuery.data.declaration.consultant?.contrat !== CONST_CONSULTANT_CONTRAT_ST ? (
                                                  <NdfDetailsSalarie ndfId={ndfExistsQuery.data.id} editionMode={false} />
                                             ) : (
                                                  <NdfDetailsSousTraitant ndfId={ndfExistsQuery.data.id} editionMode={false} />
                                             )}
                                        </>
                                   ) : (
                                        <MyAlert type={"primary"}>Aucune note de frais de saisie</MyAlert>
                                   )}
                              </>
                         )}
                    </MyCard.Body>
               </MyCard>

               {/* TR */}
               {context?.data.declaration?.consultant?.contrat !== CONST_CONSULTANT_CONTRAT_ST && (
                    <MyCard classNames={"border border-secondary border-opacity-50 mb-4"} defaultIsCollapsed={true}>
                         <MyCard.Header classNames={"bg-secondary"} isCollapseIconShown>
                              <i className={"fas fa-utensils fs-4 text-dark me-2"} /> Tickets Restaurant
                         </MyCard.Header>
                         <MyCard.Body>
                              {context.editionModeEnabled && (
                                   <div className="text-center mb-8">
                                        <button
                                             className={"btn btn-light-warning"}
                                             onClick={() => {
                                                  context.setEnCoursDeRectificationEnabledDepuisRecapitulatif(true)
                                                  context.setDeclarationStepHeader("ticket_resto")
                                             }}
                                        >
                                             Apporter une modification
                                        </button>
                                   </div>
                              )}

                              {activeCraQuery.isFetching && (
                                   <div className={"text-center p-4"}>
                                        <span className="spinner-border mb-2"></span>
                                        <p style={{ fontWeight: 500 }}>Chargement en cours ...</p>
                                   </div>
                              )}

                              {!activeCraQuery.isFetching && activeCraQuery.isError && <MyAlert type={"danger"}>{activeCraQuery.error.response?.data?.detail}</MyAlert>}

                              {!activeCraQuery.isFetching && !activeCraQuery.isError && activeCraQuery.data && (
                                   <CraUpdateTicketsRestaurant data={activeCraQuery.data} setData={cra => context.setData(prev => ({ ...prev, cra }))} editionModeEnabled={false} />
                              )}
                         </MyCard.Body>
                    </MyCard>
               )}

               {context?.data.declaration?.consultant?.contrat === CONST_CONSULTANT_CONTRAT_ST && (
                    <MyCard classNames={"border border-secondary border-opacity-50 mb-4"} defaultIsCollapsed={true}>
                         <MyCard.Header classNames={"bg-secondary"} isCollapseIconShown>
                              <i className={"fas fa-file-invoice-dollar fs-4 text-dark me-2"} /> Factures de prestation
                         </MyCard.Header>
                         <MyCard.Body>
                              {context.editionModeEnabled && (
                                   <div className="text-center">
                                        <button
                                             className={"btn btn-sm btn-light-warning mb-8"}
                                             onClick={() => {
                                                  context.setEnCoursDeRectificationEnabledDepuisRecapitulatif(true)
                                                  context.setDeclarationStepHeader("factures")
                                             }}
                                        >
                                             Apporter une modification
                                        </button>
                                   </div>
                              )}

                              <Factures isEditionModeEnabled={false} />
                         </MyCard.Body>
                    </MyCard>
               )}

               {/* FOOTER */}
               <div className="separator separator-dashed my-6" />

               <Footer />
          </div>
     )
}
