import { useContext, useState } from "react"
import { DeclarationsListContext } from "./DeclarationsList"
import { DeclarationCreate } from "../../../../app-consultant/modules/activites/components/declaration/declarationCreate/DeclarationCreate"
import { DeclarationUpdate } from "../../../../app-consultant/modules/activites/components/declaration/declarationUpdate/DeclarationUpdate"
import { useQueryClient } from "react-query"
import { IListingResponseModel } from "../core/models"

const Editer = () => {
     const queryClient = useQueryClient()
     const { refreshRows, selectedItemForDeclaration, setSelectedItemForDeclaration, handleChangeNbJoursTravaillesCallback } = useContext(DeclarationsListContext)
     const [createdDeclarationId, setCreatedDeclarationId] = useState<number | null>(null)
     const [step, setStep] = useState<"CREATE" | "DETAILS">(selectedItemForDeclaration?.item.declaration ? "DETAILS" : "CREATE")

     function handleCreateDeclaration(declaration_id: number) {
          queryClient.setQueryData<IListingResponseModel | undefined>("QUERY_LISTING", (prev: IListingResponseModel | undefined) => {
               if (prev?.data && prev.data.length > 0) {
                    const index = prev.data.findIndex(item => item.consultant?.id === selectedItemForDeclaration?.item.consultant.id)
                    if (index >= 0) {
                         prev.data[index] = {
                              ...prev.data[index],
                              declaration: {
                                   id: declaration_id,
                              },
                         }
                         return prev
                    }
               }

               return prev
          })

          setCreatedDeclarationId(declaration_id)

          setStep("DETAILS")
     }

     return (
          <>
               {step === "CREATE" && (
                    <DeclarationCreate
                         consultant_ID={selectedItemForDeclaration?.item.consultant.id!}
                         onSubmitSuccessForCreationCallback={handleCreateDeclaration}
                         onSubmitDeclarationExistsCallback={() => setStep("DETAILS")}
                         onClickPreviousCallback={() => setSelectedItemForDeclaration(null)}
                    />
               )}

               {step === "DETAILS" && (
                    <DeclarationUpdate
                         defaultStep={selectedItemForDeclaration?.defaultStep as any}
                         declarationId={createdDeclarationId || selectedItemForDeclaration?.item.declaration?.id}
                         onClickOnRetourAuxDeclarationCallback={() => {
                              setSelectedItemForDeclaration(null)
                              if (selectedItemForDeclaration?.item) refreshRows([selectedItemForDeclaration.item.consultant.id!]).then()
                         }}
                         onChangeNbJoursTravaillesCallback={handleChangeNbJoursTravaillesCallback}
                         onCRUDFacturesPrestationCallback={() => refreshRows([selectedItemForDeclaration?.item.consultant.id!])}
                         onCRUDFacturesFraisCallback={() => refreshRows([selectedItemForDeclaration?.item.consultant.id!])}
                    />
               )}
          </>
     )
}

export default Editer
