import moment from "moment/moment"
import { Link } from "react-router-dom"
import React from "react"
import MyTooltip from "../../../utils/MyTooltip"
import { IGetConsultantsDeclarationsVoyantsItemResponseModel } from "./DeclarationsVoyants"

const Voyants = ({ item, mois }: { item: IGetConsultantsDeclarationsVoyantsItemResponseModel; mois: moment.Moment }) => {
     const nonEnvoyes = item.totalUncreated + item.totalEnCoursDeCreation + item.totalEnCoursDeModification + item.totalEnCoursSignature
     const envoyes = item.totalSigneParCollab + item.totalSigneParPersonnelApresDemandeRegul + item.totalSigneParResponsable + item.totalDemandeRectifParSiege
     const toRectifier = item.totalEnCoursDeRectification
     const valideesPourFactu = item.totalValidePourPaie + item.totalEnAttenteDeValidationDeLaRegularisation

     return (
          <>
               <div className="d-flex justify-content-center mb-2  gap-3">
                    <MyTooltip title={nonEnvoyes === 1 ? `Une déclaration non envoyée` : `${nonEnvoyes} déclarations non envoyées`}>
                         <Link
                              to={"/espace-resp-fournisseur/declarations/listing"}
                              state={{ mois: mois.format("YYYY-MM-DD"), etat: "ETAT_RESP_FOURNISSEUR__NON_ENVOYES" }}
                              className={`fs-5 btn btn-sm ${nonEnvoyes > 0 ? "btn-danger" : "btn-outline btn-outline-danger"} btn-icon ${
                                   nonEnvoyes === 0 ? "cursor-not-allowed text-muted" : ""
                              }`}
                              onClick={e => nonEnvoyes === 0 && e.preventDefault()}
                         >
                              {nonEnvoyes}
                         </Link>
                    </MyTooltip>
                    <MyTooltip title={envoyes === 1 ? `Une déclaration envoyée` : `${envoyes} déclarations envoyées`}>
                         <Link
                              to={"/espace-resp-fournisseur/declarations/listing"}
                              state={{ mois: mois.format("YYYY-MM-DD"), etat: "ETAT_RESP_FOURNISSEUR__ENVOYES" }}
                              className={`fs-5 btn btn-sm ${envoyes > 0 ? "btn-success" : "btn-outline btn-outline-success"} btn-icon ${
                                   envoyes === 0 ? "cursor-not-allowed text-muted" : ""
                              }`}
                              onClick={e => envoyes === 0 && e.preventDefault()}
                         >
                              {envoyes}
                         </Link>
                    </MyTooltip>
               </div>
               <div className="d-flex justify-content-center mb-2 gap-3">
                    <MyTooltip title={toRectifier === 1 ? `Une déclaration à rectifier` : `${toRectifier} déclarations en cours de rectification`}>
                         <Link
                              to={"/espace-resp-fournisseur/declarations/listing"}
                              state={{ mois: mois.format("YYYY-MM-DD"), etat: "ETAT_RESP_FOURNISSEUR__A_RECTIFIER" }}
                              className={`fs-5 btn btn-sm ${toRectifier > 0 ? "btn-warning" : "btn-outline btn-outline-warning"} btn-icon ${
                                   toRectifier === 0 ? "cursor-not-allowed text-muted" : ""
                              }`}
                              onClick={e => toRectifier === 0 && e.preventDefault()}
                         >
                              {toRectifier}
                         </Link>
                    </MyTooltip>
                    <MyTooltip title={valideesPourFactu === 1 ? `Une déclaration a été validée` : `${valideesPourFactu} déclarations ont été validées`}>
                         <Link
                              to={"/espace-resp-fournisseur/declarations/listing"}
                              state={{ mois: mois.format("YYYY-MM-DD"), etat: "ETAT_RESP_FOURNISSEUR__FACTURE" }}
                              className={`fs-5 btn btn-sm ${valideesPourFactu > 0 ? "btn-success" : "btn-outline btn-outline-success"} btn-icon ${
                                   valideesPourFactu === 0 ? "cursor-not-allowed text-muted" : ""
                              }`}
                              onClick={e => valideesPourFactu === 0 && e.preventDefault()}
                         >
                              {valideesPourFactu}
                         </Link>
                    </MyTooltip>
               </div>
          </>
     )
}

export default Voyants
