import React, { createContext, useState } from "react"
import MyCard from "../../../../utils/MyCard"
import CompteInfoPersoPreview from "./preview/CompteInfoPersoPreview"
import { CompteInfoPersoEdit } from "./edit/CompteInfoPersoEdit"

const CompteInfoPersoContext = createContext<ICompteInfoPersoContextPropsModel>({} as ICompteInfoPersoContextPropsModel)

function CompteInfoPerso() {
     const [mode, setMode] = useState<"PREVIEW" | "EDITION">("PREVIEW")

     return (
          <CompteInfoPersoContext.Provider value={{ toggleToPreviewMode: () => setMode("PREVIEW") }}>
               <MyCard>
                    <MyCard.Header
                         rightSideContent={
                              <div onClick={() => setMode(prev => (prev === "PREVIEW" ? "EDITION" : "PREVIEW"))} className={"cursor-pointer"}>
                                   {mode === "EDITION" ? <span className={"fas fa-eye fs-1 text-hover-primary"} /> : <span className={"fas fa-edit fs-1 text-hover-warning"} />}
                              </div>
                         }
                    >
                         Mes informations personnelles
                    </MyCard.Header>
                    <MyCard.Body>
                         <>{mode === "PREVIEW" ? <CompteInfoPersoPreview /> : <CompteInfoPersoEdit />}</>
                    </MyCard.Body>
               </MyCard>
          </CompteInfoPersoContext.Provider>
     )
}

interface ICompteInfoPersoContextPropsModel {
     toggleToPreviewMode: () => void
}

export { CompteInfoPerso, CompteInfoPersoContext }
