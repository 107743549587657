import React from "react"
import { ConsultantNdfDetailModel, IHttpErrorResponseModel } from "../../../../../../../models"
import MyAlert from "../../../../../../../utils/MyAlert"
import * as Yup from "yup"
import { ErrorMessage, FieldArray, Form, Formik } from "formik"
import { useQueryClient } from "react-query"
import { ndfAddDepenseGrouped } from "../../../../core/_request"
import MySelectField from "../../../../../../../utils/fields/MySelectField"
import {
     CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR,
     CONST_NDF_DETAILS_CATEGORIE_REPAS_MIDI,
     CONST_NDF_DETAILS_CATEGORIES_OPTIONS_FOR_SELECT2,
} from "../../../../../../../constants"
import { INdfAddNdfDetailGroupedRequestModel } from "../../../../core/_models"
import { toast } from "react-toastify"
import MyDateDayMonthYearField from "../../../../../../../utils/fields/MyDateDayMonthYearField"
import moment from "moment"
import { useNdfDetailsSalarieContext } from "../../NdfDetails/salarie/NdfDetailsSalarie"
import MyInputField from "../../../../../../../utils/fields/MyInputField"
import MySelectCreatableField from "../../../../../../../utils/fields/MySelectCreatableField"
import MyTextareaField from "../../../../../../../utils/fields/MyTextareaField"

export const NdfAddDepenseGrouped = () => {
     const queryClient = useQueryClient()
     const context = useNdfDetailsSalarieContext()

     // Form add depense
     const schemaAddDepense = Yup.object().shape({
          fichiers: Yup.array().of(
               Yup.mixed()
                    .required("PJ requise")
                    .test("fileSize", "La taille de la PJ doit être inférieure ou égale à 10MB", (value: any) => {
                         return value && value.size <= 10000000
                    })
                    .test("fileFormat", "La PJ doit être au format JPEG, PNG ou PDF", (value: any) => {
                         return value && ["application/pdf", "image/jpeg", "image/png"].includes(value.type)
                    })
          ),
          childs: Yup.array().of(
               Yup.object().shape({
                    date: Yup.string().required("Champ requis"),
                    numeroTicket: Yup.number().required("Champ requis").typeError("Format incorrect."),
                    categorie: Yup.string().required("Champ requis"),
                    description: Yup.string().required("Champ requis"),
                    montantTTC: Yup.number().required("Champ requis").typeError("Format incorrect."),
                    repasSeulOuEnGroupe: Yup.string()
                         .label(`"Seul ou en groupe"`)
                         .when("categorie", {
                              is: CONST_NDF_DETAILS_CATEGORIE_REPAS_MIDI,
                              then: schema => schema.required(),
                         }),
                    invites: Yup.array()
                         .label(`"Invités"`)
                         .when("repasSeulOuEnGroupe", {
                              is: "REPAS_MIDI_EN_GROUPE",
                              then: schema => schema.required(),
                         }),
               })
          ),
     })

     return (
          <Formik
               initialValues={{
                    fichiers: [new File([new Blob()], "")] as File[],
                    childs: [{ description: undefined, date: undefined, numeroTicket: "", categorie: "", montantTTC: "", repasSeulOuEnGroupe: undefined, invites: undefined }],
               }}
               onSubmit={(values, { setStatus, setSubmitting, setFieldError }) => {
                    setStatus(null)
                    const childs: INdfAddNdfDetailGroupedRequestModel[] = []
                    values.childs.map(item =>
                         childs.push({
                              numeroTicket: parseInt(item.numeroTicket),
                              categorie: item.categorie,
                              montantTTC: parseFloat(item.montantTTC),
                              description: item.description,
                              date: moment(item.date, "DD/MM/YYYY"),
                              repasSeulOuEnGroupe: item.repasSeulOuEnGroupe || undefined,
                              invites: item.invites || undefined,
                         })
                    )
                    ndfAddDepenseGrouped(context.ndfId, childs, values.fichiers as File[])
                         .then(r => {
                              queryClient.setQueryData(["ndfDetails", context.ndfId], (details: ConsultantNdfDetailModel[] | undefined) => {
                                   if (details) {
                                        const dataWithNewDepense = [r.data, ...details]
                                        context.computeTotalExpenses(dataWithNewDepense || [])
                                        return dataWithNewDepense
                                   }

                                   return []
                              })

                              toast.success("L'ajout a bien été pris en compte.")
                              setSubmitting(false)
                              context.setIsAddDetailsModalShown(false)
                         })
                         .catch(e => {
                              const error: IHttpErrorResponseModel = e.response?.data

                              // Set form errors
                              if (error?.code === CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR && error?.errors) {
                                   for (const key in error.errors) setFieldError(key, error.errors[key])
                              }

                              // Set form global status and notify user using a toast
                              if (error?.code === "REGULARATION_IS_REQUIRED") {
                                   setStatus(
                                        <>
                                             <p>{error?.detail}</p>
                                             <b className={"mb-2 fs-5"}>Merci de suivre les étapes suivantes:</b>
                                             <ul>
                                                  <li>Accédez à la liste des déclarations.</li>
                                                  <li>Demandez la régularisation de la déclaration du mois concerné.</li>
                                                  <li>Attendez la validation de votre responsable (utilisez ce temps pour finaliser la déclaration du mois en cours).</li>
                                                  <li>Une fois la demande validée, ajoutez votre dépense et vérifiez qu'il n'y a plus de ticket restaurant.</li>
                                                  <li>Finaliser et signer votre déclaration.</li>
                                             </ul>
                                        </>
                                   )
                              } else {
                                   setStatus(error?.detail)
                              }
                              toast.error(error?.detail, { autoClose: false })

                              // Stop submit loader
                              setSubmitting(false)
                         })
               }}
               validationSchema={schemaAddDepense}
          >
               {({ values, setFieldValue, isSubmitting, status, errors }) => (
                    <Form noValidate autoComplete="off">
                         {status && (
                              <MyAlert type={"danger"} classNames={"mb-4"}>
                                   {status}
                              </MyAlert>
                         )}
                         <div className={"mb-4"}>
                              <label className="form-label fw-bolder fs-6">Justificatifs</label>
                              <FieldArray name={"fichiers"}>
                                   {({ push, remove }) => (
                                        <div className={"row"}>
                                             {values.fichiers.map((item, index) => {
                                                  return (
                                                       <div className={"col-md-6 col-lg-4 mb-4"} key={index}>
                                                            <div className="d-flex">
                                                                 <input
                                                                      type={"file"}
                                                                      name={`fichiers[${index}]`}
                                                                      onChange={e =>
                                                                           setFieldValue(
                                                                                `fichiers[${index}]`,
                                                                                e.target.files && e.target.files.length > 0 ? e.target.files[0] : null
                                                                           )
                                                                      }
                                                                      className={"form-control me-2"}
                                                                 />
                                                                 {index > 0 && (
                                                                      <button className={"btn btn-light-danger btn-sm"} type={"button"} onClick={() => remove(index)}>
                                                                           <span className={"fas fa-minus-circle"}></span>
                                                                      </button>
                                                                 )}
                                                            </div>
                                                            <ErrorMessage name={`fichiers[${index}]`}>{msg => <div className={"text-danger"}>{msg}</div>}</ErrorMessage>
                                                            <div className={"d-flex justify-content-sm-start justify-content-center mt-4"}>
                                                                 {index == values.fichiers.length - 1 && (
                                                                      <button
                                                                           className={`btn btn-light-primary btn-sm ${index > 0 && "me-2"}`}
                                                                           type={"button"}
                                                                           onClick={() => push({})}
                                                                      >
                                                                           Ajouter une autre justificatif <i className={"fas fa-plus-circle ps-1 pe-0"} />
                                                                      </button>
                                                                 )}
                                                            </div>
                                                       </div>
                                                  )
                                             })}
                                        </div>
                                   )}
                              </FieldArray>
                         </div>

                         <label className="form-label fw-bolder fs-6">Détails des dépenses</label>
                         <FieldArray name={"childs"}>
                              {({ push, remove }) => (
                                   <>
                                        <div className={"row"}>
                                             {values.childs
                                                  .slice(0)
                                                  .reverse()
                                                  .map((item, index) => {
                                                       return (
                                                            <div className={"col-sm-4 mb-4"} key={index}>
                                                                 <div className={"mb-2"}>
                                                                      <MyDateDayMonthYearField
                                                                           onChange={date => setFieldValue(`childs[${index}].date`, date)}
                                                                           value={values.childs[index].date}
                                                                           name={`childs[${index}].date`}
                                                                           placeholder={"Date"}
                                                                      />
                                                                      <ErrorMessage name={`childs[${index}].date`}>
                                                                           {msg => <div className={"text-danger"}>{msg}</div>}
                                                                      </ErrorMessage>
                                                                 </div>
                                                                 <div className={"mb-2"}>
                                                                      <input
                                                                           className={"form-control"}
                                                                           name={`childs[${index}].numeroTicket`}
                                                                           placeholder={"Numéro que vous avez inscrit"}
                                                                           autoComplete={"off"}
                                                                           inputMode={"numeric"}
                                                                           onChange={e => setFieldValue(`childs[${index}].numeroTicket`, e.target.value)}
                                                                           value={values.childs[index].numeroTicket}
                                                                      />
                                                                      <ErrorMessage name={`childs[${index}].numeroTicket`}>
                                                                           {msg => <div className={"text-danger"}>{msg}</div>}
                                                                      </ErrorMessage>
                                                                 </div>
                                                                 <div className={"mb-2"}>
                                                                      <MySelectField
                                                                           options={CONST_NDF_DETAILS_CATEGORIES_OPTIONS_FOR_SELECT2}
                                                                           name={`childs[${index}].categorie`}
                                                                           isSearchable={false}
                                                                           isClearable={false}
                                                                           placeholder={"Catégorie"}
                                                                           value={values.childs[index].categorie}
                                                                           onChange={value => setFieldValue(`childs[${index}].categorie`, value)}
                                                                      />
                                                                      <ErrorMessage name={`childs[${index}].categorie`}>
                                                                           {msg => <div className={"text-danger"}>{msg}</div>}
                                                                      </ErrorMessage>
                                                                 </div>
                                                                 <div className={"mb-2"}>
                                                                      <MyInputField
                                                                           name={`childs[${index}].montantTTC`}
                                                                           value={values.childs[index].montantTTC}
                                                                           type={"number"}
                                                                           inputMode={"decimal"}
                                                                           placeholder={"Montant TTC"}
                                                                           isInvalid={false}
                                                                           onChange={val => setFieldValue(`childs[${index}].montantTTC`, val)}
                                                                      />
                                                                      <ErrorMessage name={`childs[${index}].montantTTC`}>
                                                                           {msg => <div className={"text-danger"}>{msg}</div>}
                                                                      </ErrorMessage>
                                                                 </div>
                                                                 {values.childs[index].categorie === CONST_NDF_DETAILS_CATEGORIE_REPAS_MIDI && (
                                                                      <>
                                                                           <div className={"col-12 mt-2"}>
                                                                                <MyAlert type={"primary"} classNames={"mb-2"}>
                                                                                     Veuillez indiquer s'il s'agit d'un repas seul ou en groupe
                                                                                </MyAlert>
                                                                                <MySelectField
                                                                                     name={`childs[${index}].repasSeulOuEnGroupe`}
                                                                                     options={[
                                                                                          {
                                                                                               value: "REPAS_MIDI_SEUL",
                                                                                               label: "Repas seul",
                                                                                          },
                                                                                          {
                                                                                               value: "REPAS_MIDI_EN_GROUPE",
                                                                                               label: "Repas en groupe",
                                                                                          },
                                                                                     ]}
                                                                                     value={values.childs[index].repasSeulOuEnGroupe}
                                                                                     onChange={val => setFieldValue(`childs[${index}].repasSeulOuEnGroupe`, val)}
                                                                                     placeholder={"Choisissez si seul ou en groupe"}
                                                                                />
                                                                                <ErrorMessage name={`childs[${index}].repasSeulOuEnGroupe`}>
                                                                                     {msg => <div className={"text-danger"}>{msg}</div>}
                                                                                </ErrorMessage>
                                                                           </div>

                                                                           {values.childs[index].repasSeulOuEnGroupe === "REPAS_MIDI_EN_GROUPE" && (
                                                                                <>
                                                                                     <div className={"col-12 mt-2"}>
                                                                                          <MyAlert type={"primary"} classNames={"mb-2"}>
                                                                                               Veuillez fournir les noms et prénoms de vos invités. S'il s'agit d'un client, ajoutez
                                                                                               le nom de la société en plus.
                                                                                          </MyAlert>
                                                                                          <MySelectCreatableField
                                                                                               name={`childs[${index}].invites`}
                                                                                               options={[]}
                                                                                               value={values.childs[index].invites}
                                                                                               onChange={val => setFieldValue(`childs[${index}].invites`, val)}
                                                                                               isInvalid={false}
                                                                                               placeholder={"Saisissez les invités"}
                                                                                          />
                                                                                          <ErrorMessage name={`childs[${index}].invites`}>
                                                                                               {msg => <div className={"text-danger"}>{msg}</div>}
                                                                                          </ErrorMessage>
                                                                                     </div>
                                                                                </>
                                                                           )}
                                                                      </>
                                                                 )}
                                                                 <div className={"col-12 mt-5 mb-2"}>
                                                                      <MyTextareaField
                                                                           name={`childs[${index}].description`}
                                                                           value={values.childs[index].description}
                                                                           isInvalid={false}
                                                                           rows={2}
                                                                           onChange={val => setFieldValue(`childs[${index}].description`, val)}
                                                                           placeholder={"Description"}
                                                                      />
                                                                      <ErrorMessage name={`childs[${index}].description`}>
                                                                           {msg => <div className={"text-danger"}>{msg}</div>}
                                                                      </ErrorMessage>
                                                                 </div>
                                                                 <div className={"d-flex justify-content-sm-start justify-content-between"}>
                                                                      {index > 0 && (
                                                                           <button
                                                                                className={`btn btn-light-danger btn-sm ${index > 0 && "me-2"}`}
                                                                                type={"button"}
                                                                                onClick={() => remove(index)}
                                                                           >
                                                                                <i className={"fas fa-minus-circle"} />
                                                                                Supprimer
                                                                           </button>
                                                                      )}

                                                                      {index == values.childs.length - 1 && (
                                                                           <button
                                                                                className={`btn btn-light-primary btn-sm`}
                                                                                type={"button"}
                                                                                onClick={() =>
                                                                                     push({
                                                                                          date: undefined,
                                                                                          description: undefined,
                                                                                          numeroTicket: "",
                                                                                          categorie: "",
                                                                                          montantTTC: "",
                                                                                     })
                                                                                }
                                                                           >
                                                                                <i className={"fas fa-plus-circle"} />
                                                                                Ajouter un autre détail
                                                                           </button>
                                                                      )}
                                                                 </div>
                                                            </div>
                                                       )
                                                  })}
                                        </div>
                                   </>
                              )}
                         </FieldArray>

                         <div className="separator separator-dashed bg-primary my-4"></div>
                         <div className="text-center pt-6 align-items-center">
                              <button className={"btn btn-sm btn-primary"} type={"submit"} disabled={isSubmitting}>
                                   {!isSubmitting && (
                                        <>
                                             <i className={"fas fa-check-circle"} />
                                             Finaliser l'ajout
                                        </>
                                   )}
                                   {isSubmitting && (
                                        <span className="indicator-progress" style={{ display: "block" }}>
                                             Ajout en cours
                                             <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                        </span>
                                   )}
                              </button>
                         </div>
                    </Form>
               )}
          </Formik>
     )
}
