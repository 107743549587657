import { Link, useLocation, useNavigate } from "react-router-dom"
import React, { useEffect, useState } from "react"
import { ndfGetInfo } from "../../../core/_request"
import { ConsultantNdfModel } from "../../../../../../models"
import { toast } from "react-toastify"
import MyAlert from "../../../../../../utils/MyAlert"
import { NdfStepperHeader } from "../NdfCreate/NdfCreate"
import { NdfDetailsSalarie } from "./salarie/NdfDetailsSalarie"
import { BrowserView, MobileView } from "react-device-detect"
import { KTSVG } from "../../../../../../../_metronic/helpers"
import { CONST_CONSULTANT_CONTRAT_ST } from "../../../../../../constants"
import NdfDetailsSousTraitant from "./sousTraitant/NdfDetailsSousTraitant"

const urlParams = new URLSearchParams(window.location.search)

export function NdfDetailsWrapper() {
     // Hooks
     const navigate = useNavigate()
     const location_state = useLocation().state as { ndf_id: string; default_step: "one" | "two" }

     // Vars
     const ndf_id = location_state?.ndf_id || (urlParams.get("ndf_id") as string)
     const default_step = location_state?.default_step || (urlParams.get("default_step") as string)

     // States
     const [ndfData, setNdfData] = useState<{ isEditable: boolean; loading: boolean; error: false | string; data: ConsultantNdfModel | null }>({
          isEditable: true,
          loading: true,
          error: false,
          data: null,
     })
     const [stepperStep, setStepperStep] = useState<"one" | "two">(default_step || "two")

     // UseEffects
     useEffect(() => {
          if (!ndf_id) {
               navigate(`/activites/ndf/new`)
          } else {
               ndfGetInfo(parseInt(ndf_id))
                    .then(r => {
                         setNdfData(prev => ({ ...prev, loading: false, isEditable: r.data.declaration.modifiable!, data: r.data }))
                    })
                    .catch(e => {
                         toast.error(e.response?.data?.detail, { autoClose: false })
                         setNdfData(prev => ({ ...prev, loading: false, error: e.response?.data?.detail }))
                    })
          }
     }, [])

     return (
          <div className="card mb-5 mb-xl-10">
               <div className="card-body p-9">
                    {ndfData.loading && (
                         <div className={"text-center p-4"}>
                              <span className="spinner-border mb-2"></span>
                              <p style={{ fontWeight: 500 }}>Chargement en cours ...</p>
                         </div>
                    )}

                    {!ndfData.loading && ndfData.error && <MyAlert type={"danger"}>{ndfData.error}</MyAlert>}

                    {!ndfData.loading && ndfData.data && (
                         <>
                              <NdfStepperHeader stepperStep={stepperStep} setStepperStep={setStepperStep} />

                              {stepperStep == "two" && (
                                   <div className={"mx-auto"} style={{ maxWidth: "1200px" }}>
                                        {ndfData.data.declaration.consultant?.contrat !== CONST_CONSULTANT_CONTRAT_ST ? (
                                             <NdfDetailsSalarie ndfId={ndfData.data.id} editionMode={ndfData.isEditable} />
                                        ) : (
                                             <NdfDetailsSousTraitant ndfId={ndfData.data.id} editionMode={ndfData.isEditable} />
                                        )}

                                        <div className="separator separator-dashed mb-4 mt-4 bg-primary"></div>

                                        {/* FOOTER */}
                                        <BrowserView>
                                             <div className="d-flex flex-stack">
                                                  <div>
                                                       <Link to="/activites/ndf " className={`btn btn-sm btn-light-primary me-2`}>
                                                            <span className={"fas fa-list-dots fs-1 me-3 align-middle"} /> Retour à la liste des NDF
                                                       </Link>
                                                  </div>

                                                  {ndfData.isEditable && (
                                                       <button
                                                            type="button"
                                                            className="btn btn-sm btn-primary"
                                                            onClick={() => {
                                                                 toast.info("Note de frais finalisée. Vous pouvez reprendre son édition si besoin.")
                                                                 navigate("/activites/ndf")
                                                            }}
                                                       >
                                                            <span className={"me-2"}>Finaliser</span>
                                                            <span className="fas fa-check-circle"></span>
                                                       </button>
                                                  )}
                                             </div>
                                        </BrowserView>

                                        <MobileView>
                                             <div className="d-flex flex-column">
                                                  <div className={"mb-sm-0 mb-3"}>
                                                       <button type="button" className="btn btn-sm btn-light-primary me-3 float-sm-none" onClick={() => setStepperStep("two")}>
                                                            <span className="svg-icon me-1">
                                                                 <KTSVG path="/media/icons/duotune/arrows/arr063.svg" className="svg-icon-2" />
                                                            </span>
                                                            Dépenses
                                                       </button>

                                                       {ndfData.isEditable && (
                                                            <button
                                                                 type="button"
                                                                 className="btn btn-sm btn-primary float-end"
                                                                 onClick={() => {
                                                                      toast.info("Note de frais finalisée. Vous pouvez reprendre son édition si besoin.")
                                                                      navigate("/activites/ndf")
                                                                 }}
                                                            >
                                                                 <span className={"me-2"}>Finaliser</span>
                                                                 <span className="fas fa-check-circle"></span>
                                                            </button>
                                                       )}
                                                  </div>

                                                  <Link to="/activites/ndf " className={`btn btn-sm btn-light-primary`}>
                                                       <span className={"fas fa-list-dots fs-1 me-3 align-middle"} /> Retour à la liste des NDF
                                                  </Link>
                                             </div>
                                        </MobileView>
                                   </div>
                              )}
                         </>
                    )}
               </div>
          </div>
     )
}
