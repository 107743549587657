import React from "react"
import { useAuth } from "../../../../auth"
import DocumentsProFournisseur from "./DocumentsProFournisseur"
import DocumentsProSalarie from "./DocumentsProSalarie"

function DocumentsPro() {
     const { currentUser, setCurrentUser } = useAuth()

     return <>{currentUser?.fournisseur?.fournisseur ? <DocumentsProFournisseur /> : <DocumentsProSalarie />}</>
}

export default DocumentsPro
