import { FC, Suspense } from "react"
import { Route, Routes } from "react-router-dom"
import TopBarProgress from "react-topbar-progress-indicator"
import { getCSSVariableValue } from "../../_metronic/assets/ts/_utils"
import { WithChildren } from "../../_metronic/helpers"
import ComptePage from "../modules/compte/ComptePage"
import OffresPage from "../modules/offres/OffresPage"
import { DocosWrapper } from "../pages/docos/DocosWrapper"
import { CandidaturesWrapper } from "../app-candidat/pages/candidatures/CandidaturesWrapper"
import { Logout, useAuth } from "../modules/auth"
import { CONST_USER_ROLE_CANDIDAT, CONST_USER_ROLE_CANDIDAT_EN_COURS_EMBAUCHE } from "../constants"

const PrivateRoutes = () => {
     const { currentUser } = useAuth()
     return (
          <Routes>
               <Route path="mes-docos" element={<DocosWrapper />} />

               <Route
                    path="mon-compte/*"
                    element={
                         <SuspensedView>
                              <ComptePage />
                         </SuspensedView>
                    }
               />

               {/*Candidatures & offres uniquement pour l'espace candidat & pré-embauche. L'espace consultant n'en a pas besoin*/}
               {(currentUser?.user?.roles?.includes(CONST_USER_ROLE_CANDIDAT) || currentUser?.user?.roles?.includes(CONST_USER_ROLE_CANDIDAT_EN_COURS_EMBAUCHE)) && (
                    <>
                         <Route path="mes-candidatures" element={<CandidaturesWrapper />} />
                         <Route
                              path="offres/*"
                              element={
                                   <SuspensedView>
                                        <OffresPage />
                                   </SuspensedView>
                              }
                         />
                    </>
               )}

               {/*Logout*/}
               <Route path="logout" element={<Logout />} />

               {currentUser?.user?.roles?.includes("ROLE_CANDIDAT") && <Route path="*" element={<CandidaturesWrapper />} />}
          </Routes>
     )
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
     const baseColor = getCSSVariableValue("--kt-primary")
     TopBarProgress.config({
          barColors: {
               "0": baseColor,
          },
          barThickness: 1,
          shadowBlur: 5,
     })
     return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PrivateRoutes }
