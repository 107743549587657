import { useAuth } from "../../../../auth"
import { CONST_PAYS_CODE_FRANCE } from "../../../../../constants"
import React from "react"

export function InfoPersoViewOnlyEspacePreemabaucheSalarie() {
     const { currentUser } = useAuth()

     return (
          <>
               <div className="pb-6">
                    {/*Civilité*/}
                    <div className="row mb-7">
                         <label className="col-lg-4 fw-bold text-muted">Civilité</label>

                         <div className="col-lg-8">
                              <span className="fw-bolder fs-6 text-dark">
                                   {currentUser?.consultant?.consultant?.civilite ? (
                                        currentUser?.consultant?.consultant?.civiliteLabel
                                   ) : (
                                        <span className={"text-warning d-flex align-items-center"}>
                                             À renseigner <i className={"fas fa-exclamation-circle text-warning ms-1"} />
                                        </span>
                                   )}
                              </span>
                         </div>
                    </div>

                    {/*Nom & prénom*/}
                    <div className="row mb-7">
                         <label className="col-lg-4 fw-bold text-muted">Prénom NOM</label>

                         <div className="col-lg-8">
                              <span className="fw-bolder fs-6 text-dark">
                                   {currentUser?.consultant?.consultant?.prenom} {currentUser?.consultant?.consultant?.nom}
                              </span>
                         </div>
                    </div>

                    {/*Email*/}
                    <div className="row mb-7">
                         <label className="col-lg-4 fw-bold text-muted">Email</label>

                         <div className="col-lg-8">
                              <span className="fw-bolder fs-6 text-dark me-2">{currentUser?.consultant?.consultant?.mailPro}</span>
                              <i className={"fas fa-check-circle text-success"} />
                         </div>
                    </div>

                    {/*Tél*/}
                    <div className="row mb-7">
                         <label className="col-lg-4 fw-bold text-muted">Téléphone</label>

                         <div className="col-lg-8">
                              <span className="fw-bolder fs-6 text-dark">
                                   {currentUser?.consultant?.consultant?.telProPays?.phoneCodeWithPlusPrefix} {currentUser?.consultant?.consultant?.telPro}
                              </span>
                         </div>
                    </div>

                    {/*N° SS*/}
                    <div className="row">
                         <label className="col-lg-4 fw-bold text-muted">N° de Sécurité Sociale</label>

                         <div className="col-lg-8">
                              <span className="fw-bolder fs-6 text-dark">
                                   {currentUser?.consultant?.consultant?.numeroSecuriteSociale ? (
                                        currentUser?.consultant?.consultant?.numeroSecuriteSociale
                                   ) : (
                                        <span className={"text-warning d-flex align-items-center"}>
                                             À renseigner <i className={"fas fa-exclamation-circle text-warning ms-1"} />
                                        </span>
                                   )}
                              </span>
                         </div>
                    </div>
               </div>

               <div className="pb-6">
                    <h3 className={"mt-8 mb-4"}>Naissance</h3>
                    <div className="separator separator-dashed mb-6" />

                    <div className="row mb-7">
                         <label className="col-lg-4 fw-bold text-muted">Date de naissance</label>

                         <div className="col-lg-8">
                              <span className="fw-bolder fs-6 text-dark">
                                   {currentUser?.consultant?.consultant?.naissanceDate ? (
                                        currentUser?.consultant?.consultant?.naissanceDate?.format("DD/MM/YYYY")
                                   ) : (
                                        <span className={"text-warning d-flex align-items-center"}>
                                             À renseigner <i className={"fas fa-exclamation-circle text-warning ms-1"} />
                                        </span>
                                   )}
                              </span>
                         </div>
                    </div>

                    <div className="row mb-7">
                         <label className="col-lg-4 fw-bold text-muted">Pays et ville de naissance</label>

                         <div className="col-lg-8">
                              <span className="fw-bolder fs-6 text-dark">
                                   {currentUser?.consultant?.consultant?.naissancePays ? (
                                        <span>
                                             {currentUser?.consultant?.consultant?.naissancePays.nomFr}{" "}
                                             {currentUser?.consultant?.consultant?.naissancePays.code === CONST_PAYS_CODE_FRANCE
                                                  ? currentUser?.consultant?.consultant?.naissanceVilleFrance?.nom
                                                  : currentUser?.consultant.consultant.naissanceVille}
                                        </span>
                                   ) : (
                                        <span className={"text-warning d-flex align-items-center"}>
                                             À renseigner <i className={"fas fa-exclamation-circle text-warning ms-1"} />
                                        </span>
                                   )}
                              </span>
                         </div>
                    </div>

                    <div className="row">
                         <label className="col-lg-4 fw-bold text-muted">Nationalité</label>

                         <div className="col-lg-8">
                              <span className="fw-bolder fs-6 text-dark">
                                   {currentUser?.consultant?.consultant?.nationalite ? (
                                        currentUser?.consultant?.consultant?.nationalite.nomFr
                                   ) : (
                                        <span className={"text-warning d-flex align-items-center"}>
                                             À renseigner <i className={"fas fa-exclamation-circle text-warning ms-1"} />
                                        </span>
                                   )}
                              </span>
                         </div>
                    </div>
               </div>

               <div className="pb-6">
                    <h3 className={"mt-8 mb-4"}>Résidence</h3>
                    <div className="separator separator-dashed mb-6" />

                    <div className="row mb-7">
                         <label className="col-lg-4 fw-bold text-muted">Adresse</label>

                         <div className="col-lg-8">
                              <span className="fw-bolder fs-6 text-dark">
                                   {currentUser?.consultant?.consultant?.domicileAdresse ? (
                                        currentUser?.consultant?.consultant?.domicileAdresse
                                   ) : (
                                        <span className={"text-warning d-flex align-items-center"}>
                                             À renseigner <i className={"fas fa-exclamation-circle text-warning ms-1"} />
                                        </span>
                                   )}
                              </span>
                         </div>
                    </div>

                    <div className="row mb-7">
                         <label className="col-lg-4 fw-bold text-muted">Ville et code postal</label>

                         <div className="col-lg-8">
                              <div className="col-lg-8">
                                   <span className="fw-bolder fs-6 text-dark">
                                        {currentUser?.consultant?.consultant?.domicileVille || currentUser?.consultant?.consultant?.domocileVilleFrance ? (
                                             <span>
                                                  {currentUser?.consultant?.consultant?.domicilePays.code === CONST_PAYS_CODE_FRANCE
                                                       ? currentUser?.consultant?.consultant?.domocileVilleFrance?.nom
                                                       : currentUser?.consultant.consultant.domicileVille}
                                             </span>
                                        ) : (
                                             <span className={"text-warning d-flex align-items-center"}>
                                                  À renseigner <i className={"fas fa-exclamation-circle text-warning ms-1"} />
                                             </span>
                                        )}
                                   </span>
                              </div>
                         </div>
                    </div>

                    <div className="row">
                         <label className="col-lg-4 fw-bold text-muted">
                              Pays {!currentUser?.consultant?.consultant?.domicilePays && <i className={"fas fa-exclamation-circle text-warning"} />}
                         </label>

                         <div className="col-lg-8">
                              <span className="fw-bolder fs-6 text-dark">{currentUser?.consultant?.consultant?.domicilePays?.nomFr}</span>
                         </div>
                    </div>
               </div>

               <div className="pb-6">
                    <h3 className={"mt-8 mb-4"}>Personne à contacter en cas d'urgence</h3>
                    <div className="separator separator-dashed mb-6" />

                    <div className="row mb-7">
                         <label className="col-lg-4 fw-bold text-muted">Prénom NOM</label>

                         <div className="col-lg-8">
                              <span className="fw-bolder fs-6 text-dark">
                                   {currentUser?.consultant?.consultant?.contactUrgencePrenom} {currentUser?.consultant?.consultant?.contactUrgenceNom}
                              </span>
                         </div>
                    </div>

                    <div className="row mb-7">
                         <label className="col-lg-4 fw-bold text-muted">Téléphone</label>

                         <div className="col-lg-8">
                              <span className="fw-bolder fs-6 text-dark">
                                   {currentUser?.consultant?.consultant?.contactUrgenceTelPays?.phoneCodeWithPlusPrefix} {currentUser?.consultant?.consultant?.contactUrgenceTel}
                              </span>
                         </div>
                    </div>
               </div>
          </>
     )
}
