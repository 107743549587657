import React from "react"
import "./styles.scss"
import { useNavigate } from "react-router-dom"
import { useDeclarationUpdate } from "./DeclarationUpdate"
import { DdcListingFilters } from "../../../core/_models"
import { MaladieListe } from "../../maladie/MaladieListe"

export const DeclarationUpdateArrets = () => {
     const context = useDeclarationUpdate()
     const navigate = useNavigate()

     return (
          <div className={"mx-auto"} style={{ maxWidth: "1400px" }}>
               <div className="mb-4">
                    <MaladieListe
                         maxHeight={"45vh"}
                         filtres={{ duOrAuWithThisMonthAndYear: context.data.declaration!.mois!.startOf("month").clone() } as DdcListingFilters}
                         editionMode={context.editionModeEnabled}
                    />
               </div>

               {/* FOOTER */}
               <div className="d-none d-sm-flex justify-content-between">
                    <div>
                         <button type={"button"} onClick={context.onClickOnRetourAuxDeclarationCallback} className={`btn btn-sm btn-light-primary me-2`}>
                              <span className={"fas fa-list-dots fs-1 me-3 align-middle"} /> Retour aux déclarations
                         </button>
                         <button type="button" className="btn btn-sm btn-light-primary me-3" onClick={() => navigate(`/activites/declarations/new`)}>
                              <span className={"fas fa-arrow-left fs-3 me-1 align-middle"} /> Période
                         </button>
                    </div>

                    <button
                         type="button"
                         className="btn btn-sm btn-primary"
                         onClick={() => {
                              context.setDeclarationStepHeader(context.enCoursDeRectificationEnabledDepuisRecapitulatif ? "recap" : "ddc")
                              context.setEnCoursDeRectificationEnabledDepuisRecapitulatif(false)
                         }}
                    >
                         {context.enCoursDeRectificationEnabledDepuisRecapitulatif ? "Récapitulatif" : "Congés"}
                         <span className={"fas fa-arrow-right fs-3 ms-1 align-middle"} />
                    </button>
               </div>

               <div className="d-flex d-sm-none flex-column">
                    <div className={"mb-3"}>
                         <button type="button" className="btn btn-sm btn-light-primary me-3" onClick={() => navigate(`/activites/declarations/new`)}>
                              <span className={"fas fa-arrow-left fs-3 me-1 align-middle"} /> Période
                         </button>
                         <button
                              type="button"
                              className="btn btn-sm btn-primary float-end"
                              onClick={() => {
                                   context.setDeclarationStepHeader(context.enCoursDeRectificationEnabledDepuisRecapitulatif ? "recap" : "ddc")
                                   context.setEnCoursDeRectificationEnabledDepuisRecapitulatif(false)
                              }}
                         >
                              {context.enCoursDeRectificationEnabledDepuisRecapitulatif ? "Récapitulatif" : "Congés"}
                              <span className={"fas fa-arrow-right fs-3 ms-1 align-middle"} />
                         </button>
                    </div>

                    <button type={"button"} onClick={context.onClickOnRetourAuxDeclarationCallback} className={`btn btn-sm btn-light-primary`}>
                         <span className={"fas fa-list-dots fs-1 me-3 align-middle"} /> Retour aux déclarations
                    </button>
               </div>
          </div>
     )
}
