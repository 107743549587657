import { Navigate, Route, Routes, useLocation } from "react-router-dom"
import { FournisseurRespGetCollaborateurs } from "./pages/collaborateurs/list/FournisseurRespGetCollaborateurs"
import { FournisseurRespGetCollaborateurDetails } from "./pages/collaborateurs/details/FournisseurRespGetCollaborateurDetails"
import { PageLink, PageTitle } from "../../_metronic/layout/core"
import React from "react"
import DeclarationsVoyants from "./pages/declarations/DeclarationsVoyants"
import { DeclarationsList } from "./pages/declarations/list/DeclarationsList"

const PrivateRoutes = () => {
     const location = useLocation()

     let prevUrl: any
     if (location.pathname.includes("auth") && localStorage.getItem("prevUrl")) {
          prevUrl = localStorage.getItem("prevUrl")
          localStorage.removeItem("prevUrl")
     }

     return (
          <Routes>
               <Route path="auth/*" element={<Navigate to={prevUrl || "/espace-resp-fournisseur/collaborateurs"} />} />

               <Route path="/espace-resp-fournisseur/collaborateurs">
                    <Route
                         path={""}
                         element={
                              <>
                                   <PageTitle breadcrumbs={collabBreadCrumbs}>Liste</PageTitle>
                                   <FournisseurRespGetCollaborateurs />
                              </>
                         }
                    />
                    <Route
                         path={":id/details"}
                         element={
                              <>
                                   <FournisseurRespGetCollaborateurDetails />
                              </>
                         }
                    />
               </Route>

               <Route path={"/espace-resp-fournisseur/declarations"}>
                    <Route
                         path={"voyants"}
                         element={
                              <>
                                   <PageTitle breadcrumbs={declarationsBreadCrumbs}>Voyants</PageTitle>
                                   <DeclarationsVoyants />
                              </>
                         }
                    />
                    <Route
                         path={"listing"}
                         element={
                              <>
                                   <DeclarationsList />
                              </>
                         }
                    />
               </Route>
          </Routes>
     )
}

const collabBreadCrumbs: Array<PageLink> = [
     {
          title: "Collaborateurs",
          path: "/espace-resp-fournisseur/collaborateurs",
     },
]

const declarationsBreadCrumbs: Array<PageLink> = [
     {
          title: "Declarations",
          path: "/espace-resp-fournisseur/declarations/voyants",
     },
]

export { PrivateRoutes }
