import React from "react"
import LoadingOverlay from "react-loading-overlay-ts"
import { useThemeMode } from "../../_metronic/partials"
import MySimpleSpinner from "./MySimpleSpinner"

type Props = {
     size?: "sm" | "md" | "lg" | "xl"
     children: JSX.Element
     isActive: boolean
     styles?: React.CSSProperties
     classNames?: string
}

const MyLoadingOverlay: React.FC<Props> = ({ size = "md", children, isActive, styles = {}, classNames = "" }) => {
     const { mode } = useThemeMode()

     return (
          <LoadingOverlay
               active={isActive}
               spinner={<MySimpleSpinner size={size} />}
               fadeSpeed={200}
               styles={{
                    wrapper: prev => ({ ...prev, ...styles }),
                    overlay: prev => ({ ...prev, ...styles, backgroundColor: mode === "light" ? "rgba(0, 0, 0, 0.3)" : "rgba(0, 0, 0, 0.5)" }),
               }}
               className={classNames}
          >
               {children}
          </LoadingOverlay>
     )
}

export default MyLoadingOverlay
