import React from "react";
import InputMask from 'react-input-mask'

import DatePicker, {registerLocale} from "react-datepicker";
import fr from "date-fns/locale/fr";
import moment from "moment";

registerLocale("fr", fr);


type Props = {
    name: string,
    // value: moment.Moment | undefined | null,
    value: string | undefined | null,
    placeholder,
    onChange: (value: any) => void

}

const MyDateMonthYearField: React.FC<Props> = ({
                                          name,
                                          value,
                                          onChange,
                                          placeholder
                                      }) => {
    return <DatePicker dateFormat="MM/yyyy"
                       showMonthYearPicker
                       locale="fr"
                       placeholderText={placeholder}
                       customInput={
                           <InputMask
                               className="form-control"
                               mask="99/9999"/>
                       }
                       name={name}
                       isClearable
                       selected={value ? moment(value, "MM/YYYY").toDate() : null}
                       onChange={val => {
                           if(val){
                               const d = moment(val).format('MM/YYYY')
                               onChange(d)
                           }else{
                               onChange('')
                           }

                       }}
                       />
}

export default MyDateMonthYearField;
