import React from "react"
import { ConsultantCraModel } from "../../../../../../models"
import "./styles.scss"
import { CraUpdateGrilleTempsSalarie } from "../../cra/craUpdate/CraUpdateGrilleTempsSalarie"
import { useDeclarationUpdate } from "./DeclarationUpdate"
import { declarationActiveCra } from "../../../core/_request"
import { useQuery, useQueryClient } from "react-query"
import MyAlert from "../../../../../../utils/MyAlert"
import { AxiosError } from "axios"
import { CONST_CONSULTANT_CONTRAT_ST } from "../../../../../../constants"
import CraUpdateGrilleTempsFreelance from "../../cra/craUpdate/CraUpdateGrilleTempsFreelance"

export const DeclarationUpdateCra = () => {
     const context = useDeclarationUpdate()
     const queryClient = useQueryClient()

     const activeCraQuery = useQuery<ConsultantCraModel, AxiosError>("activeCra", () => declarationActiveCra(context.data.declaration!.id!).then(r => r.data))

     return (
          <div className={"mx-auto"} style={{ maxWidth: "1500px" }}>
               {activeCraQuery.isFetching && (
                    <div className={"text-center p-4"}>
                         <span className="spinner-border mb-2"></span>
                         <p style={{ fontWeight: 500 }}>Chargement en cours ...</p>
                    </div>
               )}

               {!activeCraQuery.isFetching && activeCraQuery.isError && (
                    <MyAlert type={"danger"} classNames={"mb-4"}>
                         <>
                              {activeCraQuery.error.response?.data?.detail}
                              <button className={"btn btn-sm btn-danger ms-2"} onClick={() => activeCraQuery.refetch()}>
                                   Recharger
                              </button>
                         </>
                    </MyAlert>
               )}

               {!activeCraQuery.isFetching && !activeCraQuery.isError && activeCraQuery.data && (
                    <>
                         {context?.data.declaration?.consultant?.contrat === CONST_CONSULTANT_CONTRAT_ST ? (
                              <CraUpdateGrilleTempsFreelance
                                   data={activeCraQuery.data}
                                   setData={cra =>
                                        queryClient.setQueryData("activeCra", (item: ConsultantCraModel | undefined) => {
                                             return cra
                                        })
                                   }
                                   editionModeEnabled={context.editionModeEnabled}
                              />
                         ) : (
                              <CraUpdateGrilleTempsSalarie
                                   data={activeCraQuery.data}
                                   setData={cra =>
                                        queryClient.setQueryData("activeCra", (item: ConsultantCraModel | undefined) => {
                                             return cra
                                        })
                                   }
                                   editionModeEnabled={context.editionModeEnabled}
                              />
                         )}
                    </>
               )}

               {/* FOOTER */}
               <div className="mt-4">
                    <div className="d-none d-sm-flex justify-content-between">
                         <div>
                              <button type={"button"} onClick={context.onClickOnRetourAuxDeclarationCallback} className={`btn btn-sm btn-light-primary me-2`}>
                                   <span className={"fas fa-list-dots fs-1 me-3 align-middle"} /> Retour aux déclarations
                              </button>
                              {context?.data.declaration?.consultant?.contrat !== CONST_CONSULTANT_CONTRAT_ST && (
                                   <button type="button" className="btn btn-sm btn-light-primary me-3" onClick={() => context.setDeclarationStepHeader("ddc")}>
                                        <span className={"fas fa-arrow-left fs-3 me-1 align-middle"} /> Congés
                                   </button>
                              )}
                         </div>

                         <button
                              type="button"
                              className="btn btn-sm btn-primary"
                              onClick={() => {
                                   context.setDeclarationStepHeader(context.enCoursDeRectificationEnabledDepuisRecapitulatif ? "recap" : "ndf")
                                   context.setEnCoursDeRectificationEnabledDepuisRecapitulatif(false)
                              }}
                         >
                              {context.enCoursDeRectificationEnabledDepuisRecapitulatif
                                   ? "Récapitulatif"
                                   : context?.data.declaration?.consultant?.contrat === CONST_CONSULTANT_CONTRAT_ST
                                   ? "Factures de frais"
                                   : "Notes de frais"}
                              <span className={"fas fa-arrow-right fs-3 ms-1 align-middle"} />
                         </button>
                    </div>

                    <div className="d-flex d-sm-none flex-column">
                         <div className={"mb-3"}>
                              {context?.data.declaration?.consultant?.contrat !== CONST_CONSULTANT_CONTRAT_ST && (
                                   <button type="button" className="btn btn-sm btn-light-primary me-3" onClick={() => context.setDeclarationStepHeader("ddc")}>
                                        <span className={"fas fa-arrow-left fs-3 me-1 align-middle"} /> Congés
                                   </button>
                              )}
                              <button
                                   type="button"
                                   className="btn btn-sm btn-primary float-end"
                                   onClick={() => {
                                        context.setDeclarationStepHeader(context.enCoursDeRectificationEnabledDepuisRecapitulatif ? "recap" : "ndf")
                                        context.setEnCoursDeRectificationEnabledDepuisRecapitulatif(false)
                                   }}
                              >
                                   {context.enCoursDeRectificationEnabledDepuisRecapitulatif
                                        ? "Récapitulatif"
                                        : context?.data.declaration?.consultant?.contrat === CONST_CONSULTANT_CONTRAT_ST
                                        ? "Factures de frais"
                                        : "Notes de frais"}
                                   <span className={"fas fa-arrow-right fs-3 ms-1 align-middle"} />
                              </button>
                         </div>

                         <button type={"button"} onClick={context.onClickOnRetourAuxDeclarationCallback} className={`btn btn-sm btn-light-primary`}>
                              <span className={"fas fa-list-dots fs-1 me-3 align-middle"} /> Retour aux déclarations
                         </button>
                    </div>
               </div>
          </div>
     )
}
