import React, { useContext, useState } from "react"
import { FournisseurRespGetCollaborateurDetailsContext } from "./FournisseurRespGetCollaborateurDetails"
import { ConsultantModel } from "../../../../models"
import MyCard from "../../../../utils/MyCard"
import MyLoadingOverlay from "../../../../utils/MyLoadingOverlay"
import { KTSVG, toAbsoluteUrl } from "../../../../../_metronic/helpers"
import MyTooltip from "../../../../utils/MyTooltip"
import { getThumbnailDriveUrl } from "../../../../helpers/helper-global"
import { CONST_CONSULTANT_ETAT_EN_COURS_EMBAUCHE_POUR_MISSION, CONST_CONSULTANT_ETAT_EN_COURS_EMBAUCHE_SANS_MISSION } from "../../../../constants"

const Header = () => {
     const context = useContext(FournisseurRespGetCollaborateurDetailsContext)
     const data = context.detailsQuery.data as ConsultantModel
     const user = data.candidat?.user

     const [isAvatarLoading, setIsAvatarLoading] = useState<boolean>(true)

     return (
          <MyCard classNames={"mb-5 mb-xl-5"}>
               <MyCard.Body classNames={"pt-9 pb-0"}>
                    <div className="d-flex flex-md-row flex-column text-center mb-3 justify-content-md-start justify-content-center">
                         <div className="me-md-7 mb-4">
                              <MyLoadingOverlay isActive={isAvatarLoading} classNames={"symbol symbol-100px symbol-md-160px symbol-fixed position-relative"}>
                                   <div className="symbol symbol-100px symbol-md-160px symbol-fixed position-relative">
                                        <img
                                             src={user?.avatarGoogleDriveId ? getThumbnailDriveUrl(user.avatarGoogleDriveId) : toAbsoluteUrl("/media/avatars/blank.png")}
                                             alt="Photo de profil"
                                             onLoad={() => setIsAvatarLoading(false)}
                                        />
                                        <div className="position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-white h-20px w-20px"></div>
                                   </div>
                              </MyLoadingOverlay>
                         </div>

                         <div className="flex-md-grow-1">
                              <div className="d-flex justify-content-center justify-content-md-between align-items-start flex-wrap mb-3">
                                   <div className="d-flex flex-column">
                                        <div className="d-flex justify-content-center justify-content-md-start align-items-center mb-2">
                                             <span className="text-gray-800 text-hover-primary fs-2 fw-bolder me-1" style={{ cursor: "default" }}>
                                                  {user?.prenomNom}
                                             </span>
                                             <span style={{ cursor: "default" }}>
                                                  <KTSVG path="/media/icons/duotune/general/gen026.svg" className="svg-icon-1 svg-icon-primary" />
                                             </span>
                                        </div>

                                        <div className="d-flex flex-wrap fw-bold fs-6">
                                             {user?.telIndicatif && data.telPro ? (
                                                  <a
                                                       href={`tel:${user?.telIndicatif?.phoneCodeWithPlusPrefix} ${data.telPro}`}
                                                       className="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2"
                                                  >
                                                       <i className={"fas fa-phone me-1"} /> {user?.telIndicatif?.phoneCodeWithPlusPrefix} {user?.tel}
                                                  </a>
                                             ) : (
                                                  <span className={"d-flex align-items-center text-gray-400 me-5 mb-2"}>
                                                       <i className={"fa-duotone fa-phone me-1"} />
                                                       <span>Non renseigné</span>
                                                  </span>
                                             )}

                                             {user?.username?.includes(".merci") ? (
                                                  <span className={"d-flex align-items-center text-gray-400 me-5 mb-2"}>
                                                       <i className={"fa-duotone fa-envelope me-1"} />
                                                       <span>Non renseigné</span>
                                                  </span>
                                             ) : (
                                                  <a href={`mailto:${user?.username}`} className="d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2">
                                                       <i className={"fa-duotone fa-envelope me-1"} /> {user?.username}
                                                  </a>
                                             )}
                                        </div>
                                   </div>
                              </div>

                              <div className="d-flex flex-wrap flex-stack justify-content-md-start justify-content-center">
                                   <div className="d-flex align-items-center w-200px w-sm-300px flex-column">
                                        <div className="d-flex justify-content-between w-100 mt-auto mb-2">
                                             <span className="fw-bold fs-6 text-gray-400">Remplissage du profil</span>
                                             <span className="fw-bolder fs-6">100%</span>
                                        </div>
                                        <div className="h-5px mx-3 w-100 bg-light mb-3">
                                             <div
                                                  className="bg-success rounded h-5px"
                                                  role="progressbar"
                                                  style={{
                                                       width: `100%`,
                                                  }}
                                             ></div>
                                        </div>
                                   </div>
                              </div>
                         </div>
                    </div>

                    <div className="d-flex justify-content-center overflow-auto">
                         <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap">
                              <MyTooltip title={"Informations de base"}>
                                   <li className="nav-item">
                                        <span
                                             className={`nav-link text-active-primary cursor-pointer ${context.selectedNavigationItem === "INFO_BASE" && "active"}`}
                                             onClick={() => context.setSelectedNavigationItem("INFO_BASE")}
                                        >
                                             <i className={"fas fa-info-circle"} style={{ fontSize: "2rem" }}></i>
                                        </span>
                                   </li>
                              </MyTooltip>

                              {![CONST_CONSULTANT_ETAT_EN_COURS_EMBAUCHE_POUR_MISSION, CONST_CONSULTANT_ETAT_EN_COURS_EMBAUCHE_SANS_MISSION].includes(data.etat!) && (
                                   <MyTooltip title={"Déclarations d'activité"}>
                                        <li className="nav-item">
                                             <span
                                                  className={`nav-link text-active-primary cursor-pointer ${context.selectedNavigationItem === "DECLARATIONS" && "active"}`}
                                                  onClick={() => context.setSelectedNavigationItem("DECLARATIONS")}
                                             >
                                                  <i className={"fas fa-calendar-days"} style={{ fontSize: "2rem" }}></i>
                                             </span>
                                        </li>
                                   </MyTooltip>
                              )}
                         </ul>
                    </div>
               </MyCard.Body>
          </MyCard>
     )
}

export default Header
