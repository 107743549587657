import React from "react"
import { SidebarMenuItem } from "./SidebarMenuItem"
import { useAuth } from "../../../../../app/modules/auth"
import MyTooltip from "../../../../../app/utils/MyTooltip"
import {
     CONST_CONSULTANT_CONTRAT_ST,
     CONST_USER_ROLE_CANDIDAT,
     CONST_USER_ROLE_CANDIDAT_EN_COURS_EMBAUCHE,
     CONST_USER_ROLE_CONSULTANT,
     CONST_USER_ROLE_RESPONSABLE_FOURNISSEUR,
} from "../../../../../app/constants"
import { SidebarMenuItemWithSub } from "./SidebarMenuItemWithSub"

const SidebarMenuMain = () => {
     const { currentUser, infoPersoToBeFilled, documentsToBeFilled, infoFournisseurToBeFilled } = useAuth()

     return (
          <>
               <SidebarMenuItem to="/mon-compte/profil" fontIcon={"fad fa-user"} title={"Mon profil"} />

               {/* Uniquement pour l'espace candidat & préembauche*/}
               {(currentUser?.user?.roles?.includes(CONST_USER_ROLE_CANDIDAT) || currentUser?.user?.roles?.includes(CONST_USER_ROLE_CANDIDAT_EN_COURS_EMBAUCHE)) && (
                    <>
                         <SidebarMenuItem to="/offres" fontIcon={"fa-duotone fa-briefcase"} title={"Offres"} />
                         <SidebarMenuItem to="/mes-candidatures" fontIcon={"fa-duotone fa-paper-plane"} title={"Mes candidatures"} />
                    </>
               )}

               {!currentUser?.user?.roles?.includes(CONST_USER_ROLE_RESPONSABLE_FOURNISSEUR) && (
                    <SidebarMenuItem to="/mes-docos" fontIcon={"fa-duotone fa-layer-group"} title={"Mes Dossiers de Compétences"} />
               )}

               {(currentUser?.user?.roles?.includes(CONST_USER_ROLE_CONSULTANT) || currentUser?.user?.roles?.includes(CONST_USER_ROLE_CANDIDAT_EN_COURS_EMBAUCHE)) && (
                    <SidebarMenuItem to="mon-compte/mes-documents" fontIcon={"fas fa-folder-tree"} title={"Mes documents"} />
               )}

               {currentUser?.user?.roles?.includes(CONST_USER_ROLE_CANDIDAT_EN_COURS_EMBAUCHE) && (
                    <>
                         <span>
                              <SidebarMenuItem
                                   to="/info-a-completer"
                                   icon={
                                        infoPersoToBeFilled.length > 0 ||
                                        documentsToBeFilled.length > 0 ||
                                        (currentUser?.fournisseur?.fournisseur && infoFournisseurToBeFilled.length > 0)
                                             ? "/media/icons/duotune/general/gen044.svg"
                                             : "/media/icons/duotune/general/gen043.svg"
                                   }
                                   title={"Dossier de recrutement"}
                                   iconColor={
                                        infoPersoToBeFilled.length > 0 ||
                                        documentsToBeFilled.length > 0 ||
                                        (currentUser?.fournisseur?.fournisseur && infoFournisseurToBeFilled.length > 0)
                                             ? "text-warning"
                                             : "text-success"
                                   }
                              />
                         </span>
                    </>
               )}

               {currentUser?.user?.roles?.includes(CONST_USER_ROLE_CONSULTANT) && (
                    <>
                         <SidebarMenuItemWithSub title={"Mes activités"} to={"#"} fontIcon={"fas fa-calendar-days"}>
                              <SidebarMenuItem to={`/activites/declarations`} title={"Déclarations mensuelles"} fontIcon={"fas fa-list-check"} />

                              {currentUser?.consultant?.consultant?.contrat != CONST_CONSULTANT_CONTRAT_ST && (
                                   <>
                                        <MyTooltip title={"Mes Demandes De Congés"} placement={"right"}>
                                             <span>
                                                  <SidebarMenuItem to="activites/ddc" fontIcon={"fas fa-umbrella-beach"} title={"Mes DDC"} />
                                             </span>
                                        </MyTooltip>

                                        <MyTooltip title={"Maladie"} placement={"right"}>
                                             <span>
                                                  <SidebarMenuItem to="activites/maladie" fontIcon={"fas fa-file-medical"} title={"Mes arrêts maladie"} />
                                             </span>
                                        </MyTooltip>
                                   </>
                              )}

                              <MyTooltip title={"Mes Notes de Frais"} placement={"right"}>
                                   <span>
                                        <SidebarMenuItem to="activites/ndf" fontIcon={"fas fa-file-invoice-dollar"} title={"Notes de Frais"} />
                                   </span>
                              </MyTooltip>
                         </SidebarMenuItemWithSub>
                    </>
               )}

               {currentUser?.user?.roles?.includes(CONST_USER_ROLE_RESPONSABLE_FOURNISSEUR) && (
                    <>
                         <SidebarMenuItem to="/espace-resp-fournisseur/collaborateurs" fontIcon={"fad fa-user-astronaut"} title={"Collaborateurs"} />
                         <SidebarMenuItem to="/espace-resp-fournisseur/declarations/voyants" fontIcon={"fas fa-calendar-days"} title={"Déclarations"} />
                    </>
               )}
          </>
     )
}

export { SidebarMenuMain }
