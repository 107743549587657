import { PageLink, PageTitle } from "../../../_metronic/layout/core"
import { Navigate, Outlet, Route, Routes } from "react-router-dom"
import { CompteHeader } from "./CompteHeader"
import { Compte } from "./components/compte/Compte"
import { Documents } from "./components/documents/Documents"
import { CompteInfoPerso } from "./components/infoPerso/CompteInfoPerso"
import { useAuth } from "../auth"
import React from "react"
import { CompteInfoFournisseur } from "./components/infoFournisseur/CompteInfoFournisseur"

const profilBreadCrumbs: Array<PageLink> = [
     {
          title: "Mon compte",
          path: "/mon-compte/profil",
     },
]

const ComptePage = () => {
     const { currentUser } = useAuth()

     return (
          <Routes>
               <Route
                    element={
                         <>
                              <div className={"d-flex justify-content-center"}>
                                   <div className={"w-100 w-lg-75 w-xxl-65"}>
                                        <CompteHeader />
                                        <Outlet />
                                   </div>
                              </div>
                         </>
                    }
               >
                    <Route
                         path="/profil"
                         element={
                              <>
                                   <PageTitle breadcrumbs={profilBreadCrumbs}>Mes informations personnelles</PageTitle>
                                   <CompteInfoPerso />
                              </>
                         }
                    />

                    <Route
                         path="/info-fournisseur"
                         element={
                              <>
                                   <PageTitle breadcrumbs={profilBreadCrumbs}>{currentUser?.fournisseur?.fournisseur?.raisonSociale}</PageTitle>
                                   <CompteInfoFournisseur />
                              </>
                         }
                    />

                    <Route
                         path="/settings"
                         element={
                              <>
                                   <PageTitle breadcrumbs={profilBreadCrumbs}>Paramètres du compte</PageTitle>
                                   <Compte />
                              </>
                         }
                    />

                    <Route
                         path="/mes-documents"
                         element={
                              <>
                                   <PageTitle breadcrumbs={profilBreadCrumbs}>Documents</PageTitle>
                                   <Documents />
                              </>
                         }
                    />

                    <Route index element={<Navigate to="profil" />} />
               </Route>
          </Routes>
     )
}

export default ComptePage
