import { CONST_CONSULTANT_CONTRAT_ST, CONST_CONSULTANT_STATUT_ST_PORTAGE, CONST_CONSULTANT_STATUT_ST_SOCIETE_SERVICE } from "../../../../../../constants"
import React from "react"
import { useDeclarationUpdate } from "./DeclarationUpdate"

const TabNavigationHeader = ({ stepperStep }: { stepperStep?: "periode" }) => {
     const context = useDeclarationUpdate()

     if (context.data.declaration?.consultant?.contrat === CONST_CONSULTANT_CONTRAT_ST) {
          return (
               <div className="stepper stepper-links mb-5">
                    <div className="stepper-nav">
                         {/* begin::Step période */}
                         <div
                              className={`stepper-item m-sm-5 m-2 ${stepperStep == "periode" && "current"} ${
                                   ["factures", "ddc", "cra", "ndf", "ticket_resto", "recap"].includes(context.declarationStepHeader) && "completed"
                              }`}
                         >
                              <h3 className="stepper-title">
                                   <span className={"d-none d-sm-block"}>Période</span>
                                   <span className={"d-block d-sm-none fas fa-calendar-plus"} style={{ fontSize: "1.5rem" }}></span>
                              </h3>
                         </div>
                         {/* end::Step période */}

                         {/* begin::Step CRA */}
                         {![CONST_CONSULTANT_STATUT_ST_PORTAGE, CONST_CONSULTANT_STATUT_ST_SOCIETE_SERVICE].includes(context.data.declaration.consultant?.statut!) && (
                              <div
                                   className={`stepper-item m-sm-5 m-4 ${context.declarationStepHeader == "cra" && "current"} ${
                                        ["periode", "factures", "ndf", "ticket_resto", "recap"].includes(context.declarationStepHeader) && "completed"
                                   } ${!["periode", "cra"].includes(context.declarationStepHeader) && "cursor-pointer"}`}
                                   onClick={() => {
                                        if (!["periode", "cra"].includes(context.declarationStepHeader)) context.setDeclarationStepHeader!("cra")
                                   }}
                              >
                                   <h3 className="stepper-title">
                                        <span className={"d-none d-sm-block"}>CRA</span>
                                        <span className={"d-block d-sm-none fas fa-calendar-days"} style={{ fontSize: "1.5rem" }}></span>
                                   </h3>
                              </div>
                         )}
                         {/* end::Step CRA */}

                         {/* begin::Step NDF */}
                         <div
                              className={`stepper-item m-sm-5 m-4 ${["ndf"].includes(context.declarationStepHeader) && "current"} ${
                                   ["factures", "recap"].includes(context.declarationStepHeader) && "completed"
                              } ${!["periode", "ndf"].includes(context.declarationStepHeader) && "cursor-pointer"}`}
                              onClick={() => !["periode", "ndf"].includes(context.declarationStepHeader) && context.setDeclarationStepHeader!("ndf")}
                         >
                              <h3 className="stepper-title">
                                   <span className={"d-none d-sm-block"}>Factures de frais</span>
                                   <span className={"d-block d-sm-none fas fa-file-invoice-dollar"} style={{ fontSize: "1.5rem" }}></span>
                              </h3>
                         </div>
                         {/* end::Step NDF */}

                         {/* begin::Step Facture */}
                         <div
                              className={`stepper-item m-sm-5 m-4 ${context.declarationStepHeader === "factures" && "current"} ${
                                   ["recap"].includes(context.declarationStepHeader) && "completed"
                              } ${!["periode", "factures"].includes(context.declarationStepHeader) && "cursor-pointer"}`}
                              onClick={() => {
                                   if (!["periode", "factures"].includes(context.declarationStepHeader)) context.setDeclarationStepHeader("factures")
                              }}
                         >
                              <h3 className="stepper-title">
                                   <span className={"d-none d-sm-block"}>Factures de prestation</span>
                                   <span className={"d-block d-sm-none fas fa-calendar-days"} style={{ fontSize: "1.5rem" }}></span>
                              </h3>
                         </div>
                         {/* end::Step Facture */}

                         {/* begin::Step 5 */}
                         <div
                              className={`stepper-item ${context.declarationStepHeader == "recap" && "current"}  ${
                                   !["periode", "recap"].includes(context.declarationStepHeader) && "cursor-pointer"
                              }`}
                              onClick={() => !["periode", "recap"].includes(context.declarationStepHeader) && context.setDeclarationStepHeader!("recap")}
                         >
                              <h3 className="stepper-title">
                                   <span className={"d-none d-sm-block"}>Récapitulatif</span>
                                   <span className={"d-block d-sm-none fas fa-list-alt"} style={{ fontSize: "1.5rem" }}></span>
                              </h3>
                         </div>
                         {/* end::Step 5 */}
                    </div>
               </div>
          )
     } else {
          return (
               <div className="stepper stepper-links mb-5">
                    <div className="stepper-nav">
                         {/* begin::Step période */}
                         <div
                              className={`stepper-item m-sm-5 m-2 ${stepperStep == "periode" && "current"} ${
                                   ["factures", "ddc", "cra", "ndf", "ticket_resto", "recap"].includes(context.declarationStepHeader) && "completed"
                              }`}
                         >
                              <h3 className="stepper-title">
                                   <span className={"d-none d-sm-block"}>Période</span>
                                   <span className={"d-block d-sm-none fas fa-calendar-plus"} style={{ fontSize: "1.5rem" }}></span>
                              </h3>
                         </div>
                         {/* end::Step période */}

                         {/* begin::Step Arrêts */}
                         <div
                              className={`stepper-item m-sm-5 m-4 ${context.declarationStepHeader === "arrets" && "current"} ${
                                   ["ddc", "cra", "ndf", "ticket_resto", "recap"].includes(context.declarationStepHeader) && "completed"
                              }  ${!["periode", "ddc"].includes(context.declarationStepHeader) && "cursor-pointer"}`}
                              onClick={() => !["periode", "arrets"].includes(context.declarationStepHeader) && context.setDeclarationStepHeader!("arrets")}
                         >
                              <h3 className="stepper-title">
                                   <span className={"d-none d-sm-block"}>Arrêts</span>
                                   <span className={"d-block d-sm-none fas fa-file-medical"} style={{ fontSize: "1.5rem" }}></span>
                              </h3>
                         </div>
                         {/* end::Step Arrêts */}

                         {/* begin::Step DDC */}
                         <div
                              className={`stepper-item m-sm-5 m-4 ${context.declarationStepHeader == "ddc" && "current"} ${
                                   ["cra", "ndf", "ticket_resto", "recap"].includes(context.declarationStepHeader) && "completed"
                              }  ${!["periode", "ddc"].includes(context.declarationStepHeader) && "cursor-pointer"}`}
                              onClick={() => !["periode", "ddc"].includes(context.declarationStepHeader) && context.setDeclarationStepHeader!("ddc")}
                         >
                              <h3 className="stepper-title">
                                   <span className={"d-none d-sm-block"}>Congés</span>
                                   <span className={"d-block d-sm-none fas fa-umbrella-beach"} style={{ fontSize: "1.5rem" }}></span>
                              </h3>
                         </div>
                         {/* end::Step DDC */}

                         {/* begin::Step CRA */}
                         <div
                              className={`stepper-item m-sm-5 m-4 ${context.declarationStepHeader == "cra" && "current"} ${
                                   ["periode", "factures", "ndf", "ticket_resto", "recap"].includes(context.declarationStepHeader) && "completed"
                              } ${!["periode", "cra"].includes(context.declarationStepHeader) && "cursor-pointer"}`}
                              onClick={() => {
                                   if (!["periode", "cra"].includes(context.declarationStepHeader)) context.setDeclarationStepHeader!("cra")
                              }}
                         >
                              <h3 className="stepper-title">
                                   <span className={"d-none d-sm-block"}>CRA</span>
                                   <span className={"d-block d-sm-none fas fa-calendar-days"} style={{ fontSize: "1.5rem" }}></span>
                              </h3>
                         </div>
                         {/* end::Step CRA */}

                         {/* begin::Step NDF */}
                         <div
                              className={`stepper-item m-sm-5 m-4 ${["ndf"].includes(context.declarationStepHeader) && "current"} ${
                                   ["ticket_resto", "recap"].includes(context.declarationStepHeader) && "completed"
                              } ${!["periode", "ndf"].includes(context.declarationStepHeader) && "cursor-pointer"}`}
                              onClick={() => !["periode", "ndf"].includes(context.declarationStepHeader) && context.setDeclarationStepHeader!("ndf")}
                         >
                              <h3 className="stepper-title">
                                   <span className={"d-none d-sm-block"}>Notes de frais</span>
                                   <span className={"d-block d-sm-none fas fa-file-invoice-dollar"} style={{ fontSize: "1.5rem" }}></span>
                              </h3>
                         </div>
                         {/* end::Step NDF */}

                         {/* begin::Step Tickets restaurant */}
                         <div
                              className={`stepper-item m-sm-5 m-4 ${context.declarationStepHeader == "ticket_resto" && "current"} ${
                                   ["recap"].includes(context.declarationStepHeader) && "completed"
                              } ${!["periode", "ticket_resto"].includes(context.declarationStepHeader) && "cursor-pointer"}`}
                              onClick={() => !["periode", "ticket_resto"].includes(context.declarationStepHeader) && context.setDeclarationStepHeader!("ticket_resto")}
                         >
                              <h3 className="stepper-title">
                                   <span className={"d-none d-sm-block"}>Tickets restaurant</span>
                                   <span className={"d-block d-sm-none fas fa-utensils"} style={{ fontSize: "1.5rem" }}></span>
                              </h3>
                         </div>
                         {/* end::Step Tickets restaurant */}

                         {/* begin::Step 5 */}
                         <div
                              className={`stepper-item ${context.declarationStepHeader == "recap" && "current"}  ${
                                   !["periode", "recap"].includes(context.declarationStepHeader) && "cursor-pointer"
                              }`}
                              onClick={() => !["periode", "recap"].includes(context.declarationStepHeader) && context.setDeclarationStepHeader!("recap")}
                         >
                              <h3 className="stepper-title">
                                   <span className={"d-none d-sm-block"}>Récapitulatif</span>
                                   <span className={"d-block d-sm-none fas fa-list-alt"} style={{ fontSize: "1.5rem" }}></span>
                              </h3>
                         </div>
                         {/* end::Step 5 */}
                    </div>
               </div>
          )
     }
}

export default TabNavigationHeader
