import React, { useState } from "react"
import * as Yup from "yup"
import { useFormik } from "formik"
import { useAuth } from "../../../../../auth"
import { setEmailConfirmCode, setEmailGenerateCode } from "../../../../core/_requests"
import { AxiosError } from "axios"

import { useIntl } from "react-intl"
import { KTSVG } from "../../../../../../../_metronic/helpers"
import { Slide, toast } from "react-toastify"
import MySimpleSpinner from "../../../../../../utils/MySimpleSpinner"

const formSetMailSchemaValidation = Yup.object().shape({
     mail: Yup.string().email("Mauvais format").min(3, "Minimum 3 caractères").max(50, "Maximum 50 caractères").required("Ce champ est requis"),
     currentPassword: Yup.string().min(3, "Minimum 3 symbols").max(50, "Maximum 50 symbols").required("Ce champ est requis"),
})

const formConfirmSetMailSchemaValidation = Yup.object().shape({
     code: Yup.string().required("Ce champ est requis"),
})

const ChangeEmail: React.FC = () => {
     const { currentUser, setCurrentUser, saveAuth } = useAuth()
     const intl = useIntl()

     const [showEmailForm, setShowEmailForm] = useState<boolean>(false)
     const [showEmailCodeConfirmationForm, setShowEmailCodeConfirmationForm] = useState<boolean>(false)

     const formikSetMail = useFormik<{ mail: string; currentPassword: string }>({
          initialValues: {
               mail: "",
               currentPassword: "",
          },
          validationSchema: formSetMailSchemaValidation,
          onSubmit: (values, { setStatus, setSubmitting }) => {
               setStatus(null)
               setEmailGenerateCode(values.mail, values.currentPassword)
                    .then(() => {
                         setSubmitting(false)
                         setShowEmailCodeConfirmationForm(true)
                    })
                    .catch((e: AxiosError) => {
                         if (e.response?.status === 400) {
                              setStatus(e.response?.data?.detail)
                         } else {
                              setStatus(intl.formatMessage({ id: "FORM.STATUS.UNEXPECTED_ERROR_MESSAGE" }))
                         }
                         setSubmitting(false)
                    })
          },
     })

     const formikConfirmSetMail = useFormik<{ code: string }>({
          initialValues: {
               code: "",
          },
          validationSchema: formConfirmSetMailSchemaValidation,
          onSubmit: (values, { setStatus, setSubmitting }) => {
               setStatus(null)
               setEmailConfirmCode(formikSetMail.values.mail, formikSetMail.values.currentPassword, values.code)
                    .then(() => {
                         toast.info("L'adresse mail a bien été modifiée! Une reconnexion est requise.", {
                              position: "top-right",
                              autoClose: 4000,
                              hideProgressBar: false,
                              closeOnClick: true,
                              pauseOnHover: true,
                              transition: Slide,
                              draggable: true,
                              theme: "light",
                         })
                         setCurrentUser(undefined)
                         setSubmitting(false)
                         setShowEmailForm(false)
                         setShowEmailCodeConfirmationForm(false)
                         saveAuth(undefined)
                    })
                    .catch((e: AxiosError) => {
                         if (e.response?.status === 400) {
                              setStatus(e.response?.data?.detail)
                         } else {
                              setStatus(intl.formatMessage({ id: "FORM.STATUS.UNEXPECTED_ERROR_MESSAGE" }))
                         }
                         setSubmitting(false)
                    })
          },
     })

     if (!showEmailCodeConfirmationForm) {
          return (
               <div className="d-flex flex-wrap align-items-center">
                    <div id="kt_signin_email" className={" " + (showEmailForm && "d-none")}>
                         <div className="fs-6 fw-bolder mb-1">Email</div>
                         <div className="fw-bold text-gray-600">
                              {currentUser?.consultant ? currentUser?.consultant?.consultant?.mailPro : currentUser?.candidat?.candidat?.email}
                         </div>
                    </div>

                    <div className={"flex-row-fluid " + (!showEmailForm && "d-none")}>
                         <form onSubmit={formikSetMail.handleSubmit} className="form" noValidate>
                              {formikSetMail.status && (
                                   <div className="alert alert-danger">
                                        <div className="alert-text font-weight-bold">{formikSetMail.status}</div>
                                   </div>
                              )}

                              <div className="row mb-6">
                                   <div className="col-lg-6 mb-4 mb-lg-0">
                                        <div className="fv-row mb-0">
                                             <label htmlFor="mail" className="form-label fs-6 fw-bolder mb-3">
                                                  Entrez une nouvelle adresse mail
                                             </label>
                                             <input type="email" className="form-control" placeholder="Adresse mail" id={"mail"} {...formikSetMail.getFieldProps("mail")} />
                                             {formikSetMail.touched.mail && formikSetMail.errors.mail && (
                                                  <div className="fv-plugins-message-container">
                                                       <div className="fv-help-block">{formikSetMail.errors.mail}</div>
                                                  </div>
                                             )}
                                        </div>
                                   </div>
                                   <div className="col-lg-6">
                                        <div className="fv-row mb-0">
                                             <label htmlFor="currentPassword" className="form-label fs-6 fw-bolder mb-3">
                                                  Votre mot de passe actuel
                                             </label>
                                             <input
                                                  type="password"
                                                  className="form-control"
                                                  placeholder={"Votre mot de passe actuel"}
                                                  id="currentPassword"
                                                  {...formikSetMail.getFieldProps("currentPassword")}
                                             />
                                             {formikSetMail.touched.currentPassword && formikSetMail.errors.currentPassword && (
                                                  <div className="fv-plugins-message-container">
                                                       <div className="fv-help-block">{formikSetMail.errors.currentPassword}</div>
                                                  </div>
                                             )}
                                        </div>
                                   </div>
                              </div>
                              <div className="d-flex justify-content-end">
                                   <button type="submit" className="btn btn-primary  me-2 px-6" disabled={formikSetMail.isSubmitting}>
                                        {!formikSetMail.isSubmitting && "Mettre à jour"}
                                        {formikSetMail.isSubmitting && (
                                             <span className="indicator-progress" style={{ display: "block" }}>
                                                  Veuillez patienter... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                             </span>
                                        )}
                                   </button>
                                   <button
                                        id="kt_signin_cancel"
                                        type="button"
                                        onClick={() => {
                                             setShowEmailForm(false)
                                        }}
                                        className="btn btn-light-danger px-6"
                                   >
                                        Annuler
                                   </button>
                              </div>
                         </form>
                    </div>

                    <div className={"ms-auto " + (showEmailForm && "d-none")}>
                         <button onClick={() => setShowEmailForm(true)} className="btn btn-light btn-active-light-primary">
                              Modifier
                         </button>
                    </div>
               </div>
          )
     } else {
          return (
               <>
                    <div className="alert alert-success d-flex align-items-center p-5 mb-10">
                         <span className={"svg-icon svg-icon-2hx svg-icon-success me-3"}>
                              <KTSVG path="/media/icons/duotune/general/gen037.svg" className="svg-icon svg-icon-2hx svg-icon-success me-4" />
                         </span>
                         <div className="d-flex flex-column">
                              <h5 className="mb-1">Un code de confirmation a été envoyé sur votre adresse mail.</h5>
                              <span>
                                   Veuillez saisir le code envoyé sur l'adresse "
                                   {currentUser?.consultant ? currentUser?.consultant?.consultant?.mailPro : currentUser?.candidat?.candidat?.email}" afin de confirmer le
                                   changement.
                              </span>
                         </div>
                    </div>

                    <form onSubmit={formikConfirmSetMail.handleSubmit} className="form" noValidate>
                         {formikConfirmSetMail.status && (
                              <div className="alert alert-danger">
                                   <div className="alert-text font-weight-bold">{formikConfirmSetMail.status}</div>
                              </div>
                         )}

                         <div className="row">
                              <div className="col-8">
                                   <input
                                        type="password"
                                        className="form-control form-control-lg form-control-solid"
                                        placeholder={"Saisissez le code reçu par email"}
                                        {...formikConfirmSetMail.getFieldProps("code")}
                                   />
                              </div>
                              <div className="col-2 d-grid">
                                   <button type="submit" className="btn btn-primary me-2 px-6" disabled={formikConfirmSetMail.isSubmitting}>
                                        {!formikConfirmSetMail.isSubmitting && (
                                             <span>
                                                  <span className={"d-none d-sm-block"}>Confirmer</span> <span className={"d-sm-none fas fa-check-circle fs-1"} />
                                             </span>
                                        )}
                                        {formikConfirmSetMail.isSubmitting && <MySimpleSpinner size={"sm"} />}
                                   </button>
                              </div>
                              <div className="col-2 d-grid">
                                   <button
                                        type="submit"
                                        className="btn btn-light-danger me-2 px-6"
                                        disabled={formikConfirmSetMail.isSubmitting}
                                        onClick={() => {
                                             setShowEmailCodeConfirmationForm(false)
                                        }}
                                   >
                                        <span className={"d-none d-sm-block"}>Annuler</span> <span className={"d-sm-none fas fa-times-circle fs-1"} />
                                   </button>
                              </div>
                         </div>
                         {formikConfirmSetMail.touched.code && formikConfirmSetMail.errors.code && (
                              <div className="fv-plugins-message-container">
                                   <div className="fv-help-block">{formikConfirmSetMail.errors.code}</div>
                              </div>
                         )}
                    </form>
               </>
          )
     }
}

export { ChangeEmail }
