import React from "react"
import { useAuth } from "../../../../auth"

const InfoPersoViewOnlyEspaceResponsableFournisseur = () => {
     const { currentUser } = useAuth()

     return (
          <>
               {/*Civilité*/}
               <div className="row mb-7">
                    <label className="col-lg-4 fw-bold text-muted">Civilité {!currentUser?.user?.civilite && <i className={"fas fa-exclamation-circle text-warning"} />}</label>

                    <div className="col-lg-8">
                         <span className="fw-bolder fs-6 text-dark">{currentUser?.user?.civiliteLabel}</span>
                    </div>
               </div>

               {/*Nom & prénom*/}
               <div className="row mb-7">
                    <label className="col-lg-4 fw-bold text-muted">Prénom NOM</label>

                    <div className="col-lg-8">
                         <span className="fw-bolder fs-6 text-dark">
                              {currentUser?.user?.prenom} {currentUser?.user?.nom}
                         </span>
                    </div>
               </div>

               {/*Email*/}
               <div className="row mb-7">
                    <label className="col-lg-4 fw-bold text-muted">Email</label>

                    <div className="col-lg-8">
                         <span className="fw-bolder fs-6 text-dark me-2">{currentUser?.user?.username}</span>
                         <i className={"fas fa-check-circle text-success"} />
                    </div>
               </div>

               {/*Tél*/}
               <div className="row">
                    <label className="col-lg-4 fw-bold text-muted">
                         Téléphone {(!currentUser?.user?.telIndicatif || !currentUser?.user?.tel) && <i className={"fas fa-exclamation-circle text-warning"} />}
                    </label>

                    <div className="col-lg-8">
                         <span className="fw-bolder fs-6 text-dark">
                              {currentUser?.user?.telIndicatif?.phoneCodeWithPlusPrefix} {currentUser?.user?.tel}
                         </span>
                    </div>
               </div>
          </>
     )
}

export default InfoPersoViewOnlyEspaceResponsableFournisseur
