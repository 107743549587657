import { ConsultantDeclarationMensuelleModel } from "../../../../models"
import {
     CONST_CONSULTANT_DECLARATION_SUIVI_TYPE_INTERESSE_DEMANDE_REGUL,
     CONST_CONSULTANT_DECLARATION_SUIVI_TYPE_INTERESSE_FINALISATION,
     CONST_CONSULTANT_DECLARATION_SUIVI_TYPE_INTERESSE_RECTIFICATION_SANS_DEMANDE,
     CONST_CONSULTANT_DECLARATION_SUIVI_TYPE_INTERESSE_SIGNATURE,
     CONST_CONSULTANT_DECLARATION_SUIVI_TYPE_RESP_DEMANDE_RECTIFICATION,
     CONST_CONSULTANT_DECLARATION_SUIVI_TYPE_RESP_REJET_REGUL,
     CONST_CONSULTANT_DECLARATION_SUIVI_TYPE_RESP_VALIDATION,
     CONST_CONSULTANT_DECLARATION_SUIVI_TYPE_RESP_VALIDATION_REGUL,
     CONST_CONSULTANT_DECLARATION_SUIVI_TYPE_SIEGE_DEMANDE_RECTIFICATION,
     CONST_CONSULTANT_DECLARATION_SUIVI_TYPE_SIEGE_VALIDATION,
} from "../../../../constants"

const useDeclarationsList = () => {
     const EtatBadge = ({ declaration }: { declaration: ConsultantDeclarationMensuelleModel | null }) => {
          if (declaration?.etat) {
               if (
                    [CONST_CONSULTANT_DECLARATION_SUIVI_TYPE_INTERESSE_FINALISATION, CONST_CONSULTANT_DECLARATION_SUIVI_TYPE_INTERESSE_RECTIFICATION_SANS_DEMANDE].includes(
                         declaration.etat?.type as string
                    )
               ) {
                    return <span className={"badge badge-danger not-concerned-by-preview"}>Non envoyé</span>
               }

               if (
                    [
                         CONST_CONSULTANT_DECLARATION_SUIVI_TYPE_INTERESSE_SIGNATURE,
                         CONST_CONSULTANT_DECLARATION_SUIVI_TYPE_SIEGE_DEMANDE_RECTIFICATION,
                         CONST_CONSULTANT_DECLARATION_SUIVI_TYPE_INTERESSE_DEMANDE_REGUL,
                         CONST_CONSULTANT_DECLARATION_SUIVI_TYPE_RESP_VALIDATION,
                    ].includes(declaration.etat?.type as string)
               ) {
                    return <span className={"badge badge-success not-concerned-by-preview"}>Envoyé</span>
               }

               if (
                    [CONST_CONSULTANT_DECLARATION_SUIVI_TYPE_RESP_DEMANDE_RECTIFICATION, CONST_CONSULTANT_DECLARATION_SUIVI_TYPE_RESP_VALIDATION_REGUL].includes(
                         declaration.etat?.type as string
                    )
               ) {
                    return <span className={"badge badge-warning text-black not-concerned-by-preview"}>À rectifier</span>
               }

               if (
                    [CONST_CONSULTANT_DECLARATION_SUIVI_TYPE_SIEGE_VALIDATION, CONST_CONSULTANT_DECLARATION_SUIVI_TYPE_RESP_REJET_REGUL].includes(declaration.etat?.type as string)
               ) {
                    return <span className={"badge badge-success not-concerned-by-preview"}>Validé</span>
               }
          }

          return <span className={"badge badge-danger not-concerned-by-preview"}>Non envoyé</span>
     }

     return { EtatBadge }
}

export default useDeclarationsList
