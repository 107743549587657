import { CONST_USER_ROLE_CANDIDAT, CONST_USER_ROLE_CANDIDAT_EN_COURS_EMBAUCHE, CONST_USER_ROLE_CONSULTANT, CONST_USER_ROLE_RESPONSABLE_FOURNISSEUR } from "../../../../../constants"
import { CompteInfoPersoPreviewCandidat } from "./CompteInfoPersoPreviewCandidat"
import { CompteInfoPersoPreviewPreembaucheFreelance } from "./CompteInfoPersoPreviewPreembaucheFreelance"
import { InfoPersoViewOnlyEspacePreemabaucheSalarie } from "./InfoPersoViewOnlyEspacePreemabaucheSalarie"
import { InfoPersoViewOnlyEspaceConsultant } from "./infoPersoViewOnlyEspaceConsultant/InfoPersoViewOnlyEspaceConsultant"
import React from "react"
import { useAuth } from "../../../../auth"
import InfoPersoViewOnlyEspaceResponsableFournisseur from "./InfoPersoViewOnlyEspaceResponsableFournisseur"

const CompteInfoPersoPreview = () => {
     const { currentUser } = useAuth()

     return (
          <>
               {/* Candidat*/}
               {currentUser?.user?.roles?.includes(CONST_USER_ROLE_CANDIDAT) && <CompteInfoPersoPreviewCandidat />}

               {/* Candidat en cours de préembauche (freelance) */}
               {currentUser?.user?.roles?.includes(CONST_USER_ROLE_CANDIDAT_EN_COURS_EMBAUCHE) && currentUser?.fournisseur?.fournisseur && (
                    <CompteInfoPersoPreviewPreembaucheFreelance />
               )}

               {/* Candidat en cours de préembauche (salarié) */}
               {currentUser?.user?.roles?.includes(CONST_USER_ROLE_CANDIDAT_EN_COURS_EMBAUCHE) && !currentUser?.fournisseur?.fournisseur && (
                    <InfoPersoViewOnlyEspacePreemabaucheSalarie />
               )}

               {/* Responsable fournisseur */}
               {currentUser?.user?.roles?.includes(CONST_USER_ROLE_RESPONSABLE_FOURNISSEUR) && <InfoPersoViewOnlyEspaceResponsableFournisseur />}

               {/* Consultant */}
               {currentUser?.user?.roles?.includes(CONST_USER_ROLE_CONSULTANT) && <InfoPersoViewOnlyEspaceConsultant />}
          </>
     )
}

export default CompteInfoPersoPreview
