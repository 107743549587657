import MyAlert from "../../../../../../../utils/MyAlert"
import React, { ChangeEvent, useState } from "react"
import { useQuery } from "react-query"
import { ConsultantNdfDetailModel } from "../../../../../../../models"
import axios, { AxiosError } from "axios"
import { ndfAddDepenseSimpleSousTraitant, ndfDepensesListingRequest, ndfDetailRemove } from "../../../../core/_request"
import { toast } from "react-toastify"
import { API_URL_ESPACE_AVENTURIER } from "../../../../../../../constants"
import { getExtensionAccordingToMimetype } from "../../../../../../../helpers/helper-global"
import swal from "sweetalert2"

const NdfDetailsSousTraitant = ({ ndfId, editionMode = true, onCRUDFacturesFraisCallback }: IPropsModel) => {
     const getDepensesQuery = useQuery<ConsultantNdfDetailModel[], AxiosError>(["ndfDetails", ndfId], () => ndfDepensesListingRequest(ndfId).then(r => r.data))

     const [selectedFile, setSelectedFile] = useState<File | null>(null)
     const [isSending, setIsSending] = useState<boolean>(false)

     const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
          if (event.target.files && event.target.files.length > 0) {
               setSelectedFile(event.target.files[0])
          }
     }

     const handleDeleteDepense = (depenseId: number) => {
          swal.fire({
               icon: "warning",
               title: `Confirmez la suppression`,
               showCancelButton: true,
               confirmButtonText: "Confirmer",
               cancelButtonText: "Annuler",
               focusConfirm: true,
               showLoaderOnConfirm: true,
               preConfirm: async () => {
                    return ndfDetailRemove(depenseId)
                         .then(() => {
                              toast.success("Document supprimé.")
                              getDepensesQuery.refetch().then(() => {
                                   if (onCRUDFacturesFraisCallback) onCRUDFacturesFraisCallback()
                              })
                         })
                         .catch(e => {
                              toast.error(e?.response?.data?.detail, { autoClose: false })
                         })
               },
               allowOutsideClick: () => !swal.isLoading(),
          }).then()
     }

     const handleAddDepense = () => {
          if (selectedFile) {
               setIsSending(true)
               ndfAddDepenseSimpleSousTraitant(ndfId as number, selectedFile)
                    .then(() => {
                         getDepensesQuery.refetch().then(() => {
                              if (onCRUDFacturesFraisCallback) onCRUDFacturesFraisCallback()
                         })
                         setSelectedFile(null)
                         toast.success("Votre document a bien été envoyé")
                    })
                    .catch(e => {
                         toast.error(e.response?.data?.detail, { autoClose: false })
                    })
                    .finally(() => {
                         setIsSending(false)
                    })
          }
     }

     const handleDownloadDocDepense = (e: any, googleDriveFileId: string, mime: string) => {
          e.target.disabled = true
          toast.info("Téléchargement en cours ...", { autoClose: false })

          axios.post(
               `${API_URL_ESPACE_AVENTURIER}/download-document`,
               { reference: googleDriveFileId }, // Reference the Google Drive file ID
               { responseType: "blob" }
          )
               .then(r => {
                    const url = window.URL.createObjectURL(new Blob([r.data]))
                    const link = document.createElement("a")
                    link.href = url
                    link.setAttribute("target", "_blank")
                    link.setAttribute("download", `document.${getExtensionAccordingToMimetype(mime)}`)
                    document.body.appendChild(link)
                    link.click()

                    toast.dismiss()
                    toast.success("Votre document a été téléchargée")
               })
               .catch((e: AxiosError) => {
                    toast.dismiss()
                    toast.error(e.response?.data?.detail)
               })
               .finally(() => {
                    e.target.disabled = false
               })
     }

     return (
          <>
               {editionMode && (
                    <MyAlert type={"primary"} classNames={"mb-5"}>
                         <div>
                              Vous pouvez déposer uniquement une facture de frais associée à vos justificatifs. Merci de déposer également l’ensemble de vos justificatifs scannés
                              au format PDF (un ou plusieurs documents).
                         </div>

                         <div>
                              Si les frais sont facturés dans une facture globale avec la prestation, dans ce cas vous n'avez que la facture de prestation (incluant les factures de
                              frais et justificatifs) à déposer à l’étape suivante.
                         </div>
                    </MyAlert>
               )}

               {!getDepensesQuery.isFetching && getDepensesQuery.isError && (
                    <MyAlert type={"danger"} classNames={"mb-4"}>
                         <>
                              <span className={"me-2"}>{getDepensesQuery.error?.response?.data?.detail}</span>
                              <button className={"btn btn-sm btn-danger"} onClick={() => getDepensesQuery.refetch()}>
                                   Recharger
                              </button>
                         </>
                    </MyAlert>
               )}

               {getDepensesQuery.isFetching && (
                    <div className={"text-center p-4"}>
                         <span className="spinner-border mb-2"></span>
                         <p style={{ fontWeight: 500 }}>Chargement en cours ...</p>
                    </div>
               )}

               {editionMode && (
                    <>
                         {/* Stylized File Upload */}
                         <div className="file-upload my-4 d-flex align-items-center justify-content-center">
                              <label className="btn btn-sm btn-outline btn-outline-dark me-3 d-flex align-items-center">
                                   <i className="fas fa-upload me-2 text-dark" /> Déposer un fichier
                                   <input type="file" onChange={handleFileChange} accept=".pdf,.doc,.docx,.png,.jpg,.jpeg" style={{ display: "none" }} />
                              </label>
                         </div>
                         {selectedFile && (
                              <div className="d-flex align-items-center justify-content-center">
                                   <button className="btn btn-sm btn-success" onClick={handleAddDepense} disabled={isSending}>
                                        {isSending ? "Envoi en cours..." : "Envoyer"}
                                   </button>
                              </div>
                         )}

                         <div className="separator separator-dashed my-5" />
                    </>
               )}

               {!getDepensesQuery.isFetching && !getDepensesQuery.isError && getDepensesQuery.data && getDepensesQuery.data.length == 0 && (
                    <MyAlert type={"primary"} classNames={"mb-4"}>
                         <div>Aucun justificatif de trouvé</div>
                    </MyAlert>
               )}

               {!getDepensesQuery.isFetching && !getDepensesQuery.isError && getDepensesQuery.data && getDepensesQuery.data.length > 0 && (
                    <>
                         {getDepensesQuery.data.length === 0 ? (
                              <div className="alert alert-info mt-4">Aucune dépense disponible.</div>
                         ) : (
                              <div className="mt-4">
                                   <div className="row">
                                        {getDepensesQuery.data.map(depense => {
                                             return depense.consultantNdfDocuments?.map(doc => {
                                                  return (
                                                       <div key={doc.id} className="col-12 col-md-6 col-lg-4 mb-3">
                                                            <div className="card shadow-sm p-3 h-100">
                                                                 <div className="d-flex flex-column h-100 justify-content-between">
                                                                      <div>
                                                                           <h5 className="mb-1">Document</h5>
                                                                           <small className="text-muted">Ajoutée le {doc.createdAt?.format("DD/MM/YYYY")}</small>
                                                                      </div>
                                                                      <div className="mt-3 gap-5 d-flex">
                                                                           {editionMode && (
                                                                                <>
                                                                                     <button
                                                                                          className="btn btn-danger btn-sm d-flex align-items-center"
                                                                                          onClick={() => handleDeleteDepense(depense.id!)}
                                                                                     >
                                                                                          <span className="fas fa-trash fs-5" />{" "}
                                                                                          <span className={"d-none d-sm-block ms-2"}>Supprimer</span>
                                                                                     </button>
                                                                                </>
                                                                           )}
                                                                           <button
                                                                                className="btn btn-success btn-sm d-flex align-items-center"
                                                                                onClick={e => handleDownloadDocDepense(e, doc.googleDriveID!, doc.googleDriveMimeType!)}
                                                                           >
                                                                                <span className="fas fa-download fs-5" />{" "}
                                                                                <span className={"d-none d-sm-block ms-2"}>Télécharger</span>
                                                                           </button>
                                                                      </div>
                                                                 </div>
                                                            </div>
                                                       </div>
                                                  )
                                             })
                                        })}
                                   </div>
                              </div>
                         )}
                    </>
               )}
          </>
     )
}

interface IPropsModel {
     ndfId: number
     editionMode?: boolean
     onCRUDFacturesFraisCallback?: () => void
}

export default NdfDetailsSousTraitant
