import React, { useRef, useState } from "react"
import { useAuth } from "../../../../auth"
import { useIntl } from "react-intl"
import {
     API_GET_GOOGLE_DRIVE_IDS_DOCUMENTS_EMBAUCHE_ENDPOINT,
     API_URL_ESPACE_AVENTURIER,
     CONST_CONSULTANT_DOCUMENT_TYPE_AVENANT_CONTRAT,
     CONST_CONSULTANT_DOCUMENT_TYPE_CONTRAT,
     CONST_DEFAULT_TOAST_OPTIONS,
     CONST_FOURNISSEUR_DOCUMENT_ASSURANCE_RC_PRO,
     CONST_FOURNISSEUR_DOCUMENT_EXTRAIT_KBIS,
     CONST_FOURNISSEUR_DOCUMENT_REGULARITE_FISCALE,
     CONST_FOURNISSEUR_DOCUMENT_RIB,
     CONST_FOURNISSEUR_DOCUMENT_VIGILANCE_URSSAF,
} from "../../../../../constants"
import { ConsultantDocumentModel, ConsultantMissionModel, IHttpErrorResponseModel } from "../../../../../models"
import { toast } from "react-toastify"
import axios, { AxiosError } from "axios"
import { getExtensionAccordingToMimetype } from "../../../../../helpers/helper-global"
import MyAlert from "../../../../../utils/MyAlert"
import { useQuery } from "react-query"
import MySimpleSpinner from "../../../../../utils/MySimpleSpinner"

function DocumentsProSalarie() {
     const { currentUser, setCurrentUser } = useAuth()
     const intl = useIntl()

     const [isDownloadingDocEmbauche, setIsDownloadingDocEmbauche] = useState(false)

     const refsInputRemplacerDocument = {}
     refsInputRemplacerDocument[CONST_FOURNISSEUR_DOCUMENT_EXTRAIT_KBIS] = useRef<HTMLInputElement>(null)
     refsInputRemplacerDocument[CONST_FOURNISSEUR_DOCUMENT_ASSURANCE_RC_PRO] = useRef<HTMLInputElement>(null)
     refsInputRemplacerDocument[CONST_FOURNISSEUR_DOCUMENT_VIGILANCE_URSSAF] = useRef<HTMLInputElement>(null)
     refsInputRemplacerDocument[CONST_FOURNISSEUR_DOCUMENT_REGULARITE_FISCALE] = useRef<HTMLInputElement>(null)
     refsInputRemplacerDocument[CONST_FOURNISSEUR_DOCUMENT_RIB] = useRef<HTMLInputElement>(null)

     let document_type_contrat: ConsultantDocumentModel | null = null
     let documents_type_avenantContrat: ConsultantDocumentModel[] = []
     let documents_type_odm: ConsultantMissionModel[] = []

     currentUser?.consultant?.consultant?.undeletedAndSigneConsultantDocuments?.map(item => {
          switch (item.type) {
               case CONST_CONSULTANT_DOCUMENT_TYPE_CONTRAT:
                    document_type_contrat = item
                    break
               case CONST_CONSULTANT_DOCUMENT_TYPE_AVENANT_CONTRAT:
                    documents_type_avenantContrat.push(item)
                    break
          }
     })

     currentUser?.consultant?.consultant?.consultantMissionsAvecOrdreMissionSigne?.map(item => {
          documents_type_odm?.push(item)
     })

     const detailsQuery = useQuery<{ titre: string; googleDriveId: string; mime: string }[], AxiosError>("GET_DOCS_EMBAUCHE_SALAIRE_GOOGLE_DRIVE_IDS", async () => {
          return axios
               .get(API_GET_GOOGLE_DRIVE_IDS_DOCUMENTS_EMBAUCHE_ENDPOINT)
               .then(r => {
                    return r.data
               })
               .catch((e: AxiosError) => {
                    const error: IHttpErrorResponseModel = e.response?.data

                    toast.error(error?.detail, { autoClose: false })

                    throw e
               })
     })

     function handleDownload(e: any, reference: string, mimeType: string) {
          e.target.disabled = true

          toast.info("Téléchargement en cours ...", { ...CONST_DEFAULT_TOAST_OPTIONS, autoClose: false })
          axios.post(
               `${API_URL_ESPACE_AVENTURIER}/download-document`,
               { reference },
               {
                    responseType: "blob",
               }
          )
               .then(r => {
                    const url = window.URL.createObjectURL(new Blob([r.data]))
                    const link = document.createElement("a")
                    link.href = url
                    link.setAttribute("target", "_blank")
                    link.setAttribute("download", `document.${getExtensionAccordingToMimetype(mimeType)}`)
                    document.body.appendChild(link)
                    link.click()

                    toast.dismiss()
                    toast.success("Votre document a bien été téléchargé", CONST_DEFAULT_TOAST_OPTIONS)
               })
               .catch((e: AxiosError) => {
                    toast.dismiss()
                    toast.error(e.response?.data?.detail ? e.response.data.detail : intl.formatMessage({ id: "FORM.STATUS.UNEXPECTED_ERROR_MESSAGE" }), CONST_DEFAULT_TOAST_OPTIONS)
               })
               .finally(() => {
                    e.target.disabled = false
               })
     }

     function handleDownloadDocEmbauche(reference: string, mime: string) {
          if (isDownloadingDocEmbauche) {
               toast.warning("Veuillez patienter quelques instants avant de télécharger un autre fichier.")
               return
          }

          setIsDownloadingDocEmbauche(true)

          toast.info("Téléchargement en cours ...", { ...CONST_DEFAULT_TOAST_OPTIONS, autoClose: false })
          axios.post(
               `${API_URL_ESPACE_AVENTURIER}/download-document-embauche`,
               { reference, mime },
               {
                    responseType: "blob",
               }
          )
               .then(r => {
                    const url = window.URL.createObjectURL(new Blob([r.data]))
                    const link = document.createElement("a")
                    link.href = url
                    link.setAttribute("target", "_blank")
                    link.setAttribute("download", `document.${getExtensionAccordingToMimetype(mime)}`)
                    document.body.appendChild(link)
                    link.click()

                    toast.dismiss()
                    toast.success("Votre document a bien été téléchargé", CONST_DEFAULT_TOAST_OPTIONS)
               })
               .catch((e: AxiosError) => {
                    toast.dismiss()
                    toast.error(e.response?.data?.detail ? e.response.data.detail : intl.formatMessage({ id: "FORM.STATUS.UNEXPECTED_ERROR_MESSAGE" }), CONST_DEFAULT_TOAST_OPTIONS)
               })
               .finally(() => setIsDownloadingDocEmbauche(false))
     }

     return (
          <>
               {/* ODM */}
               <div className={"mb-10"}>
                    <h4 className={"mt-8 mb-4"}>Ordres de Mission</h4>
                    <div className="separator separator-dashed mb-6"></div>

                    {documents_type_odm.length == 0 && (
                         <MyAlert title={"Aucun document"} type={"primary"}>
                              Aucun Ordre de Mission n'a été ajouté à votre espace.
                         </MyAlert>
                    )}

                    {documents_type_odm.map((item, key) => (
                         <>
                              <div className="row mb-4">
                                   <label className="col-md-4 col-form-label fw-bold fs-6">ODM n°{key + 1}</label>
                                   <div className="col-md-8">
                                        <button className={"btn btn-sm btn-primary me-2"} onClick={e => handleDownload(e, item.googleDriveID, item.googleDriveMimeType)}>
                                             Télécharger
                                        </button>
                                   </div>
                              </div>
                         </>
                    ))}
               </div>

               {/* Contrats & avenants */}
               {currentUser?.user?.roles?.includes("ROLE_CONSULTANT") && (
                    <>
                         <h4 className={"mb-4"}>Contrat et avenants</h4>
                         <div className="separator separator-dashed mb-6" />

                         {document_type_contrat ? (
                              <div className="row mb-4">
                                   <label className="col-md-4 col-form-label fw-bold fs-6">Contrat</label>
                                   <div className="col-md-8">
                                        <button
                                             className={"btn btn-sm btn-primary me-2"}
                                             onClick={e => handleDownload(e, document_type_contrat!.googleDriveID, document_type_contrat!.googleDriveMimeType)}
                                        >
                                             Télécharger
                                        </button>
                                   </div>
                              </div>
                         ) : (
                              <MyAlert type={"primary"}>Aucun document de disponible</MyAlert>
                         )}

                         {documents_type_avenantContrat.map((item, key) => (
                              <>
                                   <div className="row mb-4">
                                        <label className="col-md-4 col-form-label fw-bold fs-6">Avenant n°{key + 1}</label>
                                        <div className="col-md-8">
                                             <button className={"btn btn-sm btn-primary me-2"} onClick={e => handleDownload(e, item.googleDriveID, item.googleDriveMimeType)}>
                                                  Télécharger
                                             </button>
                                        </div>
                                   </div>
                              </>
                         ))}
                    </>
               )}

               {/* Documents embauche */}
               <>
                    <h4 className={"mt-8 mb-4"}>Documents d'embauche</h4>
                    <div className="separator separator-dashed mb-6"></div>

                    {detailsQuery.isFetching && (
                         <div className={"text-center p-5"}>
                              <MySimpleSpinner size={"xl"} />
                         </div>
                    )}

                    {!detailsQuery.isFetching && detailsQuery.isError && (
                         <MyAlert type={"danger"} classNames={"mb-4"}>
                              <>
                                   <span className={"me-2"}>{detailsQuery.error?.response?.data?.detail}</span>
                                   <button className={"btn btn-sm btn-danger"} onClick={() => detailsQuery.refetch()}>
                                        Recharger
                                   </button>
                              </>
                         </MyAlert>
                    )}

                    {!detailsQuery.isFetching && !detailsQuery.isError && detailsQuery.data && (
                         <>
                              <>{detailsQuery.data.length === 0 && <div>Aucun documents n'est disponible</div>}</>
                              <>
                                   {detailsQuery.data.length > 0 && (
                                        <>
                                             <ul className="list-group">
                                                  {detailsQuery.data.map(item => (
                                                       <li
                                                            className="list-group-item bg-transparent bg-hover-light"
                                                            key={item.googleDriveId}
                                                            onClick={() => handleDownloadDocEmbauche(item.googleDriveId, item.mime)}
                                                       >
                                                            <i className="fas fa-file-image me-2 text-dark" />
                                                            <span className={"text-primary"}>{item.titre}</span>
                                                       </li>
                                                  ))}
                                             </ul>
                                        </>
                                   )}
                              </>
                         </>
                    )}
               </>
          </>
     )
}

export default DocumentsProSalarie
