import React, { useRef } from "react"
import { useAuth } from "../../../../auth"
import { useIntl } from "react-intl"
import {
     API_FOURNISSEUR_DELETE_DOCUMENT_BY_FOURNISSEUR_ENDPOINT,
     API_FOURNISSEUR_SET_DOCUMENT_BY_FOURNISSEUR_ENDPOINT,
     API_URL_ESPACE_AVENTURIER,
     CONST_CONSULTANT_DOCUMENT_TYPE_AVENANT_CONTRAT,
     CONST_CONSULTANT_DOCUMENT_TYPE_CONTRAT,
     CONST_DEFAULT_TOAST_OPTIONS,
     CONST_FOURNISSEUR_DOCUMENT_ASSURANCE_RC_PRO,
     CONST_FOURNISSEUR_DOCUMENT_EXTRAIT_KBIS,
     CONST_FOURNISSEUR_DOCUMENT_REGULARITE_FISCALE,
     CONST_FOURNISSEUR_DOCUMENT_RIB,
     CONST_FOURNISSEUR_DOCUMENT_VIGILANCE_URSSAF,
} from "../../../../../constants"
import { ConsultantDocumentModel, IFournisseurDocumentModel } from "../../../../../models"
import { toast } from "react-toastify"
import axios, { AxiosError } from "axios"
import { getExtensionAccordingToMimetype } from "../../../../../helpers/helper-global"
import MyAlert from "../../../../../utils/MyAlert"
import Swal from "sweetalert2"
import withReactContent from "sweetalert2-react-content"

function DocumentsPro() {
     const { currentUser, setCurrentUser } = useAuth()
     const intl = useIntl()
     const MySwal = withReactContent(Swal)

     const refsInputRemplacerDocument = {}
     refsInputRemplacerDocument[CONST_FOURNISSEUR_DOCUMENT_EXTRAIT_KBIS] = useRef<HTMLInputElement>(null)
     refsInputRemplacerDocument[CONST_FOURNISSEUR_DOCUMENT_ASSURANCE_RC_PRO] = useRef<HTMLInputElement>(null)
     refsInputRemplacerDocument[CONST_FOURNISSEUR_DOCUMENT_VIGILANCE_URSSAF] = useRef<HTMLInputElement>(null)
     refsInputRemplacerDocument[CONST_FOURNISSEUR_DOCUMENT_REGULARITE_FISCALE] = useRef<HTMLInputElement>(null)
     refsInputRemplacerDocument[CONST_FOURNISSEUR_DOCUMENT_RIB] = useRef<HTMLInputElement>(null)

     let document_type_contrat: ConsultantDocumentModel | null = null
     let documents_type_avenantContrat: ConsultantDocumentModel[] = []
     let documents_type_fournisseur_extrait_kbis: IFournisseurDocumentModel | null = null
     let documents_type_fournisseur_assurance_rc_pro: IFournisseurDocumentModel | null = null
     let documents_type_fournisseur_vigilance_urssaf: IFournisseurDocumentModel | null = null
     let documents_type_fournisseur_regularite_fiscale: IFournisseurDocumentModel | null = null
     let documents_type_fournisseur_rib: IFournisseurDocumentModel | null = null

     currentUser?.consultant?.consultant?.undeletedAndSigneConsultantDocuments?.map(item => {
          switch (item.type) {
               case CONST_CONSULTANT_DOCUMENT_TYPE_CONTRAT:
                    document_type_contrat = item
                    break
               case CONST_CONSULTANT_DOCUMENT_TYPE_AVENANT_CONTRAT:
                    documents_type_avenantContrat.push(item)
                    break
          }
     })

     currentUser?.fournisseur?.fournisseur?.undeletedDocuments?.map(item => {
          if (item.type === "TYPE_FOURNISSEUR_EXTRAIT_KBIS") documents_type_fournisseur_extrait_kbis = item
          if (item.type === "TYPE_FOURNISSEUR_ASSURANCE_RC_PRO") documents_type_fournisseur_assurance_rc_pro = item
          if (item.type === "TYPE_FOURNISSEUR_VIGILANCE_URSSAF") documents_type_fournisseur_vigilance_urssaf = item
          if (item.type === "TYPE_FOURNISSEUR_REGULARITE_FISCALE") documents_type_fournisseur_regularite_fiscale = item
          if (item.type === "TYPE_FOURNISSEUR_RIB") documents_type_fournisseur_rib = item
     })

     function handleDownload(e: any, reference: string, mimeType: string) {
          e.target.disabled = true

          toast.info("Téléchargement en cours ...", { ...CONST_DEFAULT_TOAST_OPTIONS, autoClose: false })
          axios.post(
               `${API_URL_ESPACE_AVENTURIER}/download-document`,
               { reference },
               {
                    responseType: "blob",
               }
          )
               .then(r => {
                    const url = window.URL.createObjectURL(new Blob([r.data]))
                    const link = document.createElement("a")
                    link.href = url
                    link.setAttribute("target", "_blank")
                    link.setAttribute("download", `document.${getExtensionAccordingToMimetype(mimeType)}`)
                    document.body.appendChild(link)
                    link.click()

                    toast.dismiss()
                    toast.success("Votre document a bien été téléchargé", CONST_DEFAULT_TOAST_OPTIONS)
               })
               .catch((e: AxiosError) => {
                    toast.dismiss()
                    toast.error(e.response?.data?.detail ? e.response.data.detail : intl.formatMessage({ id: "FORM.STATUS.UNEXPECTED_ERROR_MESSAGE" }), CONST_DEFAULT_TOAST_OPTIONS)
               })
               .finally(() => {
                    e.target.disabled = false
               })
     }

     function handleSetDocument(e: any, type: string) {
          const file = e.target.files[0]

          if (file) {
               e.target.disabled = true
               toast.info("Remplacement du document en cours ...", { ...CONST_DEFAULT_TOAST_OPTIONS, autoClose: false })

               const formData = new FormData()
               formData.append("file", file)
               formData.append("type", type)

               axios.post<IFournisseurDocumentModel>(API_FOURNISSEUR_SET_DOCUMENT_BY_FOURNISSEUR_ENDPOINT, formData)
                    .then(r => {
                         setCurrentUser(prev => {
                              if (prev && prev.fournisseur?.fournisseur) {
                                   const index = prev.fournisseur.fournisseur.undeletedDocuments?.findIndex(item => item.type === type)
                                   let updatedDocuments: any
                                   if (index !== -1 && index !== undefined && prev.fournisseur.fournisseur.undeletedDocuments) {
                                        prev.fournisseur.fournisseur.undeletedDocuments[index] = r.data
                                        updatedDocuments = prev.fournisseur.fournisseur.undeletedDocuments
                                   } else {
                                        updatedDocuments = [...(prev.fournisseur.fournisseur.undeletedDocuments || []), r.data]
                                   }

                                   return {
                                        ...prev,
                                        fournisseur: {
                                             ...prev.fournisseur,
                                             fournisseur: {
                                                  ...prev.fournisseur.fournisseur,
                                                  undeletedDocuments: updatedDocuments,
                                             },
                                        },
                                   }
                              }

                              return prev
                         })
                         toast.dismiss()
                         toast.success("Le document a bien été remplacé.", CONST_DEFAULT_TOAST_OPTIONS)
                    })
                    .catch((e: AxiosError) => {
                         toast.dismiss()
                         toast.error(
                              e.response?.data?.detail ? e.response.data.detail : intl.formatMessage({ id: "FORM.STATUS.UNEXPECTED_ERROR_MESSAGE" }),
                              CONST_DEFAULT_TOAST_OPTIONS
                         )
                    })
                    .finally(() => {
                         e.target.value = ""
                         e.target.disabled = false
                    })
          }
     }

     function handleDeleteDocument(id: number) {
          MySwal.fire({
               icon: "warning",
               text: `Êtes-vous sûr de vouloir supprimer le document?`,
               showCancelButton: true,
               confirmButtonText: "Oui",
               cancelButtonText: "Annuler",
               showLoaderOnConfirm: true,
               preConfirm: async () => {
                    return axios
                         .post(API_FOURNISSEUR_DELETE_DOCUMENT_BY_FOURNISSEUR_ENDPOINT, { id })
                         .then(() => {
                              setCurrentUser(prev => {
                                   if (prev && prev.fournisseur?.fournisseur) {
                                        return {
                                             ...prev,
                                             fournisseur: {
                                                  ...prev.fournisseur,
                                                  fournisseur: {
                                                       ...prev.fournisseur.fournisseur,
                                                       undeletedDocuments: prev.fournisseur.fournisseur.undeletedDocuments?.filter(item => item.id !== id) || [],
                                                  },
                                             },
                                        }
                                   }

                                   return prev
                              })
                              toast.success("Le document a bien été supprimé.", CONST_DEFAULT_TOAST_OPTIONS)
                         })
                         .catch((e: AxiosError) => {
                              toast.error(
                                   e.response?.data?.detail ? e.response.data.detail : intl.formatMessage({ id: "FORM.STATUS.UNEXPECTED_ERROR_MESSAGE" }),
                                   CONST_DEFAULT_TOAST_OPTIONS
                              )
                         })
               },
               allowOutsideClick: () => !Swal.isLoading(),
          }).then()
     }

     const FileRowFournisseurWithDownloadReplaceDeleteButton = ({ label, document, type }: { label: string; document: IFournisseurDocumentModel | null; type: string }) => {
          return (
               <>
                    <div className={`row mb-4`}>
                         <label className="col-md-4 col-form-label fw-bold fs-6">
                              <span className={"me-2"}>{label}</span>
                              {document ? <i className={"fas fa-check-circle text-success"} /> : <i className={"fas fa-exclamation-circle text-warning"} />}
                         </label>
                         <div className="col-md-8">
                              {document ? (
                                   <>
                                        <button className={"btn btn-sm btn-warning me-2 text-black"} onClick={() => refsInputRemplacerDocument[type]?.current?.click()}>
                                             Remplacer
                                        </button>
                                        <input
                                             type="file"
                                             accept={"application/pdf, image/*"}
                                             className={"d-none"}
                                             ref={refsInputRemplacerDocument[type]}
                                             onChange={e => handleSetDocument(e, document?.type as string)}
                                        />
                                        <button
                                             className={"btn btn-sm btn-primary me-2"}
                                             onClick={e => handleDownload(e, document?.googleDriveId as string, document?.googleDriveMimeType as string)}
                                        >
                                             Télécharger
                                        </button>
                                        <button className={"btn btn-sm btn-danger"} onClick={() => handleDeleteDocument(document?.id as number)}>
                                             <i className={"fas fa-trash p-0"} />
                                        </button>
                                   </>
                              ) : (
                                   <input type={"file"} className={"form-control"} onChange={e => handleSetDocument(e, type)} />
                              )}
                         </div>
                    </div>
               </>
          )
     }

     return (
          <>
               {/* Docs fournisseur */}
               <>
                    {/* Extrait KBIS */}
                    <FileRowFournisseurWithDownloadReplaceDeleteButton
                         document={documents_type_fournisseur_extrait_kbis}
                         type={CONST_FOURNISSEUR_DOCUMENT_EXTRAIT_KBIS}
                         label={"Extrait KBIS"}
                    />

                    {/* Assurance RC Pro */}
                    <FileRowFournisseurWithDownloadReplaceDeleteButton
                         document={documents_type_fournisseur_assurance_rc_pro}
                         type={CONST_FOURNISSEUR_DOCUMENT_ASSURANCE_RC_PRO}
                         label={"Assurance Responsabilité civile Pro."}
                    />

                    {/* Vigilance URSSAF */}
                    <FileRowFournisseurWithDownloadReplaceDeleteButton
                         document={documents_type_fournisseur_vigilance_urssaf}
                         type={CONST_FOURNISSEUR_DOCUMENT_VIGILANCE_URSSAF}
                         label={"Vigilance URSSAF"}
                    />

                    {/* Régularité fiscale */}
                    <FileRowFournisseurWithDownloadReplaceDeleteButton
                         document={documents_type_fournisseur_regularite_fiscale}
                         type={CONST_FOURNISSEUR_DOCUMENT_REGULARITE_FISCALE}
                         label={"Régularité fiscale"}
                    />

                    {/* RIB */}
                    <FileRowFournisseurWithDownloadReplaceDeleteButton document={documents_type_fournisseur_rib} type={CONST_FOURNISSEUR_DOCUMENT_RIB} label={"RIB Pro."} />
               </>

               {/* Contrats & avenants (for consultant & fournisseur only) */}
               {currentUser?.user?.roles?.includes("ROLE_CONSULTANT") && (
                    <>
                         <h4 className={"mb-4"}>Contrat et avenants</h4>
                         <div className="separator separator-dashed mb-6" />

                         {document_type_contrat ? (
                              <div className="row mb-4">
                                   <label className="col-md-4 col-form-label fw-bold fs-6">Contrat</label>
                                   <div className="col-md-8">
                                        <button
                                             className={"btn btn-sm btn-primary me-2"}
                                             onClick={e => handleDownload(e, document_type_contrat!.googleDriveID, document_type_contrat!.googleDriveMimeType)}
                                        >
                                             Télécharger
                                        </button>
                                   </div>
                              </div>
                         ) : (
                              <MyAlert type={"primary"}>Aucun document de disponible</MyAlert>
                         )}

                         {documents_type_avenantContrat.map((item, key) => (
                              <>
                                   <div className="row mb-4">
                                        <label className="col-md-4 col-form-label fw-bold fs-6">Avenant n°{key + 1}</label>
                                        <div className="col-md-8">
                                             <button className={"btn btn-sm btn-primary me-2"} onClick={e => handleDownload(e, item.googleDriveID, item.googleDriveMimeType)}>
                                                  Télécharger
                                             </button>
                                        </div>
                                   </div>
                              </>
                         ))}
                    </>
               )}
          </>
     )
}

export default DocumentsPro
