import React, { useContext } from "react"
import { FournisseurRespGetCollaborateurDetailsContext } from "../FournisseurRespGetCollaborateurDetails"
import { ConsultantModel } from "../../../../../models"

const Preview = () => {
     const context = useContext(FournisseurRespGetCollaborateurDetailsContext)
     const data = context.detailsQuery.data as ConsultantModel

     return (
          <>
               {/*Civilité*/}
               <div className="row mb-7">
                    <label className="col-lg-4 fw-bold text-muted">Civilité {!data.candidat?.user?.civilite && <i className={"fas fa-exclamation-circle text-warning"} />}</label>

                    <div className="col-lg-8">
                         <span className="fw-bolder fs-6 text-dark">{data.candidat?.user?.civiliteLabel}</span>
                    </div>
               </div>

               {/*Nom & prénom*/}
               <div className="row mb-7">
                    <label className="col-lg-4 fw-bold text-muted">Prénom NOM</label>

                    <div className="col-lg-8">
                         <span className="fw-bolder fs-6 text-dark">
                              {data.candidat?.user?.prenom} {data.candidat?.user?.nom}
                         </span>
                    </div>
               </div>

               {/*Email*/}
               <div className="row mb-7">
                    <label className="col-lg-4 fw-bold text-muted">
                         Email {data?.candidat?.user?.username?.includes(".merci") && <i className={"fas fa-exclamation-circle text-warning"} />}
                    </label>

                    <div className="col-lg-8">
                         {data?.candidat?.user?.username?.includes(".merci") ? (
                              <span className={"text-warning"}>Non renseigné</span>
                         ) : (
                              <>
                                   <span className="fw-bolder fs-6 text-dark me-2">{data?.candidat?.user?.username}</span>
                                   <i className={"fas fa-check-circle text-success"} />
                              </>
                         )}
                    </div>
               </div>

               {/*Tél*/}
               <div className="row">
                    <label className="col-lg-4 fw-bold text-muted">
                         Téléphone {(!data?.candidat?.user?.telIndicatif || !data.candidat?.user?.tel) && <i className={"fas fa-exclamation-circle text-warning"} />}
                    </label>

                    <div className="col-lg-8">
                         {data?.candidat?.user?.telIndicatif && data?.candidat?.user?.tel ? (
                              <a className={"fw-bolder"} href={`tel:${data?.candidat?.user?.telIndicatif?.phoneCodeWithPlusPrefix} ${data?.candidat?.user?.tel}`}>
                                   {data?.candidat?.user?.telIndicatif?.phoneCodeWithPlusPrefix} {data?.candidat?.user?.tel}
                              </a>
                         ) : (
                              <span className={"text-warning"}>Non renseigné</span>
                         )}
                    </div>
               </div>
          </>
     )
}

export default Preview
