import React from "react"
import { KTSVG, toAbsoluteUrl } from "../../../_metronic/helpers"
import { useAuth } from "../auth"
import { Link } from "react-router-dom"
import { useLocation } from "react-router"
import { getThumbnailDriveUrl } from "../../helpers/helper-global"
import MyTooltip from "../../utils/MyTooltip"
import { CONST_USER_ROLE_CANDIDAT_EN_COURS_EMBAUCHE } from "../../constants"

const CompteHeader: React.FC = () => {
     const location = useLocation()
     const { currentUser, completionInfoProfilEnPourcentage, infoPersoToBeFilled, documentsToBeFilled, infoFournisseurToBeFilled } = useAuth()

     return (
          <div className="card mb-5 mb-xl-10">
               <div className="card-body pt-9 pb-0">
                    <div className="d-flex flex-md-row flex-column text-center mb-3 justify-content-md-start justify-content-center">
                         <div className="me-md-7 mb-4">
                              <div className="symbol symbol-100px symbol-md-160px symbol-fixed position-relative">
                                   <img
                                        src={
                                             currentUser?.user?.avatarGoogleDriveId
                                                  ? getThumbnailDriveUrl(currentUser.user.avatarGoogleDriveId)
                                                  : toAbsoluteUrl("/media/avatars/blank.png")
                                        }
                                        alt="Photo de profil"
                                   />
                                   <div className="position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-white h-20px w-20px"></div>
                              </div>
                         </div>

                         <div className="flex-md-grow-1">
                              <div className="d-flex justify-content-center justify-content-md-between align-items-start flex-wrap mb-2">
                                   <div className="d-flex flex-column">
                                        <div className="d-flex justify-content-center justify-content-md-start align-items-center mb-2">
                                             <span onClick={e => e.preventDefault()} className="text-gray-800 text-hover-primary fs-2 fw-bolder me-1" style={{ cursor: "default" }}>
                                                  <div className="d-flex align-items-center">
                                                       {currentUser?.user?.prenom} {currentUser?.user?.nom}
                                                  </div>
                                             </span>
                                        </div>

                                        <div className="d-flex flex-wrap fw-bold fs-6 mb-4 pe-2">
                                             <span className="d-flex align-items-center text-gray-400 text-hover-primary mb-2">
                                                  <KTSVG path="/media/icons/duotune/communication/com011.svg" className="svg-icon-4 me-1" />
                                                  {currentUser?.user?.username}
                                             </span>
                                        </div>
                                   </div>
                              </div>

                              <div className="d-flex flex-wrap flex-stack justify-content-md-start justify-content-center">
                                   <div className="d-flex align-items-center w-200px w-sm-300px flex-column mt-md-3">
                                        <div className="d-flex justify-content-between w-100 mt-auto mb-2">
                                             <span className="fw-bold fs-6 text-gray-400">Remplissage du profil</span>
                                             <span className="fw-bolder fs-6">{completionInfoProfilEnPourcentage}%</span>
                                        </div>
                                        <div className="h-5px mx-3 w-100 bg-light mb-3">
                                             <div className="bg-success rounded h-5px" role="progressbar" style={{ width: `${completionInfoProfilEnPourcentage}%` }}></div>
                                        </div>
                                   </div>
                              </div>
                         </div>
                    </div>

                    {/* TABS */}
                    <div className="d-flex justify-content-center justify-content-md-start overflow-auto h-55px">
                         <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap">
                              {/* Info perso tab */}
                              <li className="nav-item">
                                   <Link
                                        className={
                                             `nav-link text-active-primary ` +
                                             ((location.pathname === "/mon-compte/profil" || location.pathname === "/mon-compte/profil/edit") && "active")
                                        }
                                        to="profil"
                                   >
                                        <span className={"d-none d-md-block"}>Mes informations personnelles</span>
                                        <span className={"d-md-none d-block"}>
                                             <i className={"fas fa-info-circle"} style={{ fontSize: "2rem" }}></i>
                                        </span>
                                   </Link>

                                   {currentUser?.user?.roles?.includes(CONST_USER_ROLE_CANDIDAT_EN_COURS_EMBAUCHE) && (
                                        <>
                                             {infoPersoToBeFilled.length > 0 ? (
                                                  <MyTooltip
                                                       title={
                                                            <div className={"text-center"}>
                                                                 <b>Informations à compléter</b>
                                                                 <hr />
                                                                 {infoPersoToBeFilled.map((item, key) => {
                                                                      return <div key={key}>{item}</div>
                                                                 })}
                                                            </div>
                                                       }
                                                  >
                                                       <span style={{ position: "relative", display: "flex", alignItems: "center" }}>
                                                            <i className={"fas fa-exclamation-circle text-warning"} style={{ position: "absolute", top: 7, right: 3 }} />
                                                       </span>
                                                  </MyTooltip>
                                             ) : (
                                                  <MyTooltip title={"Rien à ajouter"}>
                                                       <span style={{ position: "relative", display: "flex", alignItems: "center" }}>
                                                            <i className={"fas fa-check-circle text-success"} style={{ position: "absolute", top: 7, right: 3 }} />
                                                       </span>
                                                  </MyTooltip>
                                             )}
                                        </>
                                   )}
                              </li>

                              {/* Tab info fournisseur */}
                              {currentUser?.fournisseur?.fournisseur && (
                                   <>
                                        <li className="nav-item">
                                             <Link
                                                  className={`nav-link text-active-primary ` + (location.pathname === "/mon-compte/info-fournisseur" && "active")}
                                                  to="info-fournisseur"
                                             >
                                                  <span className={"d-none d-md-block"}>{currentUser.fournisseur.fournisseur.raisonSociale}</span>
                                                  <span className={"d-md-none d-block"}>
                                                       <i className={"fas fa-building"} style={{ fontSize: "2rem" }}></i>
                                                  </span>
                                             </Link>

                                             {currentUser?.user?.roles?.includes(CONST_USER_ROLE_CANDIDAT_EN_COURS_EMBAUCHE) && (
                                                  <>
                                                       {infoFournisseurToBeFilled.length > 0 ? (
                                                            <MyTooltip
                                                                 title={
                                                                      <div className={"text-center"}>
                                                                           <b>Informations à compléter</b>
                                                                           <hr />
                                                                           {infoFournisseurToBeFilled.map((item, key) => {
                                                                                return <div key={key}>{item}</div>
                                                                           })}
                                                                      </div>
                                                                 }
                                                            >
                                                                 <span style={{ position: "relative", display: "flex", alignItems: "center" }}>
                                                                      <i className={"fas fa-exclamation-circle text-warning"} style={{ position: "absolute", top: 7, right: 3 }} />
                                                                 </span>
                                                            </MyTooltip>
                                                       ) : (
                                                            <MyTooltip title={"Rien à ajouter"}>
                                                                 <span style={{ position: "relative", display: "flex", alignItems: "center" }}>
                                                                      <i className={"fas fa-check-circle text-success"} style={{ position: "absolute", top: 7, right: 3 }} />
                                                                 </span>
                                                            </MyTooltip>
                                                       )}
                                                  </>
                                             )}
                                        </li>
                                   </>
                              )}

                              <li className="nav-item">
                                   <Link className={`nav-link text-active-primary  ` + (location.pathname === "/mon-compte/mes-documents" && "active")} to="mes-documents">
                                        <>
                                             <span className={"d-none d-md-block"}>Documents</span>
                                             <span className={"d-md-none d-block"}>
                                                  <i className={"fas fa-file-alt"} style={{ fontSize: "2rem" }}></i>
                                             </span>
                                        </>
                                   </Link>
                                   {currentUser?.user?.roles?.includes("ROLE_CANDIDAT_EN_COURS_EMBAUCHE") && (
                                        <>
                                             {documentsToBeFilled.length > 0 ? (
                                                  <MyTooltip
                                                       title={
                                                            <div className={"text-center"}>
                                                                 <b>Documents manquants</b>
                                                                 <hr />
                                                                 {documentsToBeFilled.map((item, key) => {
                                                                      return <div key={key}>{item}</div>
                                                                 })}
                                                            </div>
                                                       }
                                                  >
                                                       <span style={{ position: "relative", display: "flex", alignItems: "center" }}>
                                                            <i className={"fas fa-exclamation-circle text-warning"} style={{ position: "absolute", top: 7, right: 3 }} />
                                                       </span>
                                                  </MyTooltip>
                                             ) : (
                                                  <MyTooltip title={"Rien à ajouter"}>
                                                       <span style={{ position: "relative", display: "flex", alignItems: "center" }}>
                                                            <i className={"fas fa-check-circle text-success"} style={{ position: "absolute", top: 7, right: 3 }} />
                                                       </span>
                                                  </MyTooltip>
                                             )}
                                        </>
                                   )}
                              </li>

                              <li className="nav-item">
                                   <Link className={`nav-link text-active-primary ` + (location.pathname === "/mon-compte/settings" && "active")} to="settings">
                                        <>
                                             <span className={"d-none d-md-block"}>Paramètres du compte</span>
                                             <span className={"d-md-none d-block"}>
                                                  <i className={"fas fa-cog"} style={{ fontSize: "2rem" }}></i>
                                             </span>
                                        </>
                                   </Link>
                              </li>
                         </ul>
                    </div>
               </div>
          </div>
     )
}

export { CompteHeader }
