import { useAuth } from "../../../../auth"
import {
     CONST_CANDIDAT_DISPONIBILITE_DATE_EXACTE,
     CONST_CANDIDAT_MOBILITE_DEPARTEMENTAL,
     CONST_CANDIDAT_MOBILITE_PAR_VILLE,
     CONST_CANDIDAT_MOBILITE_REGIONAL,
} from "../../../../../constants"
import { FirstLetterUppercase } from "../../../../../helpers/helper-global"

export function CompteInfoPersoPreviewCandidat() {
     const { currentUser } = useAuth()

     return (
          <>
               <div className="row mb-7">
                    <label className="col-lg-4 fw-bold text-muted">Civilité</label>

                    <div className="col-lg-8">
                         <span className="fw-bolder fs-6 text-dark">{currentUser?.candidat?.candidat?.civiliteLabel}</span>
                    </div>
               </div>

               <div className="row mb-7">
                    <label className="col-lg-4 fw-bold text-muted">Prénom NOM</label>

                    <div className="col-lg-8">
                         <span className="fw-bolder fs-6 text-dark">
                              {currentUser?.candidat?.candidat?.prenom} {currentUser?.candidat?.candidat?.nom}
                         </span>
                    </div>
               </div>

               <div className="row mb-7">
                    <label className="col-lg-4 fw-bold text-muted">Email</label>

                    <div className="col-lg-8">
                         <span className="fw-bolder fs-6 text-dark me-2">{currentUser?.candidat?.candidat?.email}</span>
                         <span className="badge badge-success">Vérifié</span>
                    </div>
               </div>

               <div className="row mb-7">
                    <label className="col-lg-4 fw-bold text-muted">Téléphone</label>

                    <div className="col-lg-8">
                         <span className="fw-bolder fs-6 text-dark">
                              {currentUser?.candidat?.candidat?.telIndicatif?.phoneCodeWithPlusPrefix} {currentUser?.candidat?.candidat?.tel}
                         </span>
                    </div>
               </div>

               <div className="row mb-7">
                    <label className="col-lg-4 fw-bold text-muted">Date de naissance</label>

                    <div className="col-lg-8">
                         <span className="fw-bolder fs-6 text-dark">{currentUser?.candidat?.candidat?.dateNaissance?.format("DD/MM/YYYY")}</span>
                    </div>
               </div>

               <div className="row mb-7">
                    <label className="col-lg-4 fw-bold text-muted">Pays de résidence</label>

                    <div className="col-lg-8">
                         <span className="fw-bolder fs-6 text-dark">{currentUser?.candidat?.candidat?.pays?.nomFr}</span>
                    </div>
               </div>

               <div className="separator my-10"></div>

               <div className="row mb-7">
                    <label className="col-lg-4 fw-bold text-muted">Niveau d'études</label>

                    <div className="col-lg-8">
                         <span className="fw-bolder fs-6 text-dark">{currentUser?.candidat?.candidat?.niveauEtudesLabel}</span>
                    </div>
               </div>

               <div className="row mb-7">
                    <label className="col-lg-4 fw-bold text-muted">Disponibilité</label>

                    <div className="col-lg-8 fv-row">
                         <span className="fw-bold fs-6">
                              {currentUser?.candidat?.candidat?.disponibilite === CONST_CANDIDAT_DISPONIBILITE_DATE_EXACTE &&
                                   FirstLetterUppercase(currentUser?.candidat?.candidat?.disponibiliteDate?.format("dddd Do MMM YYYY"))}
                              {currentUser?.candidat?.candidat?.disponibilite !== CONST_CANDIDAT_DISPONIBILITE_DATE_EXACTE && currentUser?.candidat?.candidat?.disponibiliteLabel}
                         </span>
                    </div>
               </div>

               <div className="row mb-7">
                    <label className="col-lg-4 fw-bold text-muted">Mobilité</label>

                    <div className="col-lg-8">
                         <span className="fw-bolder fs-6 text-dark">
                              {currentUser?.candidat?.candidat?.mobilite === CONST_CANDIDAT_MOBILITE_DEPARTEMENTAL &&
                                   currentUser?.candidat?.candidat?.departements?.map(item => item.nom).join(" - ")}
                              {currentUser?.candidat?.candidat?.mobilite === CONST_CANDIDAT_MOBILITE_REGIONAL &&
                                   currentUser?.candidat?.candidat?.regions?.map(item => item.nom).join(" - ")}
                              {currentUser?.candidat?.candidat?.mobilite === CONST_CANDIDAT_MOBILITE_PAR_VILLE &&
                                   currentUser?.candidat?.candidat?.villes?.map(item => item.nom).join(" - ")}
                              {![CONST_CANDIDAT_MOBILITE_DEPARTEMENTAL, CONST_CANDIDAT_MOBILITE_REGIONAL, CONST_CANDIDAT_MOBILITE_PAR_VILLE].includes(
                                   currentUser?.candidat?.candidat?.mobilite || ""
                              ) && currentUser?.candidat?.candidat?.mobiliteLabel}
                         </span>
                    </div>
               </div>

               <div className="row mb-7">
                    <label className="col-lg-4 fw-bold text-muted">
                         Salaire <b>NET MENSUEL</b> souhaité
                    </label>

                    <div className="col-lg-8">
                         <span className="fw-bolder fs-6 text-dark">
                              {currentUser?.candidat?.candidat?.salaireNetSouhaite && `${currentUser?.candidat.candidat.salaireNetSouhaite}€`}
                         </span>
                    </div>
               </div>

               <div className="row mb-7">
                    <label className="col-lg-4 fw-bold text-muted">Habilitation Secret Défense</label>

                    <div className="col-lg-8">
                         <span className="fw-bolder fs-6 text-dark">
                              {currentUser?.candidat?.candidat?.isHabilitableDefense === true && "Oui"}
                              {currentUser?.candidat?.candidat?.isHabilitableDefense === false && "Non"}
                         </span>
                    </div>
               </div>

               {currentUser?.candidat?.candidat?.isHabilitableDefense === true && (
                    <>
                         <div className="row mb-7">
                              <label className="col-lg-4 fw-bold text-muted">Déjà habilité Secret Défense</label>

                              <div className="col-lg-8">
                                   <span className="fw-bolder fs-6 text-dark">
                                        {currentUser?.candidat?.candidat?.isDejaHabiliteDefense === true && <>Oui</>}
                                        {currentUser?.candidat?.candidat?.isDejaHabiliteDefense === false && "Non"}
                                   </span>
                              </div>
                         </div>

                         {currentUser?.candidat?.candidat?.isDejaHabiliteDefense === true && (
                              <div className="row mb-7">
                                   <label className="col-lg-4 fw-bold text-muted">Date de fin d'habilitation</label>

                                   <div className="col-lg-8">
                                        <span className="fw-bolder fs-6 text-dark">{currentUser?.candidat?.candidat?.dateFinHabilitationDefense?.format("DD/MM/YYYY")}</span>
                                   </div>
                              </div>
                         )}
                    </>
               )}
          </>
     )
}
