import React, { useMemo, useState } from "react"
import { DdcListingFilters } from "../../core/_models"
import MyTooltip from "../../../../../utils/MyTooltip"
import { useQuery, useQueryClient } from "react-query"
import { ConsultantDdcModel, IHttpErrorResponseModel } from "../../../../../models"
import axios, { AxiosError } from "axios"
import { ddcAnnuler, ddcCreate, ddcGetListe, ddcRectifierAvantDecisionBm } from "../../core/_request"
import MyAlert from "../../../../../utils/MyAlert"
import { FirstLetterUppercase } from "../../../../../helpers/helper-global"
import Swal from "sweetalert2"
import withReactContent from "sweetalert2-react-content"
import {
     API_URL_ESPACE_AVENTURIER,
     CONST_DDC_SOUS_TYPES_FOR_SELECT2,
     CONST_DDC_SUIVI_TYPE_ACCEPTATION_DEMANDE,
     CONST_DDC_SUIVI_TYPE_ANNULATION_DEMANDE,
     CONST_DDC_SUIVI_TYPE_ENVOI_DEMANDE,
     CONST_DDC_SUIVI_TYPE_RECTIFICATION_PAR_CONSULTANT_AVANT_DECISION_BM,
     CONST_DDC_SUIVI_TYPE_REJET_DEMANDE,
     CONST_DDC_TYPE_ABSENCE_EXCEPTIONNELLE,
     CONST_DDC_TYPE_ABSENCE_RTT,
     CONST_DDC_TYPES_FOR_SELECT2,
     CONST_ENTITE_CODE_GS,
     CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR,
     CONST_SOUS_TYPE_ABSENCE_EXCEPTIONNELLE_AUTRE,
} from "../../../../../constants"
import * as Yup from "yup"
import MyModal from "../../../../../utils/MyModal"
import { ErrorMessage, Form, Formik } from "formik"
import { toast } from "react-toastify"
import MySelectField from "../../../../../utils/fields/MySelectField"
import moment from "moment"
import MyDateDayMonthYearField from "../../../../../utils/fields/MyDateDayMonthYearField"
import { useAuth } from "../../../../../modules/auth"
import { getInfoAccordingToSousCategorieAbsenceExceptionnelle } from "../../core/_helpers"
import MyCheckBoxField from "../../../../../utils/fields/MyCheckBoxField"
import MySelectCreatableField from "../../../../../utils/fields/MySelectCreatableField"
import useDateHelper from "../../../../../helpers/useDateHelper"

export function DdcListe({ filtres, editionMode = true, maxHeight = "55vh" }: { filtres?: DdcListingFilters; editionMode?: boolean; maxHeight?: string }) {
     const { currentUser } = useAuth()
     const { countWeekdaysBetweenDates } = useDateHelper()

     const queryClient = useQueryClient()
     const MySwal = withReactContent(Swal)

     const [isModalAddDdcShown, setIsModalAddDdcShown] = useState<boolean>(false)
     const [modalEditDdc, setModalEditDdc] = useState<{ isShown: boolean; ddc: ConsultantDdcModel | null }>({ isShown: false, ddc: null })

     const [modalSuivi, setModalSuivi] = useState<{ isShown: boolean; ddc: ConsultantDdcModel | null }>({
          isShown: false,
          ddc: null,
     })

     const getDdcListingQuery = useQuery<ConsultantDdcModel[], AxiosError>("ddcListe", () => ddcGetListe(filtres).then(r => r.data))

     const BadgeAccordingToEtat = ({ ddc }: { ddc: ConsultantDdcModel }) => {
          return (
               <MyTooltip title={"Voir le suivi"}>
                    <div className={"d-inline cursor-pointer"} onClick={() => setModalSuivi({ ddc: ddc, isShown: true })}>
                         {[CONST_DDC_SUIVI_TYPE_RECTIFICATION_PAR_CONSULTANT_AVANT_DECISION_BM, CONST_DDC_SUIVI_TYPE_ENVOI_DEMANDE].includes(ddc.etat.type) && (
                              <span className="badge badge-light-warning">En cours</span>
                         )}
                         {ddc.etat.type == CONST_DDC_SUIVI_TYPE_ACCEPTATION_DEMANDE && <span className="badge badge-light-success">Validé</span>}
                         {ddc.etat.type == CONST_DDC_SUIVI_TYPE_REJET_DEMANDE && <span className="badge badge-light-danger">Rejeté</span>}
                         {ddc.etat.type == CONST_DDC_SUIVI_TYPE_ANNULATION_DEMANDE && <span className="badge badge-secondary">Annulé</span>}
                    </div>
               </MyTooltip>
          )
     }

     const SuiviInModal = () => {
          function faIconAccordingToTypeSuivi(type: string) {
               if (type == CONST_DDC_SUIVI_TYPE_ENVOI_DEMANDE) return "fa-paper-plane"
               if (type == CONST_DDC_SUIVI_TYPE_RECTIFICATION_PAR_CONSULTANT_AVANT_DECISION_BM) return "fa-edit"
               if (type == CONST_DDC_SUIVI_TYPE_ACCEPTATION_DEMANDE) return "fa-thumbs-up"
               if (type == CONST_DDC_SUIVI_TYPE_REJET_DEMANDE) return "fa-thumbs-down"
               if (type == CONST_DDC_SUIVI_TYPE_ANNULATION_DEMANDE) return "fa-times"
               return ""
          }

          return (
               <MyModal title={<span>{modalSuivi.ddc!.typeLabel}</span>} show={modalSuivi.isShown} handleClose={() => setModalSuivi(prev => ({ ...prev, isShown: false }))}>
                    <div className="timeline">
                         {modalSuivi.ddc!.suivis.map((suivi, key) => (
                              <div className="timeline-item" key={key}>
                                   <div className="timeline-line w-40px"></div>
                                   <div className="timeline-icon symbol symbol-circle symbol-40px">
                                        <div className={`symbol-label text-dark`}>
                                             <span className={`fas ${faIconAccordingToTypeSuivi(suivi.type)} fs-4`}></span>
                                        </div>
                                   </div>
                                   <div className="timeline-content mb-10 mt-n1">
                                        <div className="pe-3 mb-5">
                                             <div className="fs-5 fw-bold mb-2">{suivi.typeLabel}</div>
                                             <div className="d-flex align-items-center mt-1 fs-6">
                                                  <div className="text-dark me-2 fs-7">
                                                       Le {suivi.createdAt.format("dddd DD MMMM YYYY à H[h]m")}{" "}
                                                       {suivi.personnel ? (
                                                            <b className={"text-center"}>par {suivi.personnel.prenomNom}</b>
                                                       ) : (
                                                            <b className={"text-dark"}>par vous</b>
                                                       )}
                                                  </div>
                                             </div>
                                        </div>

                                        {key == modalSuivi.ddc!.suivis.length - 1 && modalSuivi.ddc!.motif && (
                                             <div className="overflow-auto pb-5">
                                                  <p className={"m-0 mb-2 fw-bold"}>Motif</p>
                                                  <div className="d-flex align-items-center border border-dashed border-gray-300 rounded p-7">
                                                       {modalSuivi.ddc!.justificatifGoogleDriveID && (
                                                            <MyTooltip title={"Consulter le justificatif"}>
                                                                 <div
                                                                      className={"fas fa-file-alt fs-2 me-2 cursor-pointer text-center"}
                                                                      onClick={e =>
                                                                           handleViewJustificatif(
                                                                                e,
                                                                                modalSuivi.ddc!.justificatifGoogleDriveID,
                                                                                modalSuivi.ddc!.justificatifGoogleDriveMimeType
                                                                           )
                                                                      }
                                                                 />
                                                            </MyTooltip>
                                                       )}
                                                       <div>{modalSuivi.ddc!.motif}</div>
                                                  </div>
                                             </div>
                                        )}

                                        {suivi.commentaire && (
                                             <div className="overflow-auto pb-5">
                                                  <p className={"m-0 mb-2 fw-bold"}>Commentaire</p>
                                                  <div className="d-flex align-items-center border border-dashed border-gray-300 rounded p-7">
                                                       <div>{suivi.commentaire}</div>
                                                  </div>
                                             </div>
                                        )}
                                   </div>
                              </div>
                         ))}
                    </div>
               </MyModal>
          )
     }

     const FormAddDemandeCongesInModal = useMemo(() => {
          const schema = Yup.object().shape({
               du: Yup.string().required("Champ requis"),
               au: Yup.string().required("Champ requis"),
               type: Yup.string().required("Champ requis"),
               isDemiJournee: Yup.boolean().label(`"Demi journée"`).required(),
               demiJournees: Yup.array()
                    .label(`"Demi journées"`)
                    .when(["du", "au", "isDemiJournee"], {
                         is: (du: string, au: string, isDemiJournee: boolean) => {
                              const diffDays = moment(au).diff(moment(du), "days")
                              return isDemiJournee && diffDays >= 1
                         },
                         then: schema => schema.min(1).required(),
                         otherwise: schema => schema.notRequired(),
                    }),
               sousType: Yup.string().when("type", {
                    is: CONST_DDC_TYPE_ABSENCE_EXCEPTIONNELLE,
                    then: schema => schema.required("Ce champ est requis"),
               }),
               motif: Yup.string().when("type", {
                    is: CONST_DDC_TYPE_ABSENCE_EXCEPTIONNELLE,
                    then: schema => schema.required("Le motif est requis"),
               }),
               justificatif: Yup.mixed().nullable(),
          })

          return (
               <MyModal title={<span>Nouvelle demande de congés</span>} show={isModalAddDdcShown} handleClose={() => setIsModalAddDdcShown(false)} size={"lg"}>
                    <Formik
                         initialValues={{
                              du: "",
                              au: "",
                              type: "",
                              sousType: "",
                              motif: "",
                              isDemiJournee: false,
                              demiJournees: undefined,
                              justificatif: null,
                         }}
                         onSubmit={(values, { setStatus, setSubmitting, setFieldError }) => {
                              setStatus(null)
                              ddcCreate({
                                   du: moment(values.du, "DD/MM/YYYY"),
                                   au: moment(values.au, "DD/MM/YYYY"),
                                   type: values.type,
                                   sousType: values.sousType,
                                   isDemiJournee: values.isDemiJournee,
                                   demiJournees: values.demiJournees,
                                   motif: values.motif,
                                   justificatif: values.justificatif,
                              })
                                   .then(r => {
                                        queryClient.setQueryData("ddcListe", (items: ConsultantDdcModel[] | undefined) => {
                                             if (items) {
                                                  return [r.data, ...items]
                                             }
                                             return []
                                        })

                                        toast.success("Votre demande de congés a bien été soumise à validation.")
                                        toast.info(
                                             "Merci de vérifier, lors de l'étape de Compte Rendu d'Activité (CRA), si les heures de congés ainsi que celles de travail pour la période concernée par votre demande sont corrects.",
                                             {
                                                  autoClose: false,
                                             }
                                        )
                                        setSubmitting(false)
                                        setIsModalAddDdcShown(false)
                                   })
                                   .catch((e: AxiosError) => {
                                        const error: IHttpErrorResponseModel = e.response?.data

                                        // Set form errors
                                        if (error?.code === CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR && error?.errors) {
                                             for (const key in error.errors) setFieldError(key, error.errors[key])
                                        }

                                        // Set form global status and notify user using a toast
                                        setStatus(error?.detail)
                                        toast.error(error?.detail, { autoClose: false })

                                        // Stop submit loader
                                        setSubmitting(false)
                                   })
                         }}
                         validationSchema={schema}
                    >
                         {({ values, setFieldValue, isSubmitting, status, errors }) => {
                              return (
                                   <Form noValidate autoComplete="off">
                                        {status && (
                                             <MyAlert type={"danger"} classNames={"mb-4"}>
                                                  {status}
                                             </MyAlert>
                                        )}
                                        {values.type == CONST_DDC_TYPE_ABSENCE_EXCEPTIONNELLE &&
                                             values.sousType &&
                                             values.sousType != CONST_SOUS_TYPE_ABSENCE_EXCEPTIONNELLE_AUTRE && (
                                                  <>
                                                       {
                                                            <MyAlert type={"info"} classNames={"mb-4"}>
                                                                 <span>
                                                                      Vous avez le droit à{" "}
                                                                      {getInfoAccordingToSousCategorieAbsenceExceptionnelle(
                                                                           values.sousType,
                                                                           currentUser?.candidat?.candidat?.civilite || ""
                                                                      )}
                                                                 </span>
                                                            </MyAlert>
                                                       }
                                                  </>
                                             )}
                                        <div className="row pb-4">
                                             <label className="col-lg-4 col-form-label fw-bold fs-6 required">Précisez la catégorie</label>

                                             <div className="col-lg-8">
                                                  <MySelectField
                                                       options={CONST_DDC_TYPES_FOR_SELECT2.filter(
                                                            item =>
                                                                 item.value != CONST_DDC_TYPE_ABSENCE_RTT ||
                                                                 (item.value == CONST_DDC_TYPE_ABSENCE_RTT &&
                                                                      currentUser!.consultant?.consultant?.entite.code == CONST_ENTITE_CODE_GS)
                                                       )}
                                                       name={`type`}
                                                       isSearchable={false}
                                                       value={values.type}
                                                       onChange={value => setFieldValue(`type`, value)}
                                                  />
                                                  <ErrorMessage name={`type`}>{msg => <div className={"text-danger"}>{msg}</div>}</ErrorMessage>
                                             </div>
                                        </div>

                                        {values.type == CONST_DDC_TYPE_ABSENCE_EXCEPTIONNELLE && (
                                             <div className="row pb-4">
                                                  <label className="col-lg-4 col-form-label fw-bold fs-6 required">Précisez la sous catégorie</label>

                                                  <div className="col-lg-8">
                                                       <MySelectField
                                                            options={CONST_DDC_SOUS_TYPES_FOR_SELECT2}
                                                            name={`sousType`}
                                                            isSearchable={false}
                                                            value={values.sousType}
                                                            onChange={value => setFieldValue(`sousType`, value)}
                                                       />
                                                       <ErrorMessage name={`sousType`}>{msg => <div className={"text-danger"}>{msg}</div>}</ErrorMessage>
                                                  </div>
                                             </div>
                                        )}

                                        <div className="row pb-4">
                                             <label className="col-lg-4 col-form-label fw-bold fs-6 required">Saisissez la période</label>

                                             <div className="col-lg-8">
                                                  <div className="row">
                                                       <div className="col">
                                                            <MyDateDayMonthYearField
                                                                 onChange={date => setFieldValue("du", date)}
                                                                 minDate={
                                                                      filtres?.duOrAuWithThisMonthAndYear.startOf("month").format("DD/MM/YYYY") ||
                                                                      filtres?.du.startOf("month").format("DD/MM/YYYY")
                                                                 }
                                                                 maxDate={
                                                                      filtres?.duOrAuWithThisMonthAndYear.endOf("month").format("DD/MM/YYYY") ||
                                                                      filtres?.au.endOf("month").format("DD/MM/YYYY")
                                                                 }
                                                                 value={values.du}
                                                                 name={"du"}
                                                                 placeholder={"Du (inclus)"}
                                                            />
                                                            <ErrorMessage name={`du`}>{msg => <div className={"text-danger"}>{msg}</div>}</ErrorMessage>
                                                       </div>
                                                       <div className="col">
                                                            <MyDateDayMonthYearField
                                                                 onChange={date => setFieldValue("au", date)}
                                                                 value={values.au}
                                                                 minDate={values.du}
                                                                 name={"au"}
                                                                 placeholder={"Au (inclus)"}
                                                            />
                                                            <ErrorMessage name={`au`}>{msg => <div className={"text-danger"}>{msg}</div>}</ErrorMessage>
                                                       </div>
                                                  </div>
                                             </div>
                                        </div>

                                        {values.du && values.au && (
                                             <div className={"mb-4"}>
                                                  <div className="row">
                                                       <label className="col-lg-4 col-form-label fw-bold fs-6">
                                                            {moment(values.au, "DD/MM/YYYY").diff(moment(values.du, "DD/MM/YYYY"), "days") >= 1
                                                                 ? "Y a-t-il des demi-journées ?"
                                                                 : "Est-ce une demi-journée ?"}
                                                       </label>

                                                       <div className="col-lg-8 d-flex align-items-center">
                                                            <MyCheckBoxField
                                                                 name={"isDemiJournee"}
                                                                 value={values.isDemiJournee}
                                                                 isInvalid={!!errors.isDemiJournee}
                                                                 onChange={val => setFieldValue("isDemiJournee", val)}
                                                            />
                                                            {errors.isDemiJournee && <div className={"text-danger"}>{errors.isDemiJournee}</div>}
                                                       </div>
                                                  </div>

                                                  {values.isDemiJournee && moment(values.au, "DD/MM/YYYY").diff(moment(values.du, "DD/MM/YYYY"), "days") >= 1 && (
                                                       <>
                                                            <MySelectCreatableField
                                                                 name={"demiJournees"}
                                                                 options={[]}
                                                                 value={values.demiJournees}
                                                                 onChange={val => setFieldValue("demiJournees", val)}
                                                                 isInvalid={!!errors.demiJournees}
                                                                 placeholder={"Saisissez les demi journées au format jj/mm/aaaa"}
                                                                 isValidNewOption={val => {
                                                                      const isValidDateFormat = moment(val, "DD/MM/YYYY", true).isValid()
                                                                      const isWithinDateRange = moment(val, "DD/MM/YYYY").isBetween(
                                                                           moment(values.du, "DD/MM/YYYY"),
                                                                           moment(values.au, "DD/MM/YYYY"),
                                                                           undefined,
                                                                           "[]"
                                                                      )

                                                                      return isValidDateFormat && isWithinDateRange
                                                                 }}
                                                                 classNames={"mt-4"}
                                                            />
                                                            {errors.demiJournees && <div className={"text-danger"}>{errors.demiJournees}</div>}
                                                       </>
                                                  )}
                                             </div>
                                        )}

                                        {values.type == CONST_DDC_TYPE_ABSENCE_EXCEPTIONNELLE && (
                                             <>
                                                  <div className="row pb-4">
                                                       <label className="col-lg-4 col-form-label fw-bold fs-6 required">Précisez le motif</label>

                                                       <div className="col-lg-8">
                                                            <textarea
                                                                 className="form-control"
                                                                 rows={5}
                                                                 name={"motif"}
                                                                 placeholder="Précisez le motif"
                                                                 onChange={e => setFieldValue("motif", e.target.value)}
                                                            />
                                                            <ErrorMessage name={`motif`}>{msg => <div className={"text-danger"}>{msg}</div>}</ErrorMessage>
                                                       </div>
                                                  </div>
                                                  <div className="row pb-4">
                                                       <label className="col-lg-4 col-form-label fw-bold fs-6">
                                                            Justificatif <i>(optionnel)</i>
                                                       </label>

                                                       <div className="col-lg-8">
                                                            <input
                                                                 type={"file"}
                                                                 name={`justificatif`}
                                                                 onChange={e => {
                                                                      setFieldValue("justificatif", e.target.files![0])
                                                                 }}
                                                                 className={"form-control"}
                                                            />
                                                            <ErrorMessage name={`justificatif`}>{msg => <div className={"text-danger"}>{msg}</div>}</ErrorMessage>
                                                       </div>
                                                  </div>
                                             </>
                                        )}

                                        <div className="text-center pt-4 d-flex flex-row-reverse">
                                             <button className={"btn btn-primary"} type={"submit"} disabled={isSubmitting}>
                                                  {!isSubmitting && (
                                                       <>
                                                            Soumettre à validation <span className="fas fa-paper-plane align-middle ms-1"></span>
                                                       </>
                                                  )}
                                                  {isSubmitting && (
                                                       <span className="indicator-progress" style={{ display: "block" }}>
                                                            Envoi en cours ...
                                                            <span className="spinner-border spinner-border-sm align-middle ms-1"></span>
                                                       </span>
                                                  )}
                                             </button>
                                        </div>
                                   </Form>
                              )
                         }}
                    </Formik>
               </MyModal>
          )
     }, [isModalAddDdcShown])

     const FormEditDemandeCongesInModal = useMemo(() => {
          const schema = Yup.object().shape({
               du: Yup.string().required("Champ requis"),
               au: Yup.string().required("Champ requis"),
               type: Yup.string().required("Champ requis"),
               isDemiJournee: Yup.boolean().label(`"Demi journée"`).required().nullable(),
               demiJournees: Yup.array()
                    .label(`"Demi journées"`)
                    .when(["du", "au", "isDemiJournee"], {
                         is: (du: string, au: string, isDemiJournee: boolean) => {
                              const diffDays = moment(au).diff(moment(du), "days")
                              return isDemiJournee && diffDays >= 1
                         },
                         then: schema => schema.min(1).required(),
                         otherwise: schema => schema.notRequired(),
                    })
                    .nullable(),
               sousType: Yup.string()
                    .when("type", {
                         is: CONST_DDC_TYPE_ABSENCE_EXCEPTIONNELLE,
                         then: schema => schema.required("Le champ est requis"),
                    })
                    .nullable(),
               motif: Yup.string()
                    .when("type", {
                         is: CONST_DDC_TYPE_ABSENCE_EXCEPTIONNELLE,
                         then: schema => schema.required("Le motif est requis"),
                    })
                    .nullable(),
               voulezVousChangerLaPieceJointe: Yup.boolean().when("type", {
                    is: CONST_DDC_TYPE_ABSENCE_EXCEPTIONNELLE,
                    then: schema => schema.required("Choisissez une option").typeError("Choisissez une option"),
               }),
               justificatif: Yup.mixed()
                    .when("voulezVousChangerLaPieceJointe", {
                         is: true,
                         then: schema => schema.required("Le justificatif est requis").typeError("Le justificatif est requis"),
                    })
                    .nullable(),
          })

          return (
               modalEditDdc.ddc && (
                    <MyModal
                         title={<span>Édition de la {modalEditDdc.ddc?.typeLabel}</span>}
                         show={modalEditDdc.isShown}
                         handleClose={() => setModalEditDdc(prev => ({ ...prev, isShown: false }))}
                         size={"lg"}
                    >
                         <Formik
                              initialValues={{
                                   du: modalEditDdc.ddc?.du.format("DD/MM/YYYY"),
                                   au: modalEditDdc.ddc?.au.format("DD/MM/YYYY"),
                                   type: modalEditDdc.ddc!.type,
                                   sousType: modalEditDdc.ddc!.sousType,
                                   motif: modalEditDdc.ddc!.motif || "",
                                   isDemiJournee: !!(modalEditDdc.ddc.demiJournees && modalEditDdc.ddc.demiJournees.length > 0),
                                   demiJournees:
                                        modalEditDdc.ddc.demiJournees && modalEditDdc.ddc.demiJournees.length > 0
                                             ? modalEditDdc.ddc.demiJournees.map(item => item.format("DD/MM/YYYY"))
                                             : undefined,
                                   voulezVousChangerLaPieceJointe: false,
                                   justificatif: null,
                              }}
                              onSubmit={(values, { setStatus, setSubmitting, setFieldError }) => {
                                   setStatus(null)
                                   ddcRectifierAvantDecisionBm(modalEditDdc.ddc!.id, {
                                        du: moment(values.du, "DD/MM/YYYY"),
                                        au: moment(values.au, "DD/MM/YYYY"),
                                        type: values.type,
                                        sousType: values.sousType,
                                        isDemiJournee: values.isDemiJournee,
                                        demiJournees: values.demiJournees,
                                        motif: values.motif,
                                        voulezVousChangerLaPieceJointe: values.voulezVousChangerLaPieceJointe,
                                        justificatif: values.justificatif,
                                   })
                                        .then(r => {
                                             queryClient.setQueryData("ddcListe", (items: ConsultantDdcModel[] | undefined) => {
                                                  if (items) {
                                                       items[items.findIndex(item => item.id == modalEditDdc.ddc!.id)] = r.data

                                                       return items
                                                  }
                                                  return []
                                             })

                                             toast.success("Votre demande de congés a bien été éditée.")
                                             toast.info(
                                                  "Veuillez vérifier, dans votre Compte Rendu d'Activité (CRA) que les heures de congés et de travail correspondant à la période de votre demande sont exactes.",
                                                  {
                                                       autoClose: false,
                                                  }
                                             )
                                             setSubmitting(false)
                                             setModalEditDdc(prev => ({ ...prev, isShown: false }))
                                        })
                                        .catch((e: AxiosError) => {
                                             const error: IHttpErrorResponseModel = e.response?.data

                                             // Set form errors
                                             if (error?.code === CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR && error?.errors) {
                                                  for (const key in error.errors) setFieldError(key, error.errors[key])
                                             }

                                             // Set form global status and notify user using a toast
                                             setStatus(error?.detail)
                                             toast.error(error?.detail, { autoClose: false })

                                             // Stop submit loader
                                             setSubmitting(false)
                                        })

                                   // .catch(e => {
                                   //      setStatus(e.response?.data?.detail)
                                   //      setSubmitting(false)
                                   // })
                              }}
                              validationSchema={schema}
                         >
                              {({ values, setFieldValue, isSubmitting, status, errors }) => {
                                   return (
                                        <Form noValidate autoComplete="off">
                                             {status && (
                                                  <MyAlert type={"danger"} classNames={"mb-4"}>
                                                       {status}
                                                  </MyAlert>
                                             )}

                                             <div className="row pb-4">
                                                  <label className="col-lg-4 col-form-label fw-bold fs-6 required">Précisez la catégorie</label>

                                                  <div className="col-lg-8">
                                                       <MySelectField
                                                            options={CONST_DDC_TYPES_FOR_SELECT2.filter(
                                                                 item =>
                                                                      item.value != CONST_DDC_TYPE_ABSENCE_RTT ||
                                                                      (item.value == CONST_DDC_TYPE_ABSENCE_RTT &&
                                                                           currentUser!.consultant?.consultant?.entite.code == CONST_ENTITE_CODE_GS)
                                                            )}
                                                            name={`type`}
                                                            isSearchable={false}
                                                            value={values.type}
                                                            onChange={value => setFieldValue(`type`, value)}
                                                       />
                                                       <ErrorMessage name={`type`}>{msg => <div className={"text-danger"}>{msg}</div>}</ErrorMessage>
                                                  </div>
                                             </div>

                                             {values.type == CONST_DDC_TYPE_ABSENCE_EXCEPTIONNELLE && (
                                                  <div className="row pb-4">
                                                       <label className="col-lg-4 col-form-label fw-bold fs-6 required">Précisez la sous catégorie</label>

                                                       <div className="col-lg-8">
                                                            <MySelectField
                                                                 options={CONST_DDC_SOUS_TYPES_FOR_SELECT2}
                                                                 name={`sousType`}
                                                                 isSearchable={false}
                                                                 value={values.sousType}
                                                                 onChange={value => setFieldValue(`sousType`, value)}
                                                            />
                                                            <ErrorMessage name={`sousType`}>{msg => <div className={"text-danger"}>{msg}</div>}</ErrorMessage>
                                                       </div>
                                                  </div>
                                             )}

                                             <div className="row pb-4">
                                                  <label className="col-lg-4 col-form-label fw-bold fs-6 required">Saisissez la période</label>

                                                  <div className="col-lg-8">
                                                       <div className="row">
                                                            <div className="col">
                                                                 <MyDateDayMonthYearField
                                                                      onChange={date => setFieldValue("du", date)}
                                                                      minDate={
                                                                           filtres?.duOrAuWithThisMonthAndYear.startOf("month").format("DD/MM/YYYY") ||
                                                                           filtres?.du.startOf("month").format("DD/MM/YYYY")
                                                                      }
                                                                      maxDate={
                                                                           filtres?.duOrAuWithThisMonthAndYear.endOf("month").format("DD/MM/YYYY") ||
                                                                           filtres?.au.endOf("month").format("DD/MM/YYYY")
                                                                      }
                                                                      value={values.du}
                                                                      name={"du"}
                                                                      placeholder={"Du (inclus)"}
                                                                 />
                                                                 <ErrorMessage name={`du`}>{msg => <div className={"text-danger"}>{msg}</div>}</ErrorMessage>
                                                            </div>
                                                            <div className="col">
                                                                 <MyDateDayMonthYearField
                                                                      onChange={date => setFieldValue("au", date)}
                                                                      value={values.au}
                                                                      minDate={values.du}
                                                                      name={"au"}
                                                                      placeholder={"Au (inclus)"}
                                                                 />
                                                                 <ErrorMessage name={`au`}>{msg => <div className={"text-danger"}>{msg}</div>}</ErrorMessage>
                                                            </div>
                                                       </div>
                                                  </div>
                                             </div>

                                             {values.du && values.au && (
                                                  <div className={"mb-4"}>
                                                       <div className="row">
                                                            <label className="col-lg-4 col-form-label fw-bold fs-6">
                                                                 {moment(values.au, "DD/MM/YYYY").diff(moment(values.du, "DD/MM/YYYY"), "days") >= 1
                                                                      ? "Y a-t-il des demi-journées ?"
                                                                      : "Est-ce une demi-journée ?"}
                                                            </label>

                                                            <div className="col-lg-8 d-flex align-items-center">
                                                                 <MyCheckBoxField
                                                                      name={"isDemiJournee"}
                                                                      value={values.isDemiJournee}
                                                                      isInvalid={!!errors.isDemiJournee}
                                                                      onChange={val => setFieldValue("isDemiJournee", val)}
                                                                 />
                                                                 {errors.isDemiJournee && <div className={"text-danger"}>{errors.isDemiJournee}</div>}
                                                            </div>
                                                       </div>

                                                       {values.isDemiJournee && moment(values.au, "DD/MM/YYYY").diff(moment(values.du, "DD/MM/YYYY"), "days") >= 1 && (
                                                            <>
                                                                 <MySelectCreatableField
                                                                      name={"demiJournees"}
                                                                      options={[]}
                                                                      value={values.demiJournees}
                                                                      onChange={val => setFieldValue("demiJournees", val)}
                                                                      isInvalid={!!errors.demiJournees}
                                                                      placeholder={"Saisissez les demi journées au format jj/mm/aaaa"}
                                                                      isValidNewOption={val => {
                                                                           const isValidDateFormat = moment(val, "DD/MM/YYYY", true).isValid()
                                                                           const isWithinDateRange = moment(val, "DD/MM/YYYY").isBetween(
                                                                                moment(values.du, "DD/MM/YYYY"),
                                                                                moment(values.au, "DD/MM/YYYY"),
                                                                                undefined,
                                                                                "[]"
                                                                           )

                                                                           return isValidDateFormat && isWithinDateRange
                                                                      }}
                                                                      classNames={"mt-4"}
                                                                 />
                                                                 {errors.demiJournees && <div className={"text-danger"}>{errors.demiJournees}</div>}
                                                            </>
                                                       )}
                                                  </div>
                                             )}

                                             {values.type == CONST_DDC_TYPE_ABSENCE_EXCEPTIONNELLE && (
                                                  <>
                                                       <div className="row pb-4">
                                                            <label className="col-lg-4 col-form-label fw-bold fs-6 required">Précisez le motif</label>

                                                            <div className="col-lg-8">
                                                                 <textarea
                                                                      className="form-control"
                                                                      rows={5}
                                                                      name={"motif"}
                                                                      placeholder="Précisez le motif"
                                                                      value={values.motif}
                                                                      onChange={e => setFieldValue("motif", e.target.value)}
                                                                 />
                                                                 <ErrorMessage name={`motif`}>{msg => <div className={"text-danger"}>{msg}</div>}</ErrorMessage>
                                                            </div>
                                                       </div>

                                                       <div className="row pb-4">
                                                            <label className="col-lg-4 col-form-label fw-bold fs-6">Voulez-vous remplacer la pièce jointe?</label>

                                                            <div className="col-lg-8">
                                                                 <MyCheckBoxField
                                                                      name={"voulezVousChangerLaPieceJointe"}
                                                                      value={values.voulezVousChangerLaPieceJointe}
                                                                      isInvalid={false}
                                                                      onChange={val => setFieldValue("voulezVousChangerLaPieceJointe", val)}
                                                                 />
                                                                 <ErrorMessage name={`voulezVousChangerLaPieceJointe`}>
                                                                      {msg => <div className={"text-danger"}>{msg}</div>}
                                                                 </ErrorMessage>
                                                            </div>
                                                       </div>

                                                       {values.voulezVousChangerLaPieceJointe && (
                                                            <div className="row pb-4">
                                                                 <label className="col-lg-4 col-form-label fw-bold fs-6 required">Justificatif</label>

                                                                 <div className="col-lg-8">
                                                                      <input
                                                                           type={"file"}
                                                                           name={`justificatif`}
                                                                           onChange={e => {
                                                                                setFieldValue("justificatif", e.target.files![0])
                                                                           }}
                                                                           className={"form-control"}
                                                                      />
                                                                      <ErrorMessage name={`justificatif`}>{msg => <div className={"text-danger"}>{msg}</div>}</ErrorMessage>
                                                                 </div>
                                                            </div>
                                                       )}
                                                  </>
                                             )}

                                             <div className="text-center pt-4 d-flex flex-row-reverse">
                                                  <button className={"btn btn-sm btn-primary"} type={"submit"} disabled={isSubmitting}>
                                                       {!isSubmitting && <>Appliquer les modifications</>}
                                                       {isSubmitting && (
                                                            <span className="indicator-progress" style={{ display: "block" }}>
                                                                 Edition en cours ...
                                                                 <span className="spinner-border spinner-border-sm align-middle ms-1"></span>
                                                            </span>
                                                       )}
                                                  </button>
                                             </div>
                                        </Form>
                                   )
                              }}
                         </Formik>
                    </MyModal>
               )
          )
     }, [modalEditDdc])

     function handleAnnulerDdc(ddc: ConsultantDdcModel) {
          MySwal.fire({
               icon: "warning",
               title: `Êtes-vous sûr de vouloir annuler la demande couvrant du ${ddc.du.format("DD/MM/YYYY")} au ${ddc.au.format("DD/MM/YYYY")}?`,
               text: "Un mail sera envoyé au manager pour l'informer de cette annulation.",
               input: "textarea",
               inputPlaceholder: "Précisez la raison de l'annulation",
               inputAttributes: {
                    required: "true",
               },
               showCancelButton: true,
               confirmButtonText: "Oui",
               cancelButtonText: "Annuler",
               showLoaderOnConfirm: true,
               inputValidator: value => {
                    return new Promise((resolve: any) => {
                         value == "" ? resolve("Précisez la raison de l'annulation") : resolve()
                    })
               },
               preConfirm: async (raison: string) => {
                    return ddcAnnuler(ddc.id, raison)
                         .then(r => {
                              queryClient.setQueryData("ddcListe", (items: ConsultantDdcModel[] | undefined) => {
                                   if (items) {
                                        items[items.findIndex(item => item.id == ddc!.id)] = r.data

                                        return items
                                   }

                                   return []
                              })
                              toast.info("Votre demande a bien été annulée.")
                         })
                         .catch(e => {
                              toast.error(e.response.data?.detail, { autoClose: false })
                         })
               },
               allowOutsideClick: () => !Swal.isLoading(),
          }).then()
     }

     function handleViewJustificatif(e, reference: string, mimeType: string) {
          e.target.disabled = true

          toast.info("Chargement du justificatif en cours ...", { autoClose: false })
          axios.post(
               `${API_URL_ESPACE_AVENTURIER}/download-document`,
               { reference },
               {
                    responseType: "arraybuffer",
               }
          )
               .then(r => {
                    const url = window.URL.createObjectURL(new Blob([r.data], { type: mimeType }))
                    window.open(url)

                    toast.dismiss()
               })
               .catch((e: AxiosError) => {
                    toast.dismiss()
                    toast.error(e.response?.data?.detail, { autoClose: false })
               })
               .finally(() => {
                    e.target.disabled = false
               })
     }

     return (
          <>
               {editionMode && (
                    <div className={"mb-7 d-flex justify-content-sm-end justify-content-center"}>
                         <button className={`btn btn-light-primary btn-sm me-2`} onClick={() => getDdcListingQuery.refetch()}>
                              <i className={"fas fa-sync me-md-2 pe-0 fs-3"} />
                              <span className={`d-md-inline d-none`}>Recharger la liste</span>
                         </button>
                         <button className={`btn btn-primary btn-sm`} onClick={() => setIsModalAddDdcShown(true)}>
                              Nouvelle demande <span className={"fas fa-plus-circle fs-2 ms-3 align-middle"} />
                         </button>
                    </div>
               )}

               {/* loader */}
               {getDdcListingQuery.isFetching && (
                    <div className={"text-center p-4"}>
                         <span className="spinner-border mb-2"></span>
                         <p style={{ fontWeight: 500 }}>Chargement en cours ...</p>
                    </div>
               )}

               {/* error */}
               {!getDdcListingQuery.isFetching && getDdcListingQuery.isError && (
                    <MyAlert type={"danger"} classNames={"mb-4"}>
                         <>
                              <span className={"me-2"}>{getDdcListingQuery.error?.response?.data?.detail}</span>
                              <button className={"btn btn-sm btn-danger"} onClick={() => getDdcListingQuery.refetch()}>
                                   Recharger
                              </button>
                         </>
                    </MyAlert>
               )}

               {/* no data found */}
               {!getDdcListingQuery.isFetching && !getDdcListingQuery.isError && getDdcListingQuery.data?.length == 0 && (
                    <div className={"text-center p-4"}>
                         <p style={{ fontWeight: 500 }}>Aucune DDC de disponible ...</p>
                    </div>
               )}

               {/* with DDC*/}
               {!getDdcListingQuery.isFetching && !getDdcListingQuery.isError && getDdcListingQuery.data && getDdcListingQuery.data.length > 0 && (
                    <>
                         <div className="row mb-3" style={{ maxHeight, overflow: "scroll" }}>
                              {getDdcListingQuery.data.map((item, key) => {
                                   const totalDemiJournees = item.demiJournees?.length || 0
                                   const totalJourneesCompletes = countWeekdaysBetweenDates(item.du.parseZone(), item.au.parseZone())
                                   const dureeAbsenceEnJours = totalJourneesCompletes - totalDemiJournees * 0.5

                                   return (
                                        <div className={`col-md-6 g-5`} key={key}>
                                             <div className="d-flex align-items-center">
                                                  <div className={"w-65px text-center"}>
                                                       <BadgeAccordingToEtat ddc={item} />
                                                       {item.justificatifGoogleDriveID && (
                                                            <MyTooltip title={"Voir le justificatif"}>
                                                                 <div
                                                                      className="text-center mt-4 cursor-pointer"
                                                                      onClick={e => handleViewJustificatif(e, item.justificatifGoogleDriveID, item.justificatifGoogleDriveMimeType)}
                                                                 >
                                                                      <i className={"fas fa-file-alt fs-1"} />
                                                                 </div>
                                                            </MyTooltip>
                                                       )}
                                                  </div>
                                                  <div className={"border-start ps-3 ms-2"}>
                                                       <span className={"text-primary fw-bolder d-block py-2 fs-5"}>{item.typeLabel}</span>
                                                       <span className="d-block fw-bold">
                                                            <MyTooltip
                                                                 title={
                                                                      <>
                                                                           <span>
                                                                                <div className={"fw-bold text-dark"}>Période</div>
                                                                                <div>Du {FirstLetterUppercase(item.du?.parseZone().format("dddd D MMMM YYYY"))} </div>

                                                                                <div>au {FirstLetterUppercase(item.au?.parseZone().format("dddd D MMMM YYYY"))}</div>
                                                                                {item.demiJournees && item.demiJournees.length > 0 && (
                                                                                     <>
                                                                                          <div className={"fw-bold mt-2 text-dark"}>
                                                                                               Dont {item.demiJournees.length} demi journée{item.demiJournees.length > 1 && "s"}
                                                                                          </div>
                                                                                          {item.demiJournees.map((dj, key) => (
                                                                                               <div key={key}>
                                                                                                    {FirstLetterUppercase(dj.parseZone().format("dddd D MMMM YYYY"))}
                                                                                               </div>
                                                                                          ))}
                                                                                     </>
                                                                                )}
                                                                           </span>
                                                                      </>
                                                                 }
                                                            >
                                                                 <span className="me-1 mb-1 cursor-default">
                                                                      {item.du?.parseZone().format("DD/MM/YYYY")} - {item.au?.parseZone().format("DD/MM/YYYY")}
                                                                 </span>
                                                            </MyTooltip>
                                                       </span>
                                                       <span className={"text-gray-600"}>
                                                            Durée: {dureeAbsenceEnJours} {dureeAbsenceEnJours > 1 ? "jours" : "jour"}{" "}
                                                       </span>
                                                       {editionMode &&
                                                            [CONST_DDC_SUIVI_TYPE_ENVOI_DEMANDE, CONST_DDC_SUIVI_TYPE_RECTIFICATION_PAR_CONSULTANT_AVANT_DECISION_BM].includes(
                                                                 item.etat.type
                                                            ) && (
                                                                 <div className="d-flex text-dark fw-bold mt-4">
                                                                      <span
                                                                           className={"cursor-pointer text-hover-warning d-flex align-items-center me-4"}
                                                                           onClick={() => setModalEditDdc({ isShown: true, ddc: item })}
                                                                      >
                                                                           <i className={"fas fa-edit me-2 fs-4 text-warning"} /> Editer
                                                                      </span>
                                                                      <span
                                                                           className={"cursor-pointer text-hover-danger d-flex align-items-center"}
                                                                           onClick={() => handleAnnulerDdc(item)}
                                                                      >
                                                                           <i className={"fas fa-times-circle me-2 fs-4 text-danger"} /> Annuler
                                                                      </span>
                                                                 </div>
                                                            )}
                                                  </div>
                                             </div>
                                        </div>
                                   )
                              })}
                         </div>
                    </>
               )}

               {/* Modals: add, edit & suivi*/}
               {isModalAddDdcShown && FormAddDemandeCongesInModal}

               {modalEditDdc.isShown && FormEditDemandeCongesInModal}

               {modalSuivi.isShown && <SuiviInModal />}
          </>
     )
}

export function DdcWrapper({ editionMode = true }: { editionMode?: boolean }) {
     return (
          <div className="card">
               <div className="card-body p-5 w-xxl-1200px">
                    <DdcListe editionMode={editionMode} />
               </div>
          </div>
     )
}
