import { createContext, Dispatch, FC, SetStateAction, useContext, useEffect, useMemo, useRef, useState } from "react"
import { LayoutSplashScreen } from "../../../../_metronic/layout/core"
import { AuthModel, AuthUserModel } from "./_models"
import * as authHelper from "./AuthHelpers"
import { getUserByToken } from "./_requests"
import { WithChildren } from "../../../../_metronic/helpers"
import {
     CONST_CANDIDAT_DOCUMENT_TYPE_CV,
     CONST_CANDIDAT_DOCUMENT_TYPES_FOR_SELECT2,
     CONST_CIVILITE_NON_RENSEIGNEE,
     CONST_CONSULTANT_CONTRAT_ST,
     CONST_CONSULTANT_DOCUMENT_TYPE_ATTESTATION_SS,
     CONST_CONSULTANT_DOCUMENT_TYPE_PASSEPORT_CNI,
     CONST_CONSULTANT_DOCUMENT_TYPE_PERMIS_CONDUIRE,
     CONST_CONSULTANT_DOCUMENT_TYPE_RIB,
     CONST_CONSULTANT_DOCUMENT_TYPE_TITRE_SEJOUR,
     CONST_CONSULTANT_DOCUMENT_TYPES_FOR_SELECT2,
     CONST_CONSULTANT_STATUT_ST_FREELANCE,
     CONST_FOURNISSEUR_DOCUMENT_ASSURANCE_RC_PRO,
     CONST_FOURNISSEUR_DOCUMENT_EXTRAIT_KBIS,
     CONST_FOURNISSEUR_DOCUMENT_REGULARITE_FISCALE,
     CONST_FOURNISSEUR_DOCUMENT_RIB,
     CONST_FOURNISSEUR_DOCUMENT_VIGILANCE_URSSAF,
     CONST_PAYS_CODE_FRANCE,
     CONST_USER_ROLE_CANDIDAT,
     CONST_USER_ROLE_CANDIDAT_EN_COURS_EMBAUCHE,
     CONST_USER_ROLE_CONSULTANT,
     CONST_USER_ROLE_RESPONSABLE_FOURNISSEUR,
} from "../../../constants"

type AuthContextProps = {
     auth: AuthModel | undefined
     saveAuth: (auth: AuthModel | undefined) => void
     currentUser: AuthUserModel | undefined
     setCurrentUser: Dispatch<SetStateAction<AuthUserModel | undefined>>
     completionInfoProfilEnPourcentage: number
     infoPersoToBeFilled: string[]
     documentsToBeFilled: string[]
     infoFournisseurToBeFilled: string[]
     logout: () => void
}

const initAuthContextPropsState = {
     auth: authHelper.getAuth(),
     saveAuth: () => {},
     currentUser: undefined,
     setCurrentUser: () => {},
     completionInfoProfilEnPourcentage: 0,
     infoPersoToBeFilled: [],
     infoFournisseurToBeFilled: [],
     documentsToBeFilled: [],
     logout: () => {},
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
     return useContext(AuthContext)
}

const AuthProvider: FC<WithChildren> = ({ children }) => {
     const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth())
     const [currentUser, setCurrentUser] = useState<AuthUserModel | undefined>()

     // % complétion info profil
     const completionInfoProfilEnPourcentage: number = useMemo(() => {
          if (!currentUser) return 0
          let totalInfoToFill: number = 100
          let totalFilled: number = 0

          // Candidat
          if (currentUser.user?.roles?.includes(CONST_USER_ROLE_CANDIDAT) && currentUser?.candidat?.candidat) {
               totalInfoToFill = 14

               if (currentUser.user?.avatarGoogleDriveId != null || currentUser.user.avatarGoogleDriveId != undefined) totalFilled++
               if (currentUser.user?.civilite != null || currentUser.user?.civilite != undefined) totalFilled++
               if (currentUser.user?.nom !== null) totalFilled++
               if (currentUser.user?.prenom !== null) totalFilled++
               if (currentUser.user?.telIndicatif?.phoneCodeWithPlusPrefix !== null) totalFilled++
               if (currentUser.user?.tel !== null) totalFilled++
               if (currentUser.candidat.candidat.dateNaissance != null || currentUser.candidat.candidat.dateNaissance != undefined) totalFilled++
               if (currentUser.candidat.candidat.pays != null || currentUser.candidat.candidat.pays != undefined) totalFilled++
               if (currentUser.candidat.candidat.niveauEtudes != null || currentUser.candidat.candidat.niveauEtudes != undefined) totalFilled++
               if (currentUser.candidat.candidat.disponibilite != null || currentUser.candidat.candidat.disponibilite != undefined) totalFilled++
               if (currentUser.candidat.candidat.mobilite != null || currentUser.candidat.candidat.mobilite != undefined) totalFilled++
               if (currentUser.candidat.candidat.salaireNetSouhaite != null || currentUser.candidat.candidat.salaireNetSouhaite != undefined) totalFilled++
               if (currentUser.candidat.candidat.isHabilitableDefense != null || currentUser.candidat.candidat.isHabilitableDefense != undefined) totalFilled++

               const docs = currentUser.candidat.candidat?.undeletedCandidatDocuments

               if (docs && docs.find(item => item.type === CONST_CANDIDAT_DOCUMENT_TYPE_CV)) totalFilled++
          }

          // Candidat en cours d'embauche / Consultant
          if (
               (currentUser.user?.roles?.includes(CONST_USER_ROLE_CANDIDAT_EN_COURS_EMBAUCHE) || currentUser.user?.roles?.includes(CONST_USER_ROLE_CONSULTANT)) &&
               currentUser?.consultant?.consultant
          ) {
               const consultant = currentUser.consultant.consultant

               totalInfoToFill = (() => {
                    let total: number
                    if (!currentUser?.fournisseur?.fournisseur) {
                         total = consultant.nationalite?.code == CONST_PAYS_CODE_FRANCE ? 19 : 20
                    } else {
                         total = 17
                    }

                    return total
               })()

               if (currentUser.user?.civilite && currentUser.user?.civilite !== CONST_CIVILITE_NON_RENSEIGNEE) totalFilled++
               if (currentUser.user?.nom) totalFilled++
               if (currentUser.user?.prenom) totalFilled++
               if (currentUser.user?.telIndicatif?.phoneCodeWithPlusPrefix && currentUser.user?.tel) totalFilled++

               if (!currentUser?.fournisseur?.fournisseur) {
                    if (consultant.numeroSecuriteSociale) totalFilled++
                    if (consultant.contactUrgenceNom) totalFilled++
                    if (consultant.contactUrgencePrenom) totalFilled++
                    if (consultant.contactUrgenceTel) totalFilled++
                    if (consultant.naissanceDate) totalFilled++
                    if (consultant.naissancePays) totalFilled++
                    if (consultant.nationalite) totalFilled++
                    if (consultant.domicileAdresse) totalFilled++
                    if (consultant.domicilePays) totalFilled++
                    if (consultant.domicileCodePostal) totalFilled++

                    const consultantDocs = currentUser?.consultant?.consultant?.undeletedAndSigneConsultantDocuments

                    if (consultant.nationalite?.code != CONST_PAYS_CODE_FRANCE && consultantDocs.find(item => item.type === CONST_CONSULTANT_DOCUMENT_TYPE_TITRE_SEJOUR))
                         totalFilled++
                    if (consultantDocs.find(item => item.type === CONST_CONSULTANT_DOCUMENT_TYPE_PASSEPORT_CNI)) totalFilled++
                    if (consultantDocs.find(item => item.type === CONST_CONSULTANT_DOCUMENT_TYPE_ATTESTATION_SS)) totalFilled++
                    if (consultantDocs.find(item => item.type === CONST_CONSULTANT_DOCUMENT_TYPE_RIB)) totalFilled++
                    if (consultantDocs.find(item => item.type === CONST_CONSULTANT_DOCUMENT_TYPE_PERMIS_CONDUIRE)) totalFilled++
               } else {
                    if (currentUser.fournisseur.fournisseur.raisonSociale) totalFilled++
                    if (currentUser.fournisseur.fournisseur.formeJuridique) totalFilled++
                    if (currentUser.fournisseur.fournisseur.siret) totalFilled++
                    if (currentUser.fournisseur.fournisseur.adresse) totalFilled++
                    if (currentUser.fournisseur.fournisseur.ville) totalFilled++
                    if (currentUser.fournisseur.fournisseur.codePostal) totalFilled++
                    if (currentUser.fournisseur.fournisseur.pays?.nomFr) totalFilled++

                    const fournisseursDocs = currentUser?.fournisseur.fournisseur.undeletedDocuments
                    if (fournisseursDocs?.find(item => item.type === CONST_FOURNISSEUR_DOCUMENT_EXTRAIT_KBIS)) totalFilled++
                    if (fournisseursDocs?.find(item => item.type === CONST_FOURNISSEUR_DOCUMENT_ASSURANCE_RC_PRO)) totalFilled++
                    if (fournisseursDocs?.find(item => item.type === CONST_FOURNISSEUR_DOCUMENT_VIGILANCE_URSSAF)) totalFilled++
                    if (fournisseursDocs?.find(item => item.type === CONST_FOURNISSEUR_DOCUMENT_REGULARITE_FISCALE)) totalFilled++
                    if (fournisseursDocs?.find(item => item.type === CONST_FOURNISSEUR_DOCUMENT_RIB)) totalFilled++
               }

               const candidatDocs = currentUser?.candidat?.candidat?.undeletedCandidatDocuments || []
               if (candidatDocs.find(item => item.type === CONST_CANDIDAT_DOCUMENT_TYPE_CV)) totalFilled++
          }

          if (currentUser.user?.roles?.includes(CONST_USER_ROLE_CONSULTANT) && currentUser?.consultant?.consultant) {
               const consultant = currentUser.consultant.consultant

               totalInfoToFill = consultant.contrat != CONST_CONSULTANT_CONTRAT_ST ? 7 : 3
               totalFilled = 0

               if (consultant.domicileAdresse != null || consultant.domicileAdresse != undefined) totalFilled++
               if (consultant.domicilePays != null || consultant.domicilePays != undefined) totalFilled++
               if (consultant.domicileCodePostal != null || consultant.domicileCodePostal != undefined) totalFilled++

               if (consultant.contrat != CONST_CONSULTANT_CONTRAT_ST) {
                    if (consultant.numeroSecuriteSociale != null || consultant.numeroSecuriteSociale != undefined) totalFilled++
                    if (consultant.contactUrgenceNom != null || consultant.contactUrgenceNom != undefined) totalFilled++
                    if (consultant.contactUrgencePrenom != null || consultant.contactUrgencePrenom != undefined) totalFilled++
                    if (consultant.contactUrgenceTel != null || consultant.contactUrgenceTel != undefined) totalFilled++
               }

               const consultantDocs = currentUser?.consultant?.consultant?.undeletedAndSigneConsultantDocuments
               const candidatDocs = currentUser.candidat?.candidat?.undeletedCandidatDocuments || []
               totalInfoToFill += (() => {
                    if (consultant.contrat != CONST_CONSULTANT_CONTRAT_ST) {
                         return consultant.nationalite?.code == CONST_PAYS_CODE_FRANCE ? 5 : 6
                    } else {
                         return 1 // Que le CV pour les ST
                    }
               })()

               if (candidatDocs.find(item => item.type === CONST_CANDIDAT_DOCUMENT_TYPE_CV)) totalFilled++

               if (consultant.contrat != CONST_CONSULTANT_CONTRAT_ST) {
                    if (consultant.nationalite?.code != CONST_PAYS_CODE_FRANCE && consultantDocs.find(item => item.type === CONST_CONSULTANT_DOCUMENT_TYPE_TITRE_SEJOUR))
                         totalFilled++
                    if (consultantDocs.find(item => item.type === CONST_CONSULTANT_DOCUMENT_TYPE_PASSEPORT_CNI)) totalFilled++
                    if (consultantDocs.find(item => item.type === CONST_CONSULTANT_DOCUMENT_TYPE_ATTESTATION_SS)) totalFilled++
                    if (consultantDocs.find(item => item.type === CONST_CONSULTANT_DOCUMENT_TYPE_RIB)) totalFilled++
                    if (consultantDocs.find(item => item.type === CONST_CONSULTANT_DOCUMENT_TYPE_PERMIS_CONDUIRE)) totalFilled++
               }
          }

          if (
               currentUser.user?.roles?.includes(CONST_USER_ROLE_RESPONSABLE_FOURNISSEUR) ||
               (currentUser.user?.roles?.includes(CONST_USER_ROLE_CONSULTANT) && currentUser?.consultant?.consultant?.statut === CONST_CONSULTANT_STATUT_ST_FREELANCE)
          ) {
               return 100
          }
          return Math.round((totalFilled / totalInfoToFill) * 100)
     }, [currentUser])

     const infoPersoToBeFilled: string[] = useMemo(() => {
          if (!currentUser) return []

          let infoMissing: string[] = []
          if (
               (currentUser?.user?.roles?.includes(CONST_USER_ROLE_CANDIDAT_EN_COURS_EMBAUCHE) || currentUser?.user?.roles?.includes(CONST_USER_ROLE_CONSULTANT)) &&
               currentUser?.consultant?.consultant
          ) {
               const consultant = currentUser?.consultant?.consultant

               if (!currentUser.user?.civilite || currentUser.user?.civilite === CONST_CIVILITE_NON_RENSEIGNEE) infoMissing.push("Civilité")
               if (!currentUser.user?.nom) infoMissing.push("Nom")
               if (!currentUser.user?.prenom) infoMissing.push("Prénom")
               if (!currentUser.user?.telIndicatif?.phoneCodeWithPlusPrefix || !currentUser.user?.tel) infoMissing.push("Tél.")

               if (!currentUser?.fournisseur?.fournisseur) {
                    if (!consultant.numeroSecuriteSociale) infoMissing.push("N° de Sécurité Sociale")
                    if (!consultant.naissanceDate || !consultant.naissancePays) infoMissing.push("Info. de naissance")
                    if (!consultant.nationalite) infoMissing.push("Nationalité")
                    if (!consultant.domicileAdresse || !consultant.domicilePays || !consultant.domicileCodePostal) infoMissing.push("Info. de résidence")
               }
          }

          return infoMissing
     }, [currentUser])

     const infoFournisseurToBeFilled: string[] = useMemo(() => {
          if (!currentUser) return []

          let infoMissing: string[] = []
          if (
               (currentUser?.user?.roles?.includes(CONST_USER_ROLE_CANDIDAT_EN_COURS_EMBAUCHE) || currentUser?.user?.roles?.includes(CONST_USER_ROLE_CONSULTANT)) &&
               currentUser?.fournisseur?.fournisseur
          ) {
               if (!currentUser.fournisseur.fournisseur.raisonSociale) infoMissing.push("Raison sociale")
               if (!currentUser.fournisseur.fournisseur.formeJuridique) infoMissing.push("Forme juridique")
               if (!currentUser.fournisseur.fournisseur.siret) infoMissing.push("SIRET")
               if (!currentUser.fournisseur.fournisseur.adresse) infoMissing.push("Adresse")
               if (!currentUser.fournisseur.fournisseur.ville) infoMissing.push("Ville")
               if (!currentUser.fournisseur.fournisseur.codePostal) infoMissing.push("Code postale")
               if (!currentUser.fournisseur.fournisseur.pays?.nomFr) infoMissing.push("Pays")
          }

          return infoMissing
     }, [currentUser])

     const documentsToBeFilled: string[] = useMemo(() => {
          if (!currentUser) return []

          let infoMissing: string[] = []

          if (!currentUser.candidat?.candidat?.undeletedCandidatDocuments?.find(item => item.type === CONST_CANDIDAT_DOCUMENT_TYPE_CV)) {
               const item = CONST_CANDIDAT_DOCUMENT_TYPES_FOR_SELECT2.find(e => e.value === CONST_CANDIDAT_DOCUMENT_TYPE_CV)
               infoMissing.push(item!.label)
          }

          if (currentUser?.user?.roles?.includes(CONST_USER_ROLE_CANDIDAT_EN_COURS_EMBAUCHE) || currentUser?.user?.roles?.includes(CONST_USER_ROLE_CONSULTANT)) {
               if (currentUser.consultant?.consultant?.contrat !== CONST_CONSULTANT_CONTRAT_ST) {
                    if (!currentUser?.consultant?.consultant?.undeletedAndSigneConsultantDocuments.find(item => item.type === CONST_CONSULTANT_DOCUMENT_TYPE_PASSEPORT_CNI)) {
                         const item = CONST_CONSULTANT_DOCUMENT_TYPES_FOR_SELECT2.find(e => e.value === CONST_CONSULTANT_DOCUMENT_TYPE_PASSEPORT_CNI)
                         infoMissing.push(item!.label)
                    }

                    if (
                         currentUser.consultant?.consultant?.nationalite?.code !== CONST_PAYS_CODE_FRANCE &&
                         !currentUser?.consultant?.consultant?.undeletedAndSigneConsultantDocuments.find(item => item.type === CONST_CONSULTANT_DOCUMENT_TYPE_TITRE_SEJOUR)
                    ) {
                         const item = CONST_CONSULTANT_DOCUMENT_TYPES_FOR_SELECT2.find(e => e.value === CONST_CONSULTANT_DOCUMENT_TYPE_TITRE_SEJOUR)
                         infoMissing.push(item!.label)
                    }

                    if (!currentUser?.consultant?.consultant?.undeletedAndSigneConsultantDocuments.find(item => item.type === CONST_CONSULTANT_DOCUMENT_TYPE_ATTESTATION_SS)) {
                         const item = CONST_CONSULTANT_DOCUMENT_TYPES_FOR_SELECT2.find(e => e.value === CONST_CONSULTANT_DOCUMENT_TYPE_ATTESTATION_SS)
                         infoMissing.push(item!.label)
                    }

                    if (!currentUser?.consultant?.consultant?.undeletedAndSigneConsultantDocuments.find(item => item.type === CONST_CONSULTANT_DOCUMENT_TYPE_RIB)) {
                         const item = CONST_CONSULTANT_DOCUMENT_TYPES_FOR_SELECT2.find(e => e.value === CONST_CONSULTANT_DOCUMENT_TYPE_RIB)
                         infoMissing.push(item!.label)
                    }

                    if (!currentUser?.consultant?.consultant?.undeletedAndSigneConsultantDocuments.find(item => item.type === CONST_CONSULTANT_DOCUMENT_TYPE_PERMIS_CONDUIRE)) {
                         const item = CONST_CONSULTANT_DOCUMENT_TYPES_FOR_SELECT2.find(e => e.value === CONST_CONSULTANT_DOCUMENT_TYPE_PERMIS_CONDUIRE)
                         infoMissing.push(item!.label)
                    }
               } else {
                    if (currentUser?.fournisseur?.fournisseur) {
                         const fournisseursDocs = currentUser?.fournisseur.fournisseur.undeletedDocuments
                         if (!fournisseursDocs?.find(item => item.type === CONST_FOURNISSEUR_DOCUMENT_EXTRAIT_KBIS)) infoMissing.push("Extrait KBIS")
                         if (!fournisseursDocs?.find(item => item.type === CONST_FOURNISSEUR_DOCUMENT_ASSURANCE_RC_PRO)) infoMissing.push("Assurance RC Pro.")
                         if (!fournisseursDocs?.find(item => item.type === CONST_FOURNISSEUR_DOCUMENT_VIGILANCE_URSSAF)) infoMissing.push("Vigilance URSSAF")
                         if (!fournisseursDocs?.find(item => item.type === CONST_FOURNISSEUR_DOCUMENT_REGULARITE_FISCALE)) infoMissing.push("Régularité fiscale")
                         if (!fournisseursDocs?.find(item => item.type === CONST_FOURNISSEUR_DOCUMENT_RIB)) infoMissing.push("RIB Pro.")
                    }
               }
          }

          return infoMissing
     }, [currentUser])

     const saveAuth = (auth: AuthModel | undefined) => {
          setAuth(auth)
          if (auth) {
               authHelper.setAuth(auth)
          } else {
               authHelper.removeAuth()
          }
     }

     const logout = () => {
          saveAuth(undefined)
          setCurrentUser(undefined)
     }

     return (
          <AuthContext.Provider
               value={{
                    auth,
                    saveAuth,
                    currentUser,
                    setCurrentUser,
                    logout,
                    completionInfoProfilEnPourcentage,
                    infoPersoToBeFilled: infoPersoToBeFilled,
                    infoFournisseurToBeFilled: infoFournisseurToBeFilled,
                    documentsToBeFilled: documentsToBeFilled,
               }}
          >
               {children}
          </AuthContext.Provider>
     )
}

const AuthInit: FC<WithChildren> = ({ children }) => {
     const { auth, logout, setCurrentUser } = useAuth()
     const didRequest = useRef(false)
     const [showSplashScreen, setShowSplashScreen] = useState(true)
     // We should request user by authToken (IN OUR EXAMPLE IT'S API_TOKEN) before rendering the application
     useEffect(() => {
          const requestUser = async (apiToken: string) => {
               try {
                    if (!didRequest.current) {
                         const { data } = await getUserByToken(apiToken)
                         if (data) {
                              setCurrentUser(data)
                         }
                    }
               } catch (error) {
                    if (!didRequest.current) {
                         logout()
                    }
               } finally {
                    setShowSplashScreen(false)
               }

               return () => (didRequest.current = true)
          }

          if (auth && auth.token) {
               requestUser(auth.token).then()
          } else {
               logout()
               setShowSplashScreen(false)
          }
          // eslint-disable-next-line
     }, [])

     return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

export { AuthProvider, AuthInit, useAuth }
