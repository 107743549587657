import { Navigate, Route, Routes, useLocation } from "react-router-dom"
import { CompteInfoPerso } from "../../modules/compte/components/infoPerso/CompteInfoPerso"
import { FC, Suspense } from "react"
import { WithChildren } from "../../../_metronic/helpers"
import { getCSSVariableValue } from "../../../_metronic/assets/ts/_utils"
import TopBarProgress from "react-topbar-progress-indicator"
import ActivitesPage from "../modules/activites/ActivitesPage"

const PrivateRoutes = () => {
     const location = useLocation()

     let prevUrl: any
     if (location.pathname.includes("auth") && localStorage.getItem("prevUrl")) {
          prevUrl = localStorage.getItem("prevUrl")
          localStorage.removeItem("prevUrl")
     }

     return (
          <Routes>
               <Route path="auth/*" element={<Navigate to={prevUrl || "/activites/declarations"} />} />

               <Route index element={<CompteInfoPerso />} />

               <Route
                    path="activites/*"
                    element={
                         <SuspensedView>
                              <ActivitesPage />
                         </SuspensedView>
                    }
               />
          </Routes>
     )
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
     const baseColor = getCSSVariableValue("--kt-primary")
     TopBarProgress.config({
          barColors: {
               "0": baseColor,
          },
          barThickness: 1,
          shadowBlur: 5,
     })
     return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PrivateRoutes }
