import { Suspense, useEffect } from "react"
import { Outlet } from "react-router-dom"
import { I18nProvider } from "../_metronic/i18n/i18nProvider"
import { LayoutProvider, LayoutSplashScreen } from "../_metronic/layout/core"
import { MasterInit } from "../_metronic/layout/MasterInit"
import { AuthInit, useAuth } from "./modules/auth"
import { toast, ToastContainer } from "react-toastify"
import {
     CONST_DEFAULT_TOAST_OPTIONS,
     CONST_USER_ROLE_CANDIDAT,
     CONST_USER_ROLE_CANDIDAT_EN_COURS_EMBAUCHE,
     CONST_USER_ROLE_CONSULTANT,
     CONST_USER_ROLE_RESPONSABLE_FOURNISSEUR,
} from "./constants"
import { AppCandidatProvider } from "./app-candidat/context/AppCandidatContext"
import { AppPreemabaucheProvider } from "./app-preembauche/context/AppPreemabaucheContext"
import { AppConsultantProvider } from "./app-consultant/context/AppConsultantContext"
import { useUpdateCheck } from "react-update-notification"

const App = () => {
     const { currentUser } = useAuth()
     const { status, reloadPage } = useUpdateCheck({
          type: "interval",
          interval: 10000,
          ignoreServerCache: true,
     })

     // This will notify user if there is a new version available
     useEffect(() => {
          if (status === "available") {
               toast.info(
                    <>
                         <h5 className={"text-primary"}>Nouvelle version disponible !</h5>
                         <div>Veuillez rafraîchir la page pour mettre à jour l'application.</div>
                         <button className={"btn btn-primary btn-sm p-0 px-2 py-1 d-flex align-items-center mt-2"} onClick={reloadPage}>
                              Rafraîchir <i className={"fas fa-sync fs-7 ms-1"} />
                         </button>
                    </>,
                    {
                         autoClose: false,
                         closeButton: false,
                         closeOnClick: false,
                         position: "top-right",
                    }
               )
          }
     }, [status])

     return (
          <Suspense fallback={<LayoutSplashScreen />}>
               <I18nProvider>
                    <LayoutProvider>
                         <AuthInit>
                              {currentUser?.user?.roles?.includes(CONST_USER_ROLE_CANDIDAT) && (
                                   <AppCandidatProvider>
                                        <Outlet />
                                        <MasterInit />
                                   </AppCandidatProvider>
                              )}

                              {currentUser?.user?.roles?.includes(CONST_USER_ROLE_CANDIDAT_EN_COURS_EMBAUCHE) && (
                                   <AppPreemabaucheProvider>
                                        <Outlet />
                                        <MasterInit />
                                   </AppPreemabaucheProvider>
                              )}

                              {currentUser?.user?.roles?.includes(CONST_USER_ROLE_CONSULTANT) && (
                                   <AppConsultantProvider>
                                        <Outlet />
                                        <MasterInit />
                                   </AppConsultantProvider>
                              )}

                              {currentUser?.user?.roles?.includes(CONST_USER_ROLE_RESPONSABLE_FOURNISSEUR) && (
                                   <>
                                        <Outlet />
                                        <MasterInit />
                                   </>
                              )}

                              {!currentUser && (
                                   <>
                                        <Outlet />
                                        <MasterInit />
                                   </>
                              )}
                         </AuthInit>
                         <ToastContainer {...CONST_DEFAULT_TOAST_OPTIONS} />
                    </LayoutProvider>
               </I18nProvider>
          </Suspense>
     )
}

export { App }
