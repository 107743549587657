import React, { useContext } from "react"
import { FournisseurRespGetCollaborateurDetailsContext } from "../FournisseurRespGetCollaborateurDetails"
import Edition from "./Edition"
import Preview from "./Preview"

const InfoBase = () => {
     const { isEditingDetails } = useContext(FournisseurRespGetCollaborateurDetailsContext)

     return isEditingDetails ? <Edition /> : <Preview />
}

export default InfoBase
