import React, { createContext, useState } from "react"
import { useQuery, useQueryClient } from "react-query"
import { IHttpErrorResponseModel } from "../../../../models"
import axios, { AxiosError } from "axios"
import { API_ESPACE_FOURNISSEUR_RESP_GET_DECLARATIONS_LISTING_ENDPOINT } from "../../../../constants"
import { toast } from "react-toastify"
import MyAlert from "../../../../utils/MyAlert"
import { useLocation } from "react-router-dom"
import { IDeclarationsListContextPropsModel, IListingItemResponseModel, IListingResponseModel } from "../core/models"
import Item from "./Item"
import MyModal from "../../../../utils/MyModal"
import Editer from "./Editer"
import moment from "moment/moment"
import { PageTitle } from "../../../../../_metronic/layout/core"

const DeclarationsList = () => {
     const queryClient = useQueryClient()
     const [selectedItemForDeclaration, setSelectedItemForDeclaration] = useState<{ item: IListingItemResponseModel; defaultStep: string } | null>(null)

     const [currentPage, setCurrentPage] = useState(1)
     const [searchTerm, setSearchTerm] = useState("") // state for search term
     const itemsPerPage = 8 // Number of items per page

     // Page states & URL parameters
     const location = useLocation() as {
          state: {
               etat?: "ETAT_RESP_FOURNISSEUR__NON_ENVOYES" | "ETAT_RESP_FOURNISSEUR__A_RECTIFIER" | "ETAT_RESP_FOURNISSEUR__ENVOYES" | "ETAT_RESP_FOURNISSEUR__FACTURE"
               mois?: string
               contrat?: "salarie" | "st"
               keyword?: string
          }
     }

     const urlParams = new URLSearchParams(window.location.search)

     // Vars
     if (!location.state?.mois && !urlParams.get("mois")) throw new Error("Param URL mois is required")

     const mois = moment((location.state.mois as string) || (urlParams.get("mois") as string))
     const etat = location.state.etat || urlParams.get("etat")

     const listingQuery = useQuery<IListingResponseModel, AxiosError>("QUERY_LISTING", async () => {
          return axios
               .post<IListingResponseModel>(API_ESPACE_FOURNISSEUR_RESP_GET_DECLARATIONS_LISTING_ENDPOINT, {
                    mois: !location.state?.mois && !urlParams.get("mois") ? "" : location.state?.mois || (urlParams.get("mois") as string),
                    etat,
               })
               .then(r => r.data)
               .catch((e: AxiosError) => {
                    const error: IHttpErrorResponseModel = e.response?.data
                    toast.error(error?.detail, { autoClose: false })
                    throw e
               })
     })

     // Function to handle page change
     const handlePageChange = (pageNumber: number) => {
          setCurrentPage(pageNumber)
     }

     function handleChangeNbJoursTravaillesCallback(nbJoursTravailles: number) {
          queryClient.setQueryData<IListingResponseModel | undefined>("QUERY_LISTING", (prev: IListingResponseModel | undefined) => {
               if (prev) {
                    const index = prev.data.findIndex(item => item.consultant.id === selectedItemForDeclaration?.item?.consultant.id)
                    if (index >= 0) {
                         prev.data[index] = {
                              ...prev.data[index],
                              nbJoursTravailles,
                         }
                         return prev
                    }
               }

               return prev
          })
     }

     async function refreshRows(consultant_ids: number[]): Promise<IListingResponseModel | undefined> {
          try {
               const { data: freshData } = await axios.post<IListingResponseModel>(API_ESPACE_FOURNISSEUR_RESP_GET_DECLARATIONS_LISTING_ENDPOINT, {
                    mois: !location.state?.mois && !urlParams.get("mois") ? "" : location.state?.mois || (urlParams.get("mois") as string),
                    consultants: consultant_ids,
               })

               queryClient.setQueryData<IListingResponseModel | undefined>("QUERY_LISTING", (oldData: IListingResponseModel | undefined) => {
                    if (oldData) {
                         // Create a shallow copy of `oldData` to ensure reactivity
                         const updatedData = { ...oldData, data: [...oldData.data] }

                         consultant_ids.forEach(id => {
                              const indexOldData = updatedData.data.findIndex(item => item.consultant.id === id)
                              const indexFreshData = freshData.data.findIndex(item => item.consultant.id === id)

                              if (indexOldData >= 0 && indexFreshData >= 0) {
                                   // Replace the old item with the new one from freshData
                                   updatedData.data[indexOldData] = {
                                        ...updatedData.data[indexOldData],
                                        ...freshData.data[indexFreshData],
                                   }
                              }
                         })

                         return updatedData // Return the new object to trigger reactivity
                    }
                    return oldData
               })

               // Return the fresh data as a resolved promise
               return freshData
          } catch (e: any) {
               toast.error(e.response.data?.detail, { autoClose: false })
               return undefined
          }
     }

     if (listingQuery.isFetching) {
          return (
               <div className={"text-center p-4"}>
                    <span className="spinner-border mb-2"></span>
                    <p style={{ fontWeight: 500 }}>Chargement en cours ...</p>
               </div>
          )
     }

     if (!listingQuery.isFetching && listingQuery.isError) {
          return (
               <MyAlert type={"danger"} classNames={"mb-4"}>
                    <>
                         {listingQuery.error.response?.data?.detail}
                         <button className={"btn btn-sm btn-danger ms-2"} onClick={() => listingQuery.refetch()}>
                              Recharger
                         </button>
                    </>
               </MyAlert>
          )
     }

     if (!listingQuery.isFetching && !listingQuery.isError && listingQuery.data?.data) {
          // Filtered data based on search term
          const filteredData = listingQuery.data.data.filter(item => {
               const fullName = `${item.declaration?.consultant?.prenomNom}`.toLowerCase()
               return fullName.includes(searchTerm.toLowerCase())
          })

          const offset = (currentPage - 1) * itemsPerPage
          const currentItems = filteredData.slice(offset, offset + itemsPerPage)
          const pageCount = Math.ceil(filteredData.length / itemsPerPage)

          return (
               <DeclarationsListContext.Provider
                    value={{
                         selectedItemForDeclaration,
                         setSelectedItemForDeclaration,
                         mois,
                         handleChangeNbJoursTravaillesCallback,
                         refreshRows,
                    }}
               >
                    <PageTitle
                         breadcrumbs={[
                              {
                                   title: "Declarations",
                                   path: "/espace-resp-fournisseur/declarations/voyants",
                              },
                              {
                                   title: mois.format("MMMM YYYY"),
                              },
                         ]}
                    >
                         liste
                    </PageTitle>

                    <div className="w-xxl-25 w-xl-30 w-md-50">
                         <input
                              type="text"
                              className="form-control mb-3"
                              autoComplete={"off"}
                              placeholder="Rechercher un collaborateur ..."
                              value={searchTerm}
                              onChange={e => setSearchTerm(e.target.value)}
                         />
                    </div>
                    <div className="table-responsive text-nowrap" style={{ overflowY: "hidden" }}>
                         <table className={`table table-rounded gy-5 gs-5 m-0 p-0`}>
                              <thead className={`bg-light border-bottom border-secondary`}>
                                   <tr className={"align-middle"}>
                                        <th>Collaborateur</th>
                                        <th className={"text-center"}>Responsable</th>
                                        <th className={"text-center"}>Statut</th>
                                        <th className={"text-center"}>Jours travaillés</th>
                                        <th className={"text-center"}>
                                             Facture de frais <br />
                                             Justificatifs
                                        </th>
                                        <th className={"text-center"}>
                                             Facture
                                             <br />
                                             de prestation
                                        </th>
                                        <td>Actions</td>
                                   </tr>
                              </thead>
                              <tbody>
                                   {listingQuery.data.data.length === 0 ? (
                                        <tr>
                                             <td colSpan={7} className={"bg-light-primary text-center"}>
                                                  Aucun résultat
                                             </td>
                                        </tr>
                                   ) : (
                                        currentItems.map((item, index) => <Item item={item} key={index} />)
                                   )}
                              </tbody>
                         </table>
                    </div>

                    {/* Pagination */}
                    {listingQuery.data.data.length > 0 && (
                         <>
                              <div className="separator mb-5" />
                              <nav>
                                   <ul className="pagination justify-content-center">
                                        <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
                                             <button className="page-link border" onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
                                                  Précédant
                                             </button>
                                        </li>
                                        {[...Array(pageCount)].map((_, index) => (
                                             <li key={index} className={`page-item ${currentPage === index + 1 ? "active" : ""}`}>
                                                  <button className="page-link" onClick={() => handlePageChange(index + 1)}>
                                                       {index + 1}
                                                  </button>
                                             </li>
                                        ))}
                                        <li className={`page-item ${currentPage === pageCount ? "disabled" : ""}`}>
                                             <button className="page-link border" onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === pageCount}>
                                                  Suivant
                                             </button>
                                        </li>
                                   </ul>
                              </nav>
                         </>
                    )}

                    {selectedItemForDeclaration?.item && (
                         <MyModal
                              title={<>{selectedItemForDeclaration.item.consultant.prenomNom}</>}
                              show={true}
                              handleClose={() => setSelectedItemForDeclaration(null)}
                              size={"xl"}
                         >
                              <Editer />
                         </MyModal>
                    )}
               </DeclarationsListContext.Provider>
          )
     } else {
          return <div>Loading...</div>
     }
}

const DeclarationsListContext = createContext<IDeclarationsListContextPropsModel>({} as IDeclarationsListContextPropsModel)

export { DeclarationsList, DeclarationsListContext }
