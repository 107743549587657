import React, { ChangeEvent, useState } from "react"
import { toast } from "react-toastify"
import axios, { AxiosError } from "axios"
import { useDeclarationUpdate } from "../DeclarationUpdate"
import {
     declarationAddFactureRequest,
     declarationDeleteFactureRequest,
     declarationGetFacturesRequest,
     declarationSetNbJoursTravaillesParStPortageOuSS,
} from "../../../../core/_request"
import MySimpleSpinner from "../../../../../../../utils/MySimpleSpinner"
import Footer from "./_Footer"
import MyAlert from "../../../../../../../utils/MyAlert"
import { useQuery } from "react-query"
import { ConsultantDeclarationMensuelleFactureModel, IHttpErrorResponseModel } from "../../../../../../../models"
import { API_URL_ESPACE_AVENTURIER, CONST_CONSULTANT_STATUT_ST_PORTAGE, CONST_CONSULTANT_STATUT_ST_SOCIETE_SERVICE } from "../../../../../../../constants"
import { getExtensionAccordingToMimetype } from "../../../../../../../helpers/helper-global"
import swal from "sweetalert2"

const DeclarationUpdateFactures = ({ isEditionModeEnabled }: { isEditionModeEnabled: boolean }) => {
     const { data, onCRUDFacturesPrestationCallback, onChangeNbJoursTravaillesCallback, setData } = useDeclarationUpdate()
     const [nbJoursTravailles, setNbJoursTravailles] = useState<string | undefined>(data.declaration?.nbJoursTravailles?.toString())

     function handleSetNbJoursTravailles(e: React.ChangeEvent<HTMLInputElement>) {
          // Allow digits, commas, and periods
          const value = e.target.value.replace(",", ".") // Replace comma with dot
          if (/^[0-9]*[.,]?[0-9]*$/.test(value)) {
               // Matches numbers with optional decimal
               setNbJoursTravailles(value)
               if (onChangeNbJoursTravaillesCallback) onChangeNbJoursTravaillesCallback(Number(value))
          }

          declarationSetNbJoursTravaillesParStPortageOuSS(
               data.declaration?.consultant?.id!,
               data.declaration?.mois!.format("MM")!,
               data.declaration?.mois!.format("YYYY")!,
               Number(value!)
          ).then(() => {
               setData(prev => ({ ...prev, declaration: { ...prev.declaration, nbJoursTravailles: Number(value!) } }))
          })
     }

     return (
          <>
               {[CONST_CONSULTANT_STATUT_ST_PORTAGE, CONST_CONSULTANT_STATUT_ST_SOCIETE_SERVICE].includes(data.declaration?.consultant?.statut!) && (
                    <>
                         <div className={"text-center"}>
                              <label htmlFor="" className={"me-2"}>
                                   Nb jours travaillés :
                              </label>
                              <input
                                   value={nbJoursTravailles}
                                   size={15}
                                   placeholder={"à renseigner"}
                                   type={"text"}
                                   onChange={handleSetNbJoursTravailles}
                                   className={"text-center"}
                                   disabled={!isEditionModeEnabled}
                              />
                         </div>

                         <div className="separator my-4" />
                    </>
               )}
               <Factures isEditionModeEnabled={isEditionModeEnabled} onCRUDFacturesPrestationCallback={onCRUDFacturesPrestationCallback} />

               <div className="separator separator-dashed my-6" />

               <Footer />
          </>
     )
}

const Factures = ({ isEditionModeEnabled, onCRUDFacturesPrestationCallback }: { isEditionModeEnabled: boolean; onCRUDFacturesPrestationCallback?: () => void }) => {
     const { data, setData } = useDeclarationUpdate()

     const [selectedFile, setSelectedFile] = useState<File | null>(null)
     const [isSending, setIsSending] = useState<boolean>(false)

     // Fetch existing factures
     const getFacturesQuery = useQuery<ConsultantDeclarationMensuelleFactureModel[], AxiosError>("GET_FACTURES_QUERY", async () => {
          return declarationGetFacturesRequest(data.declaration?.id!)
               .then(r => r.data)
               .catch((e: AxiosError) => {
                    const error: IHttpErrorResponseModel = e.response?.data
                    toast.error(error?.detail, { autoClose: false })
                    throw e
               })
     })

     const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
          if (event.target.files && event.target.files.length > 0) {
               setSelectedFile(event.target.files[0])
          }
     }

     const handleDeleteFacture = (factureId: number) => {
          swal.fire({
               icon: "warning",
               title: `Confirmez la suppression`,
               showCancelButton: true,
               confirmButtonText: "Confirmer",
               cancelButtonText: "Annuler",
               focusConfirm: true,
               showLoaderOnConfirm: true,
               preConfirm: async () => {
                    return declarationDeleteFactureRequest(data.declaration?.id!, factureId)
                         .then(() => {
                              toast.success("Document supprimé.")
                              getFacturesQuery.refetch().then(() => {
                                   if (onCRUDFacturesPrestationCallback) onCRUDFacturesPrestationCallback()
                              })
                         })
                         .catch(e => {
                              toast.error(e?.response?.data?.detail, { autoClose: false })
                         })
               },
               allowOutsideClick: () => !swal.isLoading(),
          }).then()
     }

     const handleAddFacture = () => {
          if (selectedFile) {
               setIsSending(true)
               declarationAddFactureRequest(data.declaration?.id as number, selectedFile)
                    .then(r => {
                         getFacturesQuery.refetch().then(() => {
                              if (onCRUDFacturesPrestationCallback) onCRUDFacturesPrestationCallback()
                         })
                         setSelectedFile(null)
                         toast.success("Votre document a bien été envoyé")
                    })
                    .catch(e => {
                         toast.error(e.response?.data?.detail, { autoClose: false })
                    })
                    .finally(() => {
                         setIsSending(false)
                    })
          }
     }

     const handleDownload = (e: any, googleDriveFileId: string, mime: string) => {
          e.target.disabled = true
          toast.info("Téléchargement en cours ...", { autoClose: false })

          axios.post(
               `${API_URL_ESPACE_AVENTURIER}/download-document`,
               { reference: googleDriveFileId }, // Reference the Google Drive file ID
               { responseType: "blob" }
          )
               .then(r => {
                    const url = window.URL.createObjectURL(new Blob([r.data]))
                    const link = document.createElement("a")
                    link.href = url
                    link.setAttribute("target", "_blank")
                    link.setAttribute("download", `document.${getExtensionAccordingToMimetype(mime)}`)
                    document.body.appendChild(link)
                    link.click()

                    toast.dismiss()
                    toast.success("Votre facture a été téléchargée")
               })
               .catch((e: AxiosError) => {
                    toast.dismiss()
                    toast.error(e.response?.data?.detail)
               })
               .finally(() => {
                    e.target.disabled = false
               })
     }

     return (
          <>
               {getFacturesQuery.isFetching && (
                    <div className="text-center p-5">
                         <MySimpleSpinner size={"xl"} />
                    </div>
               )}

               {!getFacturesQuery.isFetching && getFacturesQuery.isError && (
                    <MyAlert type={"danger"} classNames={"mb-4"}>
                         <>
                              <span className="me-2">{getFacturesQuery.error?.response?.data?.detail}</span>
                              <button className="btn btn-sm btn-danger" onClick={() => getFacturesQuery.refetch()}>
                                   Recharger
                              </button>
                         </>
                    </MyAlert>
               )}

               {isEditionModeEnabled && (
                    <>
                         {/* Stylized File Upload */}
                         <div className="file-upload my-4 d-flex align-items-center justify-content-center">
                              <label className="btn btn-sm btn-outline btn-outline-dark me-3 d-flex align-items-center">
                                   <i className="fas fa-upload me-2 text-dark" /> Déposer un fichier
                                   <input type="file" onChange={handleFileChange} accept=".pdf,.doc,.docx,.png,.jpg,.jpeg" style={{ display: "none" }} />
                              </label>
                         </div>
                         {selectedFile && (
                              <div className="d-flex align-items-center justify-content-center">
                                   <button className="btn btn-sm btn-success" onClick={handleAddFacture} disabled={isSending}>
                                        {isSending ? "Envoi en cours..." : "Envoyer"}
                                   </button>
                              </div>
                         )}

                         <div className="separator separator-dashed my-5" />
                    </>
               )}

               {/* Factures List */}
               {!getFacturesQuery.isFetching && !getFacturesQuery.isError && getFacturesQuery.data && (
                    <>
                         {getFacturesQuery.data.length === 0 ? (
                              <div className="alert alert-info mt-4">Aucune facture disponible.</div>
                         ) : (
                              <div className="mt-4">
                                   <div className="row">
                                        {getFacturesQuery.data.map((facture, index) => (
                                             <div key={facture.id} className="col-12 col-md-6 col-lg-4 mb-3">
                                                  <div className="card shadow-sm p-3 h-100">
                                                       <div className="d-flex flex-column h-100 justify-content-between">
                                                            <div>
                                                                 <h5 className="mb-1">Facture n°{index + 1}</h5>
                                                                 <small className="text-muted">Ajoutée le {facture.createdAt?.format("DD/MM/YYYY")}</small>
                                                            </div>
                                                            <div className="facture-actions mt-3 gap-5 d-flex">
                                                                 {isEditionModeEnabled && (
                                                                      <>
                                                                           <button
                                                                                className="btn btn-danger btn-sm d-flex align-items-center"
                                                                                onClick={() => handleDeleteFacture(facture.id!)}
                                                                           >
                                                                                <span className="fas fa-trash fs-5" /> <span className={"d-none d-sm-block ms-2"}>Supprimer</span>
                                                                           </button>
                                                                      </>
                                                                 )}
                                                                 <button
                                                                      className="btn btn-success btn-sm d-flex align-items-center"
                                                                      onClick={e => handleDownload(e, facture.googleDriveId!, facture.googleDriveMimeType!)}
                                                                 >
                                                                      <span className="fas fa-download fs-5" /> <span className={"d-none d-sm-block ms-2"}>Télécharger</span>
                                                                 </button>
                                                            </div>
                                                       </div>
                                                  </div>
                                             </div>
                                        ))}
                                   </div>
                              </div>
                         )}
                    </>
               )}
          </>
     )
}

export { DeclarationUpdateFactures, Factures }
