import { useContext } from "react"
import { FournisseurRespGetCollaborateurDetailsContext } from "../FournisseurRespGetCollaborateurDetails"
import { ConsultantModel } from "../../../../../models"
import { DeclarationList } from "../../../../../app-consultant/modules/activites/components/declaration/DeclarationList"
import { DeclarationCreate } from "../../../../../app-consultant/modules/activites/components/declaration/declarationCreate/DeclarationCreate"
import { DeclarationUpdate } from "../../../../../app-consultant/modules/activites/components/declaration/declarationUpdate/DeclarationUpdate"

const Declarations = () => {
     const { isAddingNewDeclaration, detailsQuery, setIsAddingNewDeclaration, declarationSousConsultation, setDeclarationSousConsultation } =
          useContext(FournisseurRespGetCollaborateurDetailsContext)
     const data = detailsQuery.data as ConsultantModel

     return (
          <>
               {isAddingNewDeclaration ? (
                    <DeclarationCreate
                         consultant_ID={data.id!}
                         onSubmitDeclarationExistsCallback={declaration => {
                              setIsAddingNewDeclaration(false)
                              setDeclarationSousConsultation({ declaration, defaultStep: "ndf" })
                         }}
                         onSubmitSuccessForCreationCallback={(id, selectedMonth) => {
                              setIsAddingNewDeclaration(false)
                              setDeclarationSousConsultation({
                                   declaration: {
                                        id,
                                        mois: selectedMonth,
                                   },
                                   defaultStep: "ndf",
                              })
                         }}
                         onClickPreviousCallback={() => setIsAddingNewDeclaration(false)}
                    />
               ) : declarationSousConsultation ? (
                    <>
                         <div className="d-flex justify-content-center">
                              <h5
                                   className="text-dark"
                                   style={{
                                        position: "relative",
                                        display: "inline-block",
                                        textDecoration: "none",
                                        paddingBottom: "3px",
                                        borderBottom: "1px solid currentColor",
                                   }}
                              >
                                   {declarationSousConsultation.declaration.mois?.format("MMMM YYYY").toUpperCase()}
                              </h5>
                         </div>

                         <DeclarationUpdate
                              declarationId={declarationSousConsultation.declaration.id}
                              defaultStep={declarationSousConsultation.defaultStep}
                              onClickOnRetourAuxDeclarationCallback={() => setDeclarationSousConsultation(null)}
                         />
                    </>
               ) : (
                    <DeclarationList
                         consultant_ID={data.id!}
                         onClickOnConsultationButtonCallback={(declaration, defaultStep) => setDeclarationSousConsultation({ declaration, defaultStep })}
                    />
               )}
          </>
     )
}

export default Declarations
