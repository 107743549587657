import { useLocation, useNavigate } from "react-router-dom"
import React, { createContext, Dispatch, SetStateAction, useContext, useEffect, useState } from "react"
import { toast } from "react-toastify"
import MyAlert from "../../../../../../utils/MyAlert"
import { declarationDetails } from "../../../core/_request"
import { ConsultantDeclarationMensuelleModel } from "../../../../../../models"
import { useIntl } from "react-intl"
import "./styles.scss"
import { DeclarationUpdateCra } from "./DeclarationUpdateCra"
import { DeclarationUpdateConges } from "./DeclarationUpdateConges"
import { DeclarationUpdateNotesDeFrais } from "./ndf/DeclarationUpdateNotesDeFrais"
import { DeclarationUpdateRecapitulatif } from "./recap/DeclarationUpdateRecapitulatif"
import { DeclarationUpdateTicketsResaurant } from "./DeclarationUpdateTicketsResaurant"
import { DeclarationUpdateArrets } from "./DeclarationUpdateArrets"
import {
     CONST_CONSULTANT_CONTRAT_ST,
     CONST_CONSULTANT_STATUT_ST_FREELANCE,
     CONST_CONSULTANT_STATUT_ST_PORTAGE,
     CONST_CONSULTANT_STATUT_ST_SOCIETE_SERVICE,
     CONST_DECLARATION_SUIVI_TYPE_INTERESSE_SIGNATURE,
     CONST_DECLARATION_SUIVI_TYPE_RESP_DEMANDE_RECTIFICATION,
     CONST_DECLARATION_SUIVI_TYPE_RESP_REJET_REGUL,
     CONST_DECLARATION_SUIVI_TYPE_RESP_VALIDATION_REGUL,
} from "../../../../../../constants"
import TabNavigationHeader from "./TabNavigationHeader"
import { DeclarationUpdateFactures } from "./factures/DeclarationUpdateFactures"
import { PageTitle } from "../../../../../../../_metronic/layout/core"

const urlParams = new URLSearchParams(window.location.search)

export function DeclarationUpdate({
     declarationId,
     defaultStep,
     onClickOnRetourAuxDeclarationCallback,
     onChangeNbJoursTravaillesCallback,
     onCRUDFacturesPrestationCallback,
     onCRUDFacturesFraisCallback,
     onValidationResponsableFournisseurCallback,
}: IPropsModel) {
     const intl = useIntl()

     const navigate = useNavigate()
     const location_state = useLocation().state as { declaration_id: string; default_step: "periode" | "cra" | "ticket_resto" | "ddc" | "arrets" | "ndf" | "factures" | "recap" }

     const declaration_id = (() => {
          if (location_state?.declaration_id) return Number(location_state?.declaration_id)
          if (urlParams.get("declaration_id")) return Number(urlParams.get("declaration_id"))
          if (declarationId) return declarationId

          throw new Error("Unable to get declaration_id")
     })()
     const default_step = (() => {
          const state = location_state?.default_step
          const param = urlParams.get("default_step") as "periode" | "cra" | "ticket_resto" | "ddc" | "arrets" | "ndf" | "factures" | "recap"
          if (state) return state
          if (param) return param
          if (defaultStep) return defaultStep

          throw new Error("Unable to get default_step")
     })()

     const [data, setData] = useState<{ loading: boolean; error: false | string; declaration: ConsultantDeclarationMensuelleModel | null }>({
          loading: true,
          error: false,
          declaration: null,
     })
     const [enCoursDeRectificationEnabledDepuisRecapitulatif, setEnCoursDeRectificationEnabledDepuisRecapitulatif] = useState<boolean>(false)
     const [declarationStepHeader, setDeclarationStepHeader] = useState<"periode" | "cra" | "ticket_resto" | "ddc" | "arrets" | "ndf" | "factures" | "recap">(
          default_step || "recap"
     )

     useEffect(() => {
          if (declaration_id) {
               declarationDetails(declaration_id)
                    .then(r => {
                         // BEGIN:: When first time created
                         const cons = r.data.consultant
                         if (!default_step)
                              setDeclarationStepHeader(
                                   cons?.contrat === CONST_CONSULTANT_CONTRAT_ST ? (cons.statut === CONST_CONSULTANT_STATUT_ST_FREELANCE ? "cra" : "ndf") : "arrets"
                              )
                         // END:: When first time created
                         setData(prev => ({ ...prev, loading: false, declaration: r.data }))
                    })
                    .catch(e => {
                         const error = e.response?.data?.detail ? e.response.data.detail : intl.formatMessage({ id: "FORM.STATUS.UNEXPECTED_ERROR_MESSAGE" })
                         toast.error(error, { autoClose: false })
                         setData(prev => ({ ...prev, loading: false, error: error }))
                    })
          } else {
               navigate(`/declarations/new`)
          }
     }, [])

     return (
          <>
               <PageTitle
                    breadcrumbs={[
                         {
                              title: "Déclarations",
                              path: "/activites/declarations",
                         },
                         {
                              title: data.declaration?.mois?.parseZone().format("MMMM YYYY")!,
                         },
                    ]}
               >
                    détails
               </PageTitle>

               <div className="card mb-5 mb-xl-10">
                    <div className="card-body p-9">
                         {data.loading && (
                              <div className={"text-center p-4"}>
                                   <span className="spinner-border mb-2"></span>
                                   <p style={{ fontWeight: 500 }}>Chargement en cours ...</p>
                              </div>
                         )}

                         {data.error && <MyAlert type={"danger"}>{data.error}</MyAlert>}

                         {!data.loading && data.declaration && (
                              <>
                                   <DeclarationUpdateContext.Provider
                                        value={{
                                             data: data,
                                             setData: setData,
                                             declarationStepHeader: declarationStepHeader,
                                             setDeclarationStepHeader: setDeclarationStepHeader,
                                             enCoursDeRectificationEnabledDepuisRecapitulatif: enCoursDeRectificationEnabledDepuisRecapitulatif,
                                             setEnCoursDeRectificationEnabledDepuisRecapitulatif: setEnCoursDeRectificationEnabledDepuisRecapitulatif,
                                             editionModeEnabled: data.declaration.modifiable!,
                                             onClickOnRetourAuxDeclarationCallback,
                                             onChangeNbJoursTravaillesCallback,
                                             onCRUDFacturesPrestationCallback,
                                             onCRUDFacturesFraisCallback,
                                             onValidationResponsableFournisseurCallback,
                                        }}
                                   >
                                        {data.declaration &&
                                             data.declaration.etat &&
                                             data.declaration.etat?.commentaire &&
                                             [
                                                  CONST_DECLARATION_SUIVI_TYPE_RESP_DEMANDE_RECTIFICATION,
                                                  CONST_DECLARATION_SUIVI_TYPE_RESP_VALIDATION_REGUL,
                                                  CONST_DECLARATION_SUIVI_TYPE_RESP_REJET_REGUL,
                                                  CONST_DECLARATION_SUIVI_TYPE_INTERESSE_SIGNATURE,
                                             ].includes(data.declaration.etat.type as string) && (
                                                  <MyAlert type={"info"} customIcon={"fas fa-comment-dots"} classNames={"mb-2"}>
                                                       <b className={"text-dark"}>{data.declaration.etat.personnel ? data.declaration.etat.personnel?.prenomNom : "Moi"}: </b>{" "}
                                                       {data.declaration.etat?.commentaire}
                                                  </MyAlert>
                                             )}

                                        <TabNavigationHeader />

                                        {declarationStepHeader == "arrets" && <DeclarationUpdateArrets />}

                                        {declarationStepHeader == "ddc" && <DeclarationUpdateConges />}

                                        {![CONST_CONSULTANT_STATUT_ST_PORTAGE, CONST_CONSULTANT_STATUT_ST_SOCIETE_SERVICE].includes(data.declaration.consultant?.statut!) &&
                                             declarationStepHeader == "cra" && <DeclarationUpdateCra />}

                                        {declarationStepHeader == "factures" && <DeclarationUpdateFactures isEditionModeEnabled={data.declaration?.modifiable!} />}

                                        {declarationStepHeader == "ndf" && <DeclarationUpdateNotesDeFrais />}

                                        {declarationStepHeader == "ticket_resto" && <DeclarationUpdateTicketsResaurant />}

                                        {declarationStepHeader == "recap" && <DeclarationUpdateRecapitulatif />}
                                   </DeclarationUpdateContext.Provider>
                              </>
                         )}
                    </div>
               </div>
          </>
     )
}

type DeclarationUpdateContextProps = {
     data: { loading: boolean; error: false | string; declaration: ConsultantDeclarationMensuelleModel | null }
     setData: Dispatch<SetStateAction<{ loading: boolean; error: false | string; declaration: ConsultantDeclarationMensuelleModel | null }>>
     declarationStepHeader: "periode" | "cra" | "ticket_resto" | "ddc" | "arrets" | "ndf" | "factures" | "recap"
     setDeclarationStepHeader: Dispatch<SetStateAction<"periode" | "cra" | "ticket_resto" | "ddc" | "arrets" | "ndf" | "factures" | "recap">>
     enCoursDeRectificationEnabledDepuisRecapitulatif: boolean
     setEnCoursDeRectificationEnabledDepuisRecapitulatif: Dispatch<SetStateAction<boolean>>
     editionModeEnabled: boolean
     onClickOnRetourAuxDeclarationCallback: () => void
     onChangeNbJoursTravaillesCallback?: (nbJoursTravailles: number) => void
     onCRUDFacturesPrestationCallback?: () => void
     onCRUDFacturesFraisCallback?: () => void
     onValidationResponsableFournisseurCallback?: () => void
}
const DeclarationUpdateContext = createContext<DeclarationUpdateContextProps>({} as DeclarationUpdateContextProps)

export function useDeclarationUpdate() {
     return useContext(DeclarationUpdateContext)
}

interface IPropsModel {
     declarationId?: number
     defaultStep?: "periode" | "cra" | "ticket_resto" | "ddc" | "arrets" | "ndf" | "factures" | "recap"
     onClickOnRetourAuxDeclarationCallback: () => void
     onChangeNbJoursTravaillesCallback?: (nbJoursTravailles: number) => void
     onCRUDFacturesPrestationCallback?: () => void
     onCRUDFacturesFraisCallback?: () => void
     onValidationResponsableFournisseurCallback?: () => void
}
