import React, { FC } from "react"
import { PageTitle } from "../../../../_metronic/layout/core"
import { CompteInfoPersoEdit } from "../../../modules/compte/components/infoPerso/edit/CompteInfoPersoEdit"
import { useAuth } from "../../../modules/auth"
import DocumentsPerso from "../../../modules/compte/components/documents/DocumentsPerso"
import MyCard from "../../../utils/MyCard"
import DocumentsPro from "../../../modules/compte/components/documents/pro/DocumentsPro"
import CompteInfoFournisseurEditionMode from "../../../modules/compte/components/infoFournisseur/CompteInfoFournisseurEditionMode"

function InfoToCompleterAvantDemandeEmbauche() {
     const { currentUser } = useAuth()

     const { infoPersoToBeFilled, documentsToBeFilled, infoFournisseurToBeFilled } = useAuth()

     return (
          <>
               <div className={"d-flex justify-content-center"}>
                    <div className={"w-100 w-lg-75 w-xxl-65"}>
                         <MyCard classNames={"mb-5 mb-xl-10"}>
                              <MyCard.Header classNames={"d-flex"} isCollapseIconShown>
                                   Informations Personnelles
                                   {infoPersoToBeFilled.length > 0 ? (
                                        <span className={"ms-sm-4"}>
                                             <span className="badge badge-light-warning fs-6 badge-sm p-2">
                                                  A compléter <i className={"fas fa-exclamation-circle text-warning ms-2  fs-3"} />{" "}
                                             </span>
                                        </span>
                                   ) : (
                                        <span className={"ms-sm-4"}>
                                             <span className="badge badge-light-success fs-6 badge-sm p-2">
                                                  Rien à ajouter <i className={"fas fa-check-circle text-success ms-2 fs-3"} />{" "}
                                             </span>
                                        </span>
                                   )}
                              </MyCard.Header>
                              <MyCard.Body>
                                   <CompteInfoPersoEdit />
                              </MyCard.Body>
                         </MyCard>

                         {currentUser?.fournisseur?.fournisseur && (
                              <MyCard classNames={"mb-5 mb-xl-10"}>
                                   <MyCard.Header classNames={"d-flex"} isCollapseIconShown>
                                        Informations Société
                                        {infoFournisseurToBeFilled.length > 0 ? (
                                             <span className={"ms-sm-4"}>
                                                  <span className="badge badge-light-warning fs-6 badge-sm p-2">
                                                       A compléter <i className={"fas fa-exclamation-circle text-warning ms-2  fs-3"} />{" "}
                                                  </span>
                                             </span>
                                        ) : (
                                             <span className={"ms-sm-4"}>
                                                  <span className="badge badge-light-success fs-6 badge-sm p-2">
                                                       Rien à ajouter <i className={"fas fa-check-circle text-success ms-2  fs-3"} />{" "}
                                                  </span>
                                             </span>
                                        )}
                                   </MyCard.Header>
                                   <MyCard.Body>
                                        <CompteInfoFournisseurEditionMode />
                                   </MyCard.Body>
                              </MyCard>
                         )}

                         <MyCard classNames={"mb-5 mb-xl-10"}>
                              <MyCard.Header classNames={"d-flex"} isCollapseIconShown>
                                   Documents
                                   {documentsToBeFilled.length > 0 ? (
                                        <span className={"ms-sm-4"}>
                                             <span className="badge badge-light-warning badge-sm fs-6 p-2">
                                                  A compléter <i className={"fas fa-exclamation-circle text-warning ms-2  fs-3"} />{" "}
                                             </span>
                                        </span>
                                   ) : (
                                        <span className={"ms-sm-4"}>
                                             <span className="badge badge-light-success badge-sm fs-6 p-2">
                                                  Rien à ajouter <i className={"fas fa-check-circle text-success ms-2  fs-3"} />{" "}
                                             </span>
                                        </span>
                                   )}
                              </MyCard.Header>
                              <MyCard.Body>
                                   <DocumentsPerso />
                                   {currentUser?.fournisseur?.fournisseur && (
                                        <>
                                             <div className="separator my-5"></div>
                                             <DocumentsPro />
                                        </>
                                   )}
                              </MyCard.Body>
                         </MyCard>
                    </div>
               </div>
          </>
     )
}

const InfoToCompleterAvantDemandeEmbaucheWrapper: FC = () => {
     return (
          <>
               <PageTitle breadcrumbs={[]}>Dossier de recrutement</PageTitle>
               <InfoToCompleterAvantDemandeEmbauche />
          </>
     )
}

export { InfoToCompleterAvantDemandeEmbaucheWrapper }
