import React from "react"
import { useDeclarationUpdate } from "../DeclarationUpdate"
import { CONST_CONSULTANT_CONTRAT_ST, CONST_CONSULTANT_STATUT_ST_PORTAGE, CONST_CONSULTANT_STATUT_ST_SOCIETE_SERVICE } from "../../../../../../../constants"

const Footer = () => {
     const context = useDeclarationUpdate()

     return (
          <>
               <div className="d-none d-sm-flex justify-content-between">
                    <div>
                         <button type={"button"} onClick={context.onClickOnRetourAuxDeclarationCallback} className={`btn btn-sm btn-light-primary me-2`}>
                              <i className={"fas fa-list-dots me-2 align-middle"} /> Retour aux déclarations
                         </button>
                         {![CONST_CONSULTANT_STATUT_ST_PORTAGE, CONST_CONSULTANT_STATUT_ST_SOCIETE_SERVICE].includes(context?.data.declaration?.consultant?.statut!) && (
                              <button type="button" className="btn btn-sm btn-light-primary me-3" onClick={() => context.setDeclarationStepHeader("cra")}>
                                   <span className={"fas fa-arrow-left fs-3 me-1 align-middle"} /> CRA
                              </button>
                         )}
                    </div>

                    <button
                         type="button"
                         className="btn btn-sm btn-primary"
                         onClick={() => {
                              context.setDeclarationStepHeader(
                                   context.enCoursDeRectificationEnabledDepuisRecapitulatif
                                        ? "recap"
                                        : context?.data.declaration?.consultant?.contrat === CONST_CONSULTANT_CONTRAT_ST
                                        ? "factures"
                                        : "ticket_resto"
                              )
                              context.setEnCoursDeRectificationEnabledDepuisRecapitulatif(false)
                         }}
                    >
                         {context.enCoursDeRectificationEnabledDepuisRecapitulatif
                              ? "Récapitulatif"
                              : context?.data.declaration?.consultant?.contrat === CONST_CONSULTANT_CONTRAT_ST
                              ? "Factures de prestation"
                              : "Tickets restaurant"}
                         <span className={"fas fa-arrow-right fs-3 ms-1 align-middle"} />
                    </button>
               </div>

               <div className="d-flex d-sm-none flex-column">
                    <div className={"mb-3"}>
                         {![CONST_CONSULTANT_STATUT_ST_PORTAGE, CONST_CONSULTANT_STATUT_ST_SOCIETE_SERVICE].includes(context?.data.declaration?.consultant?.statut!) && (
                              <button type="button" className="btn btn-sm btn-light-primary me-3" onClick={() => context.setDeclarationStepHeader("cra")}>
                                   <span className={"fas fa-arrow-left fs-3 me-1 align-middle"} /> CRA
                              </button>
                         )}
                         <button
                              type="button"
                              className="btn btn-sm btn-primary float-end"
                              onClick={() => {
                                   context.setDeclarationStepHeader(
                                        context.enCoursDeRectificationEnabledDepuisRecapitulatif
                                             ? "recap"
                                             : context?.data.declaration?.consultant?.contrat === CONST_CONSULTANT_CONTRAT_ST
                                             ? "factures"
                                             : "ticket_resto"
                                   )
                                   context.setEnCoursDeRectificationEnabledDepuisRecapitulatif(false)
                              }}
                         >
                              {context.enCoursDeRectificationEnabledDepuisRecapitulatif
                                   ? "Récapitulatif"
                                   : context?.data.declaration?.consultant?.contrat === CONST_CONSULTANT_CONTRAT_ST
                                   ? "Factures de prestation"
                                   : "Tickets restaurant"}
                              <span className={"fas fa-arrow-right fs-3 ms-1 align-middle"} />
                         </button>
                    </div>

                    <button type={"button"} onClick={context.onClickOnRetourAuxDeclarationCallback} className={`btn btn-sm btn-light-primary`}>
                         <span className={"fas fa-list-dots fs-1 me-3 align-middle"} /> Retour aux déclarations
                    </button>
               </div>
          </>
     )
}

export default Footer
