import React, { useMemo, useState } from "react"
import { toast } from "react-toastify"
import MyAlert from "../../../../../../utils/MyAlert"
import { craSetHeuresDayMission, craSetHeuresMissionForTheEntireMonth } from "../../../core/_request"
import { ConsultantCraDetailsModel, ConsultantCraModel } from "../../../../../../models"
import { useIntl } from "react-intl"
import moment from "moment"
import MyTooltip from "../../../../../../utils/MyTooltip"
import MyModal from "../../../../../../utils/MyModal"
import "./styles.scss"
import MySimpleSpinner from "../../../../../../utils/MySimpleSpinner"

const CraUpdateGrilleTempsFreelance: React.FC<CraUpdateGrilleTempsProps> = ({ data, setData, editionModeEnabled = true }) => {
     const [cellsToggling, setCellsToggling] = useState<ConsultantCraDetailsModel[]>([])

     const intl = useIntl()

     const [isSubmittingAutoFillLine, setIsSubmittingAutoFillLine] = useState(false)
     const [modalFillAllRowRefMissionWithHeures, setModalToggleAllLine] = useState<{ isShown: boolean; mission_id: null | number; mission_ref: null | string }>({
          isShown: false,
          mission_id: null,
          mission_ref: null,
     })

     const heuresParRefMissionParJour: heuresParRefMissionParJourType[] = (() => {
          let list: heuresParRefMissionParJourType[] = []

          data.consultantCraDetails.map(craDetails => {
               craDetails.consultantCraDetailsHeures.map(craDetailsHeure => {
                    const ref = craDetailsHeure.mission ? craDetailsHeure.mission.reference : "INTERCONTRAT"
                    const id = craDetailsHeure.mission ? craDetailsHeure.mission.id : 0
                    const index = list.findIndex(item => item.refMission == ref)
                    if (index === -1) {
                         list.push({ idMission: id, refMission: ref, items: [{ jour: craDetails.date, heures: craDetailsHeure.heuresJour }] })
                    } else {
                         list[index] = { ...list[index], items: [...list[index].items, { jour: craDetails.date, heures: craDetailsHeure.heuresJour }] }
                    }
               })
          })
          return list
     })()

     // Fill la ligne mission avec des heures
     const ModalFillAllCasesOfMissionRow = useMemo(() => {
          function handleSubmitAutoFillLine() {
               setIsSubmittingAutoFillLine(true)
               craSetHeuresMissionForTheEntireMonth(data.id, modalFillAllRowRefMissionWithHeures.mission_id as number, 7)
                    .then(r => {
                         setData(r.data)
                         setModalToggleAllLine({ mission_id: null, isShown: false, mission_ref: null })
                         setIsSubmittingAutoFillLine(false)
                    })
                    .catch(e => {
                         const error = e.response?.data?.detail ? e.response.data.detail : intl.formatMessage({ id: "FORM.STATUS.UNEXPECTED_ERROR_MESSAGE" })
                         toast.error(error, { autoClose: false })
                         setIsSubmittingAutoFillLine(false)
                    })
          }

          return (
               <MyModal
                    title={<span>Saisie automatique "{modalFillAllRowRefMissionWithHeures.mission_ref}"</span>}
                    show={modalFillAllRowRefMissionWithHeures.isShown}
                    handleClose={() => setModalToggleAllLine({ isShown: false, mission_id: null, mission_ref: null })}
               >
                    <div>
                         <MyAlert type={"primary"} classNames={"mb-5"}>
                              La ligne "{modalFillAllRowRefMissionWithHeures.mission_ref}" sera remplie automatiquement.
                         </MyAlert>
                         <div className="text-end">
                              <button className={"btn btn-primary btn-sm"} onClick={handleSubmitAutoFillLine} disabled={isSubmittingAutoFillLine}>
                                   {isSubmittingAutoFillLine ? <MySimpleSpinner size={"sm"} /> : "Confirmer"}
                              </button>
                         </div>
                    </div>
               </MyModal>
          )
     }, [modalFillAllRowRefMissionWithHeures.isShown, isSubmittingAutoFillLine])

     function toggleCell(cra_id: number, mission_id: number, date: moment.Moment, isChecked: boolean, cra_details_id: number) {
          craSetHeuresDayMission(cra_id, mission_id, date, isChecked ? 0 : 7)
               .then(r => {
                    setData(r.data)
               })
               .catch(e => {
                    toast.error(e.response.data?.detail, { autoClose: false })
               })
               .finally(() => setCellsToggling(prev => prev.filter(item => item.id != cra_details_id)))
     }

     // Une row avec les jours du mois
     const TableHeadRow = () => {
          return (
               <tr className={"px-2"}>
                    <th className="col fw-bold sticky-column"></th>
                    {data.consultantCraDetails.map((item, key) => {
                         return (
                              <th key={item.id} className={`text-center text-primary fw-bold ${key == data.consultantCraDetails.length - 1 && "pe-4"}`}>
                                   {item.date.parseZone().format("DD")}
                              </th>
                         )
                    })}
               </tr>
          )
     }

     // Heures de travail pour chaque ligne de mission
     // Popover onClick cell

     const TableBody = () => {
          return (
               <>
                    {heuresParRefMissionParJour.map((item, key) => (
                         <tr key={key}>
                              <td className={"ps-sm-6 border-end sticky-column"} style={{ fontSize: "0.8rem" }}>
                                   {item.refMission}
                                   {editionModeEnabled && (
                                        <>
                                             <MyTooltip title={"Saisie automatique"}>
                                                  <i
                                                       className={"fas fa-wand-magic-sparkles btn btn-color-dark fs-2 ms-sm-2 ms-4 p-0"}
                                                       onClick={() => setModalToggleAllLine({ isShown: true, mission_id: item.idMission, mission_ref: item.refMission })}
                                                  />
                                             </MyTooltip>
                                        </>
                                   )}
                              </td>
                              {data.consultantCraDetails.map((craDetails, key_) => {
                                   const rowReferenceMission = heuresParRefMissionParJour.find(item_ => item_.refMission == item.refMission)!.items
                                   const cellHeures = rowReferenceMission.find(item__ => item__.jour.format("DD/MM/YYYY") == craDetails.date.format("DD/MM/YYYY"))
                                   return editionModeEnabled ? (
                                        <td
                                             className={`text-center heuresCell ${[5, 6].includes(craDetails.date.weekday()) && "isWeekendCell"} ${
                                                  moment().format("YYYYMMDD") == craDetails.date.format("YYYYMMDD") && "isTodayCell"
                                             }`}
                                             onClick={() => {
                                                  if (editionModeEnabled && !cellsToggling.find(item_ => item_.id == craDetails.id)) {
                                                       toggleCell(data.id, item.idMission, craDetails.date, !!(cellHeures?.heures && cellHeures.heures > 0), craDetails.id)
                                                       setCellsToggling(prev => [...prev, craDetails])
                                                  }
                                             }}
                                             key={key_}
                                        >
                                             <span>
                                                  {cellsToggling.find(item_ => item_.id == craDetails.id) ? (
                                                       <span className="spinner-border spinner-border-sm align-middle" />
                                                  ) : cellHeures?.heures && cellHeures.heures > 0 ? (
                                                       <i className={"fas fa-check-circle text-dark"} />
                                                  ) : (
                                                       <></>
                                                  )}
                                             </span>
                                        </td>
                                   ) : (
                                        <td
                                             className={`text-center heuresCell ${[5, 6].includes(craDetails.date.weekday()) && "isWeekendCell"} ${
                                                  moment().format("YYYYMMDD") == craDetails.date.format("YYYYMMDD") && "isTodayCell"
                                             }`}
                                             key={key_}
                                        >
                                             <span>{cellHeures!.heures > 0 ? <i className={"fas fa-check-circle text-dark"} /> : ""} </span>
                                        </td>
                                   )
                              })}
                         </tr>
                    ))}
               </>
          )
     }

     return (
          <div className="table-responsive text-nowrap" style={{ overflowY: "hidden" }}>
               <table id={"cra-table"} className={`table m-0 ${editionModeEnabled && "cra-table-edition-mode"}`}>
                    {/* Begin::thead */}
                    <thead className="thead-dark border-bottom border-top">
                         <TableHeadRow />
                    </thead>
                    {/* End::thead */}

                    {/* Begin::tbody */}
                    <tbody className={"border-top border-bottom"}>
                         <TableBody />
                    </tbody>
                    {/* End::tbody */}
               </table>

               {modalFillAllRowRefMissionWithHeures.isShown && ModalFillAllCasesOfMissionRow}
          </div>
     )
}

type heuresParRefMissionParJourType = { idMission: number; refMission: string; items: { heures: number; jour: moment.Moment }[] }
type CraUpdateGrilleTempsProps = {
     data: ConsultantCraModel
     setData: (v: ConsultantCraModel) => void
     editionModeEnabled?: boolean
}

export default CraUpdateGrilleTempsFreelance
