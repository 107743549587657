import axios, { AxiosError } from "axios"
import { useQuery, UseQueryResult } from "react-query"
import { ConsultantDeclarationMensuelleModel, ConsultantModel, IHttpErrorResponseModel } from "../../../../models"
import { API_ESPACE_FOURNISSEUR_RESP_GET_COLLABORATEUR_DETAILS_ENDPOINT } from "../../../../constants"
import { toast } from "react-toastify"
import MySimpleSpinner from "../../../../utils/MySimpleSpinner"
import MyAlert from "../../../../utils/MyAlert"
import MyCard from "../../../../utils/MyCard"
import { useParams } from "react-router-dom"
import React, { createContext, Dispatch, SetStateAction, useState } from "react"
import Header from "./Header"
import InfoBase from "./infoBase/InfoBase"
import Declarations from "./declarations/Declarations"
import MyTooltip from "../../../../utils/MyTooltip"
import { PageTitle } from "../../../../../_metronic/layout/core"

const FournisseurRespGetCollaborateurDetails = () => {
     const urlParams = new URLSearchParams(window.location.search)
     const urlParamDefaultSelectedNavigationItem = urlParams.get("defaultSelectedNavigationItem") as "INFO_BASE" | "DECLARATIONS" | null

     const collaborateur_ID = parseInt(useParams<{ id: string }>().id as string)

     const [selectedNavigationItem, setSelectedNavigationItem] = useState<"INFO_BASE" | "DECLARATIONS">(urlParamDefaultSelectedNavigationItem || "INFO_BASE")
     const [isAddingNewDeclaration, setIsAddingNewDeclaration] = useState(false)
     const [declarationSousConsultation, setDeclarationSousConsultation] = useState<{ declaration: ConsultantDeclarationMensuelleModel; defaultStep: any } | null>(null)
     const [isEditingDetails, setIsEditingDetails] = useState(false)

     const detailsQuery = useQuery<ConsultantModel, AxiosError>("GET_CONSULTANT_DETAILS", async () => {
          return axios
               .get<ConsultantModel>(API_ESPACE_FOURNISSEUR_RESP_GET_COLLABORATEUR_DETAILS_ENDPOINT(collaborateur_ID))
               .then(r => {
                    return r.data
               })
               .catch((e: AxiosError) => {
                    const error: IHttpErrorResponseModel = e.response?.data

                    toast.error(error?.detail, { autoClose: false })

                    throw e
               })
     })

     // Navigation title on the left corner of the card
     const NavigationTitle = () => {
          return (
               <span>
                    {selectedNavigationItem === "INFO_BASE" && <div className={"d-flex align-items-center"}>Informations de base</div>}
                    {selectedNavigationItem === "DECLARATIONS" && <div className={"d-flex align-items-center"}>Déclarations d'activité</div>}
               </span>
          )
     }

     const NavigationRightSide = () => {
          if (selectedNavigationItem === "INFO_BASE") {
               return (
                    <i
                         className={`fas fa-${isEditingDetails ? "eye" : "edit"} text-dark fs-2 text-hover-primary cursor-pointer`}
                         onClick={() => setIsEditingDetails(prev => !prev)}
                    />
               )
          }

          if (selectedNavigationItem === "DECLARATIONS") {
               return (
                    <MyTooltip title={isAddingNewDeclaration || declarationSousConsultation ? "Retour à la liste des déclarations" : "Ajouter une nouvelle déclaration"}>
                         <i
                              className={`fas ${
                                   isAddingNewDeclaration || declarationSousConsultation ? "fa-list" : "fa-plus-circle"
                              } text-dark fs-2 text-hover-primary cursor-pointer ms-2`}
                              onClick={() => {
                                   setIsAddingNewDeclaration(!(isAddingNewDeclaration || declarationSousConsultation))
                                   setDeclarationSousConsultation(null)
                              }}
                         />
                    </MyTooltip>
               )
          }

          return <></>
     }

     return (
          <FournisseurRespGetCollaborateurDetailsContext.Provider
               value={{
                    detailsQuery,
                    selectedNavigationItem,
                    setSelectedNavigationItem,
                    isAddingNewDeclaration,
                    setIsAddingNewDeclaration,
                    declarationSousConsultation,
                    setDeclarationSousConsultation,
                    isEditingDetails,
                    setIsEditingDetails,
               }}
          >
               <PageTitle
                    breadcrumbs={[
                         {
                              title: "Collaborateurs",
                              path: "/espace-resp-fournisseur/collaborateurs",
                         },
                         {
                              title: detailsQuery.data?.prenomNom || "",
                         },
                    ]}
               >
                    Details
               </PageTitle>

               <div className={"d-flex justify-content-center"}>
                    <div className={"w-100 w-lg-75 w-xxl-65"}>
                         {detailsQuery.isFetching && (
                              <div className={"text-center p-5"}>
                                   <MySimpleSpinner size={"xl"} />
                              </div>
                         )}

                         {!detailsQuery.isFetching && detailsQuery.isError && (
                              <MyAlert type={"danger"} classNames={"mb-4"}>
                                   <>
                                        <span className={"me-2"}>{detailsQuery.error?.response?.data?.detail}</span>
                                        <button className={"btn btn-sm btn-danger"} onClick={() => detailsQuery.refetch()}>
                                             Recharger
                                        </button>
                                   </>
                              </MyAlert>
                         )}

                         {!detailsQuery.isFetching && !detailsQuery.isError && detailsQuery.data && (
                              <>
                                   <Header />

                                   <MyCard classNames={"mb-4"}>
                                        <MyCard.Header
                                             classNames={"d-flex justify-content-center d-sm-inline-grid justify-content-sm-between"}
                                             rightSideContent={<NavigationRightSide />}
                                        >
                                             <NavigationTitle />
                                        </MyCard.Header>
                                        <MyCard.Body noPadding>
                                             {selectedNavigationItem === "INFO_BASE" && (
                                                  <div className="p-5">
                                                       <InfoBase />
                                                  </div>
                                             )}
                                             {selectedNavigationItem === "DECLARATIONS" && (
                                                  <div className="p-5">
                                                       <Declarations />
                                                  </div>
                                             )}
                                        </MyCard.Body>
                                   </MyCard>
                              </>
                         )}
                    </div>
               </div>
          </FournisseurRespGetCollaborateurDetailsContext.Provider>
     )
}

const FournisseurRespGetCollaborateurDetailsContext = createContext<FournisseurRespGetCollaborateurDetailsContext>({} as FournisseurRespGetCollaborateurDetailsContext)

interface FournisseurRespGetCollaborateurDetailsContext {
     detailsQuery: UseQueryResult<ConsultantModel, AxiosError>
     selectedNavigationItem: "INFO_BASE" | "DECLARATIONS"
     setSelectedNavigationItem: Dispatch<SetStateAction<"INFO_BASE" | "DECLARATIONS">>
     isAddingNewDeclaration: boolean
     setIsAddingNewDeclaration: React.Dispatch<React.SetStateAction<boolean>>
     declarationSousConsultation: { declaration: ConsultantDeclarationMensuelleModel; defaultStep: any } | null
     setDeclarationSousConsultation: React.Dispatch<React.SetStateAction<{ declaration: ConsultantDeclarationMensuelleModel; defaultStep: any } | null>>
     isEditingDetails: boolean
     setIsEditingDetails: React.Dispatch<React.SetStateAction<boolean>>
}

export { FournisseurRespGetCollaborateurDetails, FournisseurRespGetCollaborateurDetailsContext }
