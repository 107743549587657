import React from "react"
import { useAuth } from "../../../auth"

const CompteInfoFournisseurPreviewMode = () => {
     const { currentUser } = useAuth()

     return (
          <>
               <div className="row mb-7">
                    <label className="col-lg-4 fw-bold text-muted">Nom commercial</label>

                    <div className="col-lg-8">
                         <span className="fw-bolder fs-6 text-dark">
                              {currentUser?.fournisseur?.fournisseur?.raisonSociale ? (
                                   <>{currentUser?.fournisseur?.fournisseur?.raisonSociale}</>
                              ) : (
                                   <span className={"text-warning d-flex align-items-center"}>
                                        À renseigner <i className={"fas fa-exclamation-circle text-warning ms-1"} />
                                   </span>
                              )}
                         </span>
                    </div>
               </div>

               <div className="row mb-7">
                    <label className="col-lg-4 fw-bold text-muted">Forme juridique</label>

                    <div className="col-lg-8">
                         <span className="fw-bolder fs-6 text-dark">
                              {currentUser?.fournisseur?.fournisseur?.formeJuridique ? (
                                   <>{currentUser?.fournisseur?.fournisseur?.formeJuridiqueLabel}</>
                              ) : (
                                   <span className={"text-warning d-flex align-items-center"}>
                                        À renseigner <i className={"fas fa-exclamation-circle text-warning ms-1"} />
                                   </span>
                              )}
                         </span>
                    </div>
               </div>

               <div className="row mb-7">
                    <label className="col-lg-4 fw-bold text-muted">SIRET</label>

                    <div className="col-lg-8">
                         <span className="fw-bolder fs-6 text-dark">
                              {currentUser?.fournisseur?.fournisseur?.siret ? (
                                   currentUser?.fournisseur?.fournisseur?.siret
                              ) : (
                                   <span className={"text-warning d-flex align-items-center"}>
                                        À renseigner <i className={"fas fa-exclamation-circle text-warning ms-1"} />
                                   </span>
                              )}
                         </span>
                    </div>
               </div>

               <div className="row mb-7">
                    <label className="col-lg-4 fw-bold text-muted">Adresse</label>

                    <div className="col-lg-8">
                         <span className="fw-bolder fs-6 text-dark">
                              {currentUser?.fournisseur?.fournisseur?.adresse ? (
                                   currentUser?.fournisseur?.fournisseur?.adresse
                              ) : (
                                   <span className={"text-warning d-flex align-items-center"}>
                                        À renseigner <i className={"fas fa-exclamation-circle text-warning ms-1"} />
                                   </span>
                              )}
                         </span>
                    </div>
               </div>

               <div className="row mb-7">
                    <label className="col-lg-4 fw-bold text-muted">Ville</label>

                    <div className="col-lg-8">
                         <span className="fw-bolder fs-6 text-dark">
                              {currentUser?.fournisseur?.fournisseur?.ville ? (
                                   currentUser?.fournisseur?.fournisseur?.ville
                              ) : (
                                   <span className={"text-warning d-flex align-items-center"}>
                                        À renseigner <i className={"fas fa-exclamation-circle text-warning ms-1"} />
                                   </span>
                              )}
                         </span>
                    </div>
               </div>

               <div className="row mb-7">
                    <label className="col-lg-4 fw-bold text-muted">Code postal</label>

                    <div className="col-lg-8">
                         <span className="fw-bolder fs-6 text-dark">
                              {currentUser?.fournisseur?.fournisseur?.codePostal ? (
                                   currentUser?.fournisseur?.fournisseur?.codePostal
                              ) : (
                                   <span className={"text-warning d-flex align-items-center"}>
                                        À renseigner <i className={"fas fa-exclamation-circle text-warning ms-1"} />
                                   </span>
                              )}
                         </span>
                    </div>
               </div>

               <div className="row">
                    <label className="col-lg-4 fw-bold text-muted">Pays</label>

                    <div className="col-lg-8">
                         <span className="fw-bolder fs-6 text-dark">
                              {currentUser?.fournisseur?.fournisseur?.pays?.nomFr ? (
                                   currentUser?.fournisseur?.fournisseur?.pays?.nomFr
                              ) : (
                                   <span className={"text-warning d-flex align-items-center"}>
                                        À renseigner <i className={"fas fa-exclamation-circle text-warning ms-1"} />
                                   </span>
                              )}
                         </span>
                    </div>
               </div>
          </>
     )
}

export default CompteInfoFournisseurPreviewMode
